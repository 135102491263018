import React from "react";
import {Context} from "../../tools/context";
import Image from "../../components/Image";

const Banner = ({partner, customer, ...props}) => {
  const [showMono, setShowMono] = React.useState(Context.partner.platform === 'ticketcommercant');

  if (Context.partner.landing?.doubleBackground) {
    return (
      <div className='contentWrapper'>
        {customer && <div className='background' style={{minWidth: '300px'}}>
          <Image className={'backgroundImg'}
                 src={`assets/${Context.partner.sponsor}_landing_secondary_background.jpg`}
                 fallback={'assets/ticket_co_landing_background.jpg'}
          />
          {customer}
        </div>}
        {partner && <div className='background' style={{minWidth: '300px'}}>
          <Image className={'backgroundImg'}
            src={`assets/${Context.partner.sponsor}_landing_primary_background.jpg`}
            fallback={'assets/ticket_co_landing_background.jpg'}
          />
          {partner}
        </div>}
      </div>
    )
  }

  const monoLogo = showMono ?
    <img
      style={{maxWidth: '300px', zIndex: 1}}
      src={`assets/${Context.partner.sponsor}_logo_monochrome.png`}
      onError={() => setShowMono(false)}
    />
    : null;

  return (
    <div className='contentWrapper'>
      <div className='background solo'>
        <Image className={'backgroundImg'}
          src={`assets/${Context.partner.sponsor}_landing_background.jpg`}
          fallback={'assets/ticket_co_landing_background.jpg'}
        />
        {customer && <div style={{flex: 1, minWidth: '300px', zIndex: 1}}>
          {customer}
        </div>}
        {monoLogo}
        {partner && <div style={{flex: 1, minWidth: '300px', zIndex: 1}}>
          {partner}
        </div>}
      </div>
    </div>
  )
}

export default Banner;