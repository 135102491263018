import React from 'react';
import Context from "../../../tools/context";
import Coche from "../../../components/Coche";
import TicketsInfoPopup from "../../../components/Popup/TicketsPopup";
import {popupTypes} from "../../../Utils/Constants";
import {clientT} from "../../../tools/i18n";
import moment from "moment";
import LogoNegative from "../../../components/LogoNegative";
import Logo from "../../../components/Logo";

const TCAGiftCard = ({ticket, selected, handleSelection, promo, type}) => {
  const [notYetEligible, setNotYetEligible] = React.useState(false);

  let bodyBase = () => {
    switch (type) {
      case 'TCA':
        return {
          condition: 'Tous professionnels du tourisme',
        };
      default:
        return {
          condition: 'Tous professionnels du tourisme',
        };
    }
  };

  let body = bodyBase();
  if (Context.partner?.client?.ticketStyle) {
    body = {
      ...body(),
      ...Context.partner.client.ticketStyle,
    }
  }

  ticket?.campaignData
    ?.filter(d => Object.keys(body).includes(d.key) && d.value)
    .forEach(data => {
      let value = data.value;
      if (data.key === 'background' && data.value.startsWith('/')) {
        value = `https://cdn.cibler.io/campaigns/assets/${ticket.campaignId}/mobile${data.value}`;
      }
      body[data.key] = value;
    });

  ticket.condition = body.condition;

  const isDateValid = () => {
    if (!ticket.validityStartDate) return true;
    const date = new Date(ticket.validityStartDate);

    return (date - new Date()) < 0;
  }

  const description = () => {
    return ticket.threshold ? <>{clientT('myTicketsV2.step1.forAPurchase')}{' '}
    <span>{clientT('myTicketsV2.step1.purchaseValue', {threshold: ticket.threshold})}</span>
    </> : clientT('myTicketsV2.step1.noThreshold');
  }

  const startValidity = () => {
    if (isDateValid()) return false;
    const date = moment(ticket.validityStartDate).format('DD/MM/YYYY')

    return ` ${clientT('myTicketsV2.step1.validityFrom')} ${date}`
  }


  const expiration = () => {
    if (!ticket.expiration) return false;
    const date = moment(ticket.expiration).format('DD/MM/YYYY')

    return ` ${clientT('myTicketsV2.step1.validityTo')} ${date}`
  }

  const attributed = () => {
    if (!ticket.burnDate) return false;

    const date = moment(ticket.burnDate).format('DD/MM/YYYY')
    const hours = moment(ticket.burnDate).format('HH:mm')
    return <div>
      {clientT('usedTickets.affectedTo', {
        sellerRecipient: ticket.sellerRecipient,
        sellerRecipientId: ticket.sellerRecipientId,
        date: date,
        hour: hours
      })}
    </div>;
  }

  const subConditions = () => {
    if (body.condition.toLowerCase() !== 'tous commerces') return false;

    return <div className="subtext">
      {Context.partner.exclusionText || 'Sauf alcool, tabac et jeux d\'argent'}
    </div>
  }

  const handleClick = () => {
    if (!isDateValid()) return setNotYetEligible(true);

    handleSelection && handleSelection(ticket);
  }

  const src = Context.partner.customLogo
    ? `/assets/${Context.partner.sponsor}_logo.png`
    : '/assets/logo.png';

  const count = ticket.uuids?.length || 1;

  return (
    <>
      <div className="TCAGiftcard"
           onClick={handleClick}
      >
        <svg className="decoration" width="250" height="145" viewBox="0 0 250 145" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_939_81613" maskUnits="userSpaceOnUse" x="0" y="0" width="250" height="145">
            <rect y="0.75" width="250" height="144" rx="15" fill="#fff"/>
          </mask>
          <g mask="url(#mask0_939_81613)">
            <path d="M86.2325 -59.6117L38.5946 26.3293C38.4775 25.7588 38.2745 25.213 37.955 24.7469C37.5556 24.1644 36.9905 23.4901 36.0759 23.0553C35.327 22.7124 34.3205 22.4434 32.9891 22.4999C30.1852 22.6069 28.8551 23.9641 28.2978 25.0997C26.6014 28.4208 28.9689 33.9222 30.8066 37.2521C27.0641 37.4889 21.144 38.3964 19.2266 41.595C18.5588 42.6693 18.1127 44.5166 19.5324 47.037C20.9828 49.5022 22.7994 50.0036 24.1002 50.0023C24.6647 50.0263 25.2352 49.9092 25.7811 49.7062L-16.4947 125.974L-13.5664 127.597L33.9217 41.7957C35.464 44.0951 37.4555 47.7992 37.1331 50.7262C37.0358 51.6834 37.6808 52.4743 38.6381 52.5716C39.5953 52.6688 40.3862 52.0238 40.4835 51.0666C40.9277 47.2682 38.8378 43.2206 37.197 40.5777C40.2645 40.2557 44.7427 39.3434 47.403 36.6289C48.0528 35.9779 48.0702 34.9041 47.4191 34.2544C47.284 34.1072 47.1183 34.0154 46.9525 33.9235C46.3141 33.6418 45.5473 33.7223 45.0446 34.2382C43.0095 36.2158 39.1018 37.0111 36.3472 37.2898L89.2161 -57.9579L86.2325 -59.6117ZM31.2567 26.6677C31.3486 26.5019 31.6548 25.9494 33.158 25.8437C34.6611 25.7379 35.0052 26.2898 35.1097 26.4922C36.0928 27.9761 34.9922 31.9162 33.578 35.2493C31.8754 32.0665 30.4544 28.2453 31.2567 26.6677ZM24.0725 46.6645C23.9866 46.6892 23.7902 46.6525 23.5692 46.53C23.293 46.3769 22.8816 46.0766 22.4882 45.353C21.7261 43.9916 22.057 43.525 22.1795 43.304C23.0922 41.7876 27.0305 40.9371 30.6626 40.6392C28.5854 43.6047 25.8272 46.626 24.0725 46.6645Z" fill="white"/>
          </g>
        </svg>
        <img className="logo" src={src} alt='' title='logo'/>
        <div className="cardTitle">{clientT('common.ticket')}</div>
        <div className="value">
          {ticket.value}<span>€</span>
          <div className="code">{ticket.prize}</div>
        </div>
        <div className="info">
          <div>
            {description()}
            {startValidity()}
            {expiration()}
            {attributed()}
          </div>
          <div dangerouslySetInnerHTML={{__html: body.condition}} />
        </div>
        {count > 1 && <span className="count">{clientT('myTicketsV2.count', {count})}</span>}
        {selected &&
          <Coche height="21px" width="21px" className="selected" color={body.textColor} selected={selected}/>
        }
      </div>
      {notYetEligible &&
      <TicketsInfoPopup
        type={popupTypes.notYetEligible}
        close={(e) => setNotYetEligible(false)}
      />
      }
    </>
  )
}

export default TCAGiftCard;