import Card from "./Card";
import StatusTag from "./StatusTag";
import {useCallback, useState} from "react";
import {authClient} from "../../../../tools/apitools";
import useWindowSize from "../../../../hooks/useWindowSize";
import Balance from "./Balance";
import Button from "./Button";
import {getPartnerKey} from "../../../../tools/toolbox";

export default function SwiperItem({ ticket, user, used }) {
  const {isDesktop} = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  const status = ticket?.sorewardsCard
    ? ticket?.sorewardsCardBalance >= 0 && !used
      ? "active"
      : "empty"
    : "inactive";
  const isActive = status === "active" || status === "empty";

  const data = {};
  const desktopData = {};
  ticket?.campaignData?.forEach(d => (
    d.device === "mobile"
      ? (data[d.key] = d.value)
      : (desktopData[d.key] = d.value)
  ));

  const getValueWithSize = useCallback((key) => (
    isDesktop && !!desktopData[key] ? desktopData[key] : data[key]
  ), [isDesktop]);

  const getCardImage = useCallback(() => (
    `https://cdn.cibler.io/campaigns/assets/${
      ticket.campaignId
    }/${
      isDesktop && !!desktopData.cardImage
        ? "desktop"
        : "mobile"
    }${
      getValueWithSize("cardImage")
    }`
  ), [isDesktop, desktopData, ticket.campaignId]);

  const goToCard = () => {
    setIsLoading(true);
    authClient(`api/paymentCard/userLink/${ticket.uuid}?partner=${getPartnerKey()}`,
      {method: 'GET'},
      (res) => {
        setIsLoading(false);
        window.location.href = res;
      },
          (e) => {
        setIsLoading(false);
        console.error(e)
      },
      true
    )
      .catch(e => console.error(e))
      .finally(() => setIsLoading(false));
  }

  return (
    <div className="SwiperItem">
      <Card
        imgUrl={getCardImage()}
        name={`${user.firstname} ${user.name}`}
        isActive={isActive}
      />
      <div className="CardInfo">
        <StatusTag status={status} />
        <span className="CardName">{ticket.name}</span>
        {used
          ? null
          : <Balance
              isActive={isActive}
              balance={ticket.sorewardsCardBalance ?? ticket.value}
              maxBalance={ticket.value}
            />
        }
      </div>
      {used ? null : <Button isActive={isActive} onClick={goToCard} />}
    </div>
  );
};
