import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)

export default class DateField extends Component {
  localHandle = (date) => {
    this.props.handleChange({
      target: {
        id: this.props.id,
        value: moment(date).format('YYYY-MM-DD'),
      }
    })
  }

  render(){
    const {id, label,
      optional,
      errorMsg, value,
      customError, subLabel,
      placeholder, disabled, labelOptional
    } = this.props;

    const localValue = value ? moment(value, 'YYYY-MM-DD').toDate() : null;
    const placeHolder = (labelOptional && `${label}${!optional ? '*' : ''}`) || placeholder;

    return (
      <p>
       {
         !labelOptional &&  <label htmlFor={id}>
              {label}{!optional ? <span
              className="required">*</span> : null} <span
              className="subLabel">{subLabel}</span>
            </label>
       }
        <DatePicker
          locale="fr"
          id={id}
          selected={localValue}
          placeholderText={placeHolder}
          dateFormat={"dd/MM/yyyy"}
          onChange={this.localHandle}
          required={!optional}
          disabled={disabled}
          onInvalid={(e) => {
            if (customError) e.target.setCustomValidity(customError);
          }}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        {errorMsg ?
          <span className="error">
                  {errorMsg}
                </span>
          : null}
      </p>
    )
  }
}