import React, {Component, useEffect, useState} from 'react';
import AppButton from "../../components/AppButton";
import ReactMapGL, {Marker} from "react-map-gl";
import {addressGeocoding} from "../../tools/apitools";

export default ({
  state,
  handleChange,
  handleClose,
  handleNext,
                  ...props
                }) => {
  const [error, setError] = useState('');
  const [loader, setLoader] = useState(false);
  const [location, setLocation] = useState({
    longitude: 5.4,
    latitude: 43.3,
    zoom: 15,
  });
  const [valid, setValid] = useState(false);
  const mapParams = {
    width: 300,
    height: 300,
  };

  useEffect(() => {
    getGeoloc();
  }, [])

  const getGeoloc = async () => {
    const address =
      state.address +
      ' ' +
      state.zipcode +
      ' ' +
      state.city;
    const data = await addressGeocoding(address)
    if (data?.results?.length) {
      const { lat, lng } = data.results[0].geometry.location;
      setLocation(p => ({
        ...p,
        longitude: lng,
        latitude: lat,
      }))
      setValid(true);
    } else {
      setValid(false)
    }
  }

  const handleLocalChange = (e) => {
    if (state.address && state.zipcode && state.city) {
      getGeoloc();
    } else {
      setValid(false);
    }
    handleChange(e);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    handleChange({target: {
        id: 'longitude',
        value: location.longitude,
      }})
    handleChange({target: {
        id: 'latitude',
        value: location.latitude,
      }})
    handleNext();
    handleClose();

    return false;
  }

  return (
    <div className='mapContainer'>
      <div className='mapWrapper'>
        <div>
          <div className='mapQuestion'>
            <p className="title"> Voici l'adresse indiquée</p>
            <p>La position est-elle correcte ?</p>
          </div>
        </div>
        <ReactMapGL
          width={mapParams.width}
          height={mapParams.height}
          zoom={location.zoom}
          longitude={location.longitude}
          latitude={location.latitude}
          mapStyle={'mapbox://styles/mapbox/streets-v11'}
          mapboxApiAccessToken='pk.eyJ1IjoibHZlcmRvbmsiLCJhIjoiY2s4OHY0b2llMDBkeTNpbXBhNmRnMmU3dCJ9.adHYqa4eOwGNKzBR9EJB7A'
          onViewportChange={(nextViewport) => {
            setLocation(nextViewport)
          }}
        >
          <Marker
            longitude={location.longitude}
            latitude={location.latitude}
          >
            <img
              className='mapMarker'
              alt='mapmarker'
              src='data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22%2368737d%22%3E%20%20%3Cdefs%2F%3E%20%20%3Cpath%20d%3D%22M%204%2017%20C%204%2022%2010%2028%2015%2028%20V%2032%20H%2017%20V%2028%20C%2022%2028%2028%2022%2028%2017%20H%2032%20V%2015%20H%2028%20C%2028%2010%2022%204%2017%204%20V%200%20H%2015%20V%204%20C%2010%204%204%2010%204%2015%20H%200%20V%2017%20H%2012%20V%2015%20H%207%20C%207%2011%2011%207%2015%207%20V%2012%20H%2017%20V%207%20C%2021%207%2025%2011%2025%2015%20C%2025%2015%2025%2015%2025%2015%20H%2020%20V%2017%20H%2025%20C%2025%2021%2021%2025%2017%2025%20V%2020%20H%2015%20V%2025%20C%2011%2025%207%2021%207%2017%20Z%22%2F%3E%3C%2Fsvg%3E'
            />
          </Marker>
        </ReactMapGL>
        <form onSubmit={handleSubmit}>
          <div className='mapInputs'>
            <input
              required
              placeholder='adresse'
              id='address'
              value={state.address}
              onChange={handleLocalChange}
            />
            <input
              required
              placeholder='Code postal'
              id='zipcode'
              value={state.zipcode}
              onChange={handleLocalChange}
              minLength='5'
              maxLength='5'
            />
            <input
              required
              placeholder='Ville'
              id='city'
              value={state.city}
              onChange={handleLocalChange}
            />
          </div>
          <div className='mapButtons'>
            <AppButton
              className='button button2'
              style={{ fontSize: '14px' }}
              event="Cancel register address"
              onClick={handleClose}
            >
              Annuler
            </AppButton>
            <AppButton
              className={`button button1 ${
                valid ? '' : 'disabled'
              }`}
              style={{ fontSize: '14px' }}
              disabled={!valid}
              event="Confirm register address"
              type={"submit"}
            >
              Confirmer
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  );
}