import React, {useCallback, useState} from 'react';
import Coche from "../../../components/Coche";
import {clientT} from "../../../tools/i18n";
import moment from 'moment';
import useWindowSize from "../../../hooks/useWindowSize";

const PassTickets = ({ticket, selected, handleSelection, promo, type}) => {
  const {isDesktop} = useWindowSize();
  const cData = ticket?.campaignData;
  const data = {};
  const desktopData = {};
  cData?.forEach(d => (
    d.device === "mobile"
      ? (data[d.key] = d.value)
      : (desktopData[d.key] = d.value)
  ));

  const getValueWithSize = useCallback((key) => (
    isDesktop && !!desktopData[key] ? desktopData[key] : data[key]
  ), [isDesktop]);

  const [background, setBackground]
    = useState(`https://cdn.cibler.io/campaigns/assets/${ticket.campaignId}/${
      isDesktop && !!desktopData.background
        ? "desktop"
        : "mobile"
    }${
      getValueWithSize("background")
    }`);

  const [border, setBorder] = useState(`3px solid transparent`);
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    handleSelection && handleSelection(ticket);
    setIsSelected(!isSelected);
    setBorder(!isSelected ? `3px solid ${getValueWithSize("borderColor") || 'transparent'}` : '3px solid transparent');
  }
  const attributed = () => {
    if (!ticket.burnDate) return false;

    const date = new Date(ticket.burnDate);
    return <div style={{marginTop: '5px'}}>
      {clientT('usedTickets.affectedTo', {
        sellerRecipient: ticket.sellerRecipient,
        sellerRecipientId: ticket.sellerRecipientId,
        date: moment(date).format('DD/MM/YYYY'),
        hour: moment(date).format('HH:mm')
      })}
    </div>;
  }

  const getPassHeight = () => (
    isDesktop ? (desktopData.height ?? data.height) : data.height
  );

  const getPassHeightCallback = useCallback(() => getPassHeight(), [isDesktop]);

  const height = useCallback(() => {
    if (!data.height && !desktopData.height) return 'auto';

    const height = getPassHeight();

    if (!ticket.burnDate) return height;

    return "fit-content";
  }, [isDesktop]);

  const count = ticket.uuids?.length || 1;

  return (
    <div className="PassTickets"
      style={{
        backgroundImage: `url("${background}")`,
        backgroundColor: getValueWithSize("bgColor"),
        border: border,
        height: height(),
        color: getValueWithSize("textColor"),
      }}
      onClick={handleClick}
    >
      <div
        style={{
          height: !ticket.burnDate
            ? "100%"
            : getPassHeightCallback(),
        }}
        dangerouslySetInnerHTML={{__html: getValueWithSize("condition")}}
      />
      {attributed()}
      {count > 1
        ? <span className="count">
            {clientT('myTicketsV2.count', {count})}
          </span>
        : null
      }
      {handleSelection && !ticket.burnDate
        ? <Coche
            height="21px"
            width="21px"
            className="coche"
            color={getValueWithSize("textColor")}
            selected={selected}
          />
        : null
      }
      {background
        ? <div style={{display: 'none'}}>
            <img
              src={background}
              onError={() => setBackground('')}
             alt="background"
            />
          </div>
        : null
      }
    </div>
  )
}

export default PassTickets;