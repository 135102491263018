import React, {Fragment, useEffect, useState} from "react";
import '../../../style/transactions.scss';
import {useHistory, withRouter} from 'react-router-dom';
import {siteContext} from "../../../tools/toolbox";
import {authClient} from "../../../tools/apitools";
import {t} from '../../../tools/i18n';
import {OctogoneCourse} from "../../../components/Picto/Course/Octogone";
import {SparkTop} from "../../../components/Picto/Course/SparkTop";
import {SparkBottom} from "../../../components/Picto/Course/SparkBottom";
import Context from "../../../tools/context";
import moment from "moment/moment";

function Transactions(props) {
  const {shortcode} = props.match.params;
  const history = useHistory();
  const [loadingSeller, setLoadingSeller] = useState(true);
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [ticket, setTicket] = useState(null);
  const [transactionState, setTransactionState] = useState('pending');
  const [seller, setSeller] = useState(null);
  const [error, setError] = useState("");

  const currentDate = new Date().toLocaleDateString('fr-FR', {
    day: 'numeric', month: 'long'
  });

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("CIBLER_AUTH", siteContext().clientToken);

  useEffect(() => {
    if (transactionState !== 'done')
      return;

    setTimeout(() => {
      history.replace('/course')
    }, 5000);
  }, [transactionState]);

  useEffect(() => {
    const getSeller = () => {
      setLoadingSeller(true);
      try {
        authClient(
          `api/public/seller/data/${shortcode}`,
          { headers: headers },
          (res) => {
            setSeller(res)
            setLoadingSeller(false)
          },
          (e) => {
            setError(e)
            setLoadingSeller(false)
          }
        );
      } catch (err) {
        console.error(err);
        setLoadingSeller(false)
      }
    }

    getSeller();
  }, []);

  useEffect(() => {
    const getTicket = () => {
      setLoadingTicket(true);
      try {
        authClient(
          `api/bienvenus/user/ticketV2?customer=${Context.partner.customerId}`,
          {method: 'GET'},
          (res) => {
            const courseTickets = res?.couponList?.filter(ticket => (
              ticket.used === false &&
              ticket.giftType?.toLowerCase() === "standard" &&
              ticket.campaignId === Context.partner.course?.campaignId &&
              (!ticket.expiration || moment().isBefore(ticket.expiration))
            ));
            if (!courseTickets.length) {
              setError(t('transactions.error.noTickets'));
              setLoadingTicket(false);
              return;
            }
            setTicket(courseTickets[0]);
            setLoadingTicket(false);
          },
          (e) => {
            console.error('Either customerId is incorrect or partner is not correctly set', e);
            setLoadingTicket(false)
            setError(e)
          }
        );
      } catch (err) {
        console.error(err);
        setLoadingTicket(false)
      }
    }

    getTicket();
  }, []);

  useEffect(() => {
    const doTransaction = () => {
      if (!ticket || !shortcode)
        return;
      try {
        authClient(
          `api/bienvenus/coupons/burn/${Context.partner.customerId}/?shortcode=${shortcode}&couponBurn=true`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify([ticket.prize])
          },
          () => setTransactionState('done'),
          (e) => {
            setError(t('transactions.error.default'));
            console.error(e)
          }
        );
      } catch (err) {
        console.error(err);
      }
    }

    if (!Context.partner.course?.campaignId) {
      history.replace(`/portefeuille?shortcode=${shortcode}`);
      return;
    }
    doTransaction();
  }, [ticket]);

  if (!seller && !error) return <div className="Transactions" />;

  const sellerImage = seller?.data?.find(data => data.key === 'store_image')?.value ||
    seller?.data?.find(data => data.key === 'store_image_2')?.value ||
    seller?.data?.find(data => data.key === 'store_image_3')?.value;

  return (
    <div className="Transactions">
      <div className="transactions__wrapper">
        {error
          ? <div className="transactions__error">
              <h6>{typeof error == "string" ? error : JSON.stringify(error)}</h6>
            </div>
          : <div
              className="transactions__container"
              style={{
                display: transactionState !== 'done' ? 'none' : ''
              }}
            >
              <div className="transactions__content">
                <h1>{t('transactions.title')}</h1>
                <h6>{t('transactions.subtitle')}</h6>
              </div>
              {sellerImage
                ? <div className="transactions__seller-image-container">
                  <div className="transactions__seller-image">
                    <OctogoneCourse id="octogone" />
                    <img src={sellerImage} alt="Seller image"/>
                  </div>
                  <SparkTop id="spark-top"/>
                  <SparkBottom id="spark-bottom"/>
                </div>
                : null
              }
              <div className="transactions__seller-infos">
                <h2>{seller.name?.toUpperCase()}</h2>
                <p><i>{t('transactions.visited')} {currentDate}</i></p>
              </div>
            <div className="transactions__progressBar">
              <div className="transactions__progress" />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default withRouter(Transactions);