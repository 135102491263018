import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css'; // theme
import {reactFormatter, ReactTabulator} from 'react-tabulator';
import {authCall, getAccount, getSeller} from '../tools/apitools';
import '../style/adminPage.scss';
import {Context, ContextUtil} from '../tools/context';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {getCategoryKeys} from '../tools/formFieldsDatabase';
import {siteContext, saveContext} from '../tools/toolbox';
import Loader from '../components/Loader';
import AppLink from '../components/AppLink';
import AppButton from '../components/AppButton';
import {clientT} from "../tools/i18n";
import {getQueryString} from "../tools/toolbox";
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {t} from '../tools/i18n';

class AdminPage extends Component {
    constructor() {
        super();
        this.shortcodeFilter = getQueryString("filter");
        this.tabRef = React.createRef();
        this.formatters = {
            registrationDate: this.formatCreationDate,
            convention: this.formatConvention,
            contact: this.formatContact,
            partnerStatus: this.formatStatus,
            id: this.formatButton,
            category: this.formatCategory,
            testAccount: this.formatTestAccount,
            withdrawStatus: this.formatWithdrawStatus,
        };

        this.clickhandler = {
            id: this.handleCellClick,
        };

        this.categoryKeys = getCategoryKeys();

        this.baseTable = [
            {
                title: t('adminPage.fields.partnerStatus'),
                field: 'partnerStatus',
                hozAlign: 'center',
                cssClass: 'select',
                headerFilter: 'select',
                headerFilterPlaceholder: 'Filtrer',
                headerFilterParams: {
                    values: {
                        "": "Tous",
                        accepted: "Accepté",
                        referenced: "Référencé",
                        pending: "En attente",
                        created: "Créé",
                        denied: "Refusé",
                    },
                    sortValuesList: 'asc',
                },
            },
            {
                title: t('adminPage.fields.contactEmail'),
                field: 'contactEmail'
            },
            {
                title: t('adminPage.fields.name'),
                field: 'name'
            },
            {
                title: t('adminPage.fields.contactPhone'),
                field: 'contactPhone',
                sorter: this.sortByPhone
            },
            {
                title: t('adminPage.fields.registrationDate'),
                field: 'registrationDate',
            },
            {
                title: t('adminPage.fields.category'),
                field: 'category',
                width: 300
            },
            {
                title: t('adminPage.fields.iban'),
                field: 'iban',
                hozAlign: 'center',
                formatter: 'tickCross',
                formatterParams: {allowTruthy: true},
            },
            {
                title: t('adminPage.fields.debit'),
                field: 'debit',
                hozAlign: 'center',
                sorter:"number",
            },
            {
                title: t('adminPage.fields.credit'),
                field: 'credit',
                hozAlign: 'center',
                sorter:"number",
            },
            {
                title: t('adminPage.fields.passage'),
                field: 'passage',
                hozAlign: 'center',
                sorter:"number",
            },
            {
                title: t('adminPage.fields.shortcode'),
                field: 'shortcode'
            },
            {
                title: t('adminPage.fields.group'),
                field: 'epci_name',
                hozAlign: 'center'
            },
            {
                title: t(`adminPage.fields.${Context.partner?.features?.enableSignatureDate ? "signature" : 'convention'}`),
                field: Context.partner?.features?.enableSignatureDate ? "signature" : 'convention',
                hozAlign: 'center',
                headerSort: false,
            },
            {
                title: t('adminPage.fields.withdrawStatus'),
                field: 'withdrawStatus',
                hozAlign: 'center',
                cssClass: 'select',
            },
            {
                title: '',
                field: 'id',
                headerSort: false,
                hozAlign: 'center',
                cssClass: 'selectable',
                cellClick: this.handleCellClick,
            },
        ];

        if (Context?.partner?.sponsor === 'passprovence') {
            this.baseTable = this.baseTable.filter(b =>
              b.field!=='iban' && b.field!=='credit' && b.field!=='debit')
        }else {
            this.baseTable = this.baseTable.filter(b =>
                b.field!=='convention')
        }

        if (Context?.partner?.adminPageTable) {
            this.baseTable = Context.partner.adminPageTable;
        }
        if(siteContext().adminToken){

            siteContext().accessToken = siteContext().adminToken;
            siteContext().refreshToken = siteContext().adminRefresh;
            siteContext().adminToken = null;
            siteContext().adminRefresh = null;
            saveContext()
        }
        this.state = {
            vendors: [],
            filter: ''||this.shortcodeFilter,
            errorMessage: null,
            actionToTrigger: "",
            isEnrichingSellers: false,
        };
    }


    componentDidMount() {
        // document.title = 'Page d\'administration';
        this.initSellers();
    }

    initSellers = () => {

        authCall(
            `/api/sellers/${Context.partner.sponsor}${this.state.filter ?"?filters="+this.state.filter:""}`,
            {},
            (r) => {
                console.log(r);
                this.setState({vendors: r.map(d => (
                  {
                    ...d,
                    ...d.datamap,
                    contact: `https://${Context?.partner?.sponsor}.activehosted.com/app/contacts?q=${d.contactEmail}`
                  }
                ))});
            },
            () =>
                this.setState({
                    errorMessage:
                    clientT('adminPage.errorMessageLoading'),
                })
        );
    }

    handleChange = (value, cell) => {
        const id = cell.getRow().getData().id;

        const newState = [...this.state.vendors];
        const row = newState.find((s) => s.id === id);
        if (!row) return false;
        const oldData = row.partnerStatus;
        row.partnerStatus = value;

        // this.setState({vendors: newState});

        const body = {};
        body[`${Context.partner.sponsor}_status`] = value;

        authCall(
            `api/seller/data/${id}`,
            {
                method: 'PUT',
                body: JSON.stringify(body),
            },
            () => {
                const selectElement = cell.getElement().querySelector('select');
                if (selectElement) {
                    selectElement.className = '';
                    selectElement.classList.add(value.toLowerCase());
                }
                if (!this.state.errorMessage)
                    return;
                this.setState({
                    errorMessage: '',
                });
            },
            () => {
                row.partnerStatus = oldData;
                this.setState({
                    vendors: newState,
                    errorMessage: clientT('adminPage.errorMessageRecording'),
                });
            }
        );
    };

    handleWithdrawStatusChange = (value, cell) => {
        const id = cell.getRow().getData().id;

        const newState = [...this.state.vendors];
        const row = newState.find((s) => s.id === id);
        if (!row) return false;
        const oldData = row.withdrawStatus;
        row.withdrawStatus = value;

        this.setState({vendors: newState});

        authCall(
          `api/sellers/${id}/withdrawStatus/${value}`,
          {
              method: 'POST',
          },
          () => {
              this.setState({
                  errorMessage: '',
              });
          },
          () => {
              row.withdrawStatus = oldData;
              this.setState({
                  vendors: newState,
                  errorMessage:  clientT('adminPage.errorMessageRecording'),
              });
          }
        );
    };

    sellerSignin = (cell) => {
        const {history} = this.props;
        const sellerToConnectEmail = cell.getRow().getData().contactEmail;
        let reqParams = {
            method: 'POST',
            body: JSON.stringify({
                username: sellerToConnectEmail,
                password: 'nopassword',
                grantType: 'password',
                scope: 'read+write',
            }),
        };

        if (!window.__STATE__) {
            siteContext().adminToken = siteContext().accessToken;
            siteContext().adminRefresh = siteContext().refreshToken;
        }

        authCall(
            '/api/users/auth?shortcode='+cell.getRow().getData().shortcode,
            reqParams,
            async (result) => {
                this.setState({displaySigninPopup: false});
                if (!window.__STATE__) {
                    siteContext().accessToken = result.access_token;
                    siteContext().refreshToken = result.refresh_token;
                    saveContext();
                    await getAccount();
                    await getSeller();
                    window.location.href = '/dashboard-new';
                } else {
                    window.open(
                      `https://prod.bienvenus.com/dashboard-new?part=${Context.partner.sponsor
                      }&ta=${siteContext().accessToken
                      }&ts=${result.access_token}`);
                }
            },

            () => {
                console.log('reject');
            }
        );
    };

    handleReset  = (cell) => {
        const {history} = this.props;
        const sellerToConnectEmail = cell.getRow().getData().contactEmail;
        let reqParams = {
            method: 'POST',
            body: JSON.stringify({
                username: sellerToConnectEmail,
                password: 'nopassword',
                grantType: 'password',
                scope: 'read+write',
            }),
        };

        authCall(
            '/api/users/forceReset',
            reqParams,
            async (result) => {
                let newpwd = result.password;
                this.setState({username:result.username,displayNewPwd: true,newPwd:newpwd});
            },
            () => {
                console.log('reject');
            }
        );
    };

    handleTriggerAction = (event, cell) => {
        switch (event.target.value) {
            case 'seller_data': //Open new tab  to /adminPage/${seller_id}
                this.handleCellClick(event, cell);
                event.target.value = '';
                break;
            case 'seller_signIn': //Sign in seller account
                this.sellerSignin(cell);
                event.target.value = '';
                break;
            case 'flag_test_account': //Flag seller as test account
                this.handleTestAccount(cell);
                event.target.value = '';
                break;
            case 'reset':
                this.handleReset(cell);
                event.target.value = '';
                break;
            default:
                console.log('No action triggered');
        }
    };

    formatTestAccount = (cell, params, onRendered) => {
        return reactFormatter(
            <input
                type='checkbox'
                onChange={(e) => {
                    this.handleTestAccount(e.target.checked, cell, 'testAccount');
                }}
            />
        )(cell, params, onRendered);
    };

    formatActionsList = (cell, params, onRendered) => {
        return reactFormatter(
            <div>
                <select onChange={(event) => this.handleTriggerAction(event, cell)}>
                    <option value='' selected disabled>Action</option>
                    <option value='seller_data'>{clientT('adminPage.actionList.sellerData')}</option>
                    <option value='flag_test_account'>{clientT('adminPage.actionList.flagTestAccount')}</option>
                    <option value='seller_signIn'>Se connnecter</option>
                    <option value='reset'>Reset MDP</option>
                </select>
            </div>
        )(cell, params, onRendered);
    };

    formatConvention = (cell, params, onRendered) => {
        const mail = cell.getRow().getData().contactEmail;
        const url = `https://app.pandadoc.com/a/#/documents-next?sortBy=status&direction=desc&customDate=date_modified&search=${mail}`;
        return reactFormatter(
            <div className='contract_signature_icon'>
                <AppLink target='_blank' href={url}>
                    <FontAwesomeIcon icon='file-signature' size='lg'/>
                </AppLink>
            </div>
        )(cell, params, onRendered);
    };

    formatContact = (cell, params, onRendered) => {
        const mail = cell.getRow().getData().contactEmail;
        const url = `https://${Context?.partner?.sponsor}.activehosted.com/app/contacts?q=${mail}`;

        return reactFormatter(
            <div className='contact_icon'>
                <AppLink target='_blank' href={url}>
                    <FontAwesomeIcon icon={faEnvelope} size='lg' />
                </AppLink>
            </div>
        )(cell, params, onRendered);
    }

    formatStatus = (cell, params, onRendered) => {
        const cellValue = cell.getValue();
        return reactFormatter(
            <select
                className={cellValue ? cellValue.toLowerCase() : ''}
                defaultValue={cellValue ? cellValue.toLowerCase() : ''}
                onChange={(e) => this.handleChange(e.target.value, cell)}
            >
                <option className='accepted' value='accepted'>
                    Accepté
                </option>
                <option className='referenced' value='referenced'>
                    Referencé
                </option>
                <option className='pending' value='pending'>
                    En attente
                </option>
                <option className='created' value='created'>
                    Crée
                </option>
                <option className='denied' value='denied'>
                    Refusé
                </option>
            </select>
        )(cell, params, onRendered);
    };

    formatWithdrawStatus = (cell, params, onRendered) => {
        const cellValue = cell.getValue();
        return reactFormatter(
            <select
                className={cellValue ? cellValue.toLowerCase() : ''}
                defaultValue={cellValue}
                onChange={(e) => this.handleWithdrawStatusChange(e.target.value, cell)}
            >
                <option className='accepted' value='OK'>
                    OK
                </option>
                <option className='pending' value='PROCESSING'>
                    En cours
                </option>
                <option className='denied' value='BLOCKED'>
                    Bloqué
                </option>
                <option className='denied' value='BANNED'>
                    Bannis
                </option>
            </select>
        )(cell, params, onRendered);
    };

    formatButton = (cell, params, onRendered) => {
        return '+';
    };

    formatNumber = (number) => {
        const space = /\s/g;

        if (!number) return "";
        number = number.replace(space, '');
        if (number[0] === '+') number = number.substring(1);
        switch (number.length) {
            case 11:
                number = '0' + number.substring(2);
                break;
            case 12:
                number = '0' + number.substring(3);
                break;
            case 13:
                number = '0' + number.substring(4);
                break;
            default:
                break;
        }
        return number;
    }

    sortByPhone = (a, b) => {
        if (!a && !b) return 0;

        const aNumber = this.formatNumber(a);
        const bNumber = this.formatNumber(b);
        return aNumber - bNumber;
    }

    handleCellClick = (e, cell) => {
        window.open(`${window.location.href}/${cell.getValue()}`, '_blank').focus();
        // this.props.history.push(`/adminPage/${cell.getValue()}`)
    };

    handleTestAccount = (cell) => {
        const id = cell.getRow().getData().id;

        const newState = [...this.state.vendors];
        const row = newState.find((s) => s.id === id);
        if (!row) return false;
        const oldData = row.testAccount;
        const filteredVendorsList = newState.filter((v) => {
            return v.id !== id;
        });
        this.setState({vendors: filteredVendorsList});
        const body = {testAccount: true};
        authCall(
            `api/seller/data/${id}`,
            {
                method: 'PUT',
                body: JSON.stringify(body),
            },
            () => {
                this.setState({
                    errorMessage: '',
                });
            },
            () => {
                row.testAccount = oldData;
                this.setState({
                    vendors: newState,
                    errorMessage:  clientT('adminPage.errorMessageRecording'),
                });
            }
        );
    };

    test = (input, filter) => {
        return (input + '' || '').toLowerCase().includes(filter.toLowerCase());
    };

    formatCreationDate = (cell, params, onRendered) => {
        const dateToFormat = cell.getRow().getData().registrationDate;
        const dateFormatted = moment(dateToFormat).format('DD/MM/YYYY');
        return reactFormatter(<div>{dateFormatted}</div>)(cell, params, onRendered);
    };

    formatSignatureDate = (cell, params, onRendered) => {
        const dateToFormat = cell.getRow().getData().signature;
        const dateFormatted = dateToFormat && moment(dateToFormat).format('DD/MM/YYYY') || '';
        return reactFormatter(<div>{dateFormatted}</div>)(cell, params, onRendered);
    };

    formatCategory = (cell) => {
        return this.categoryKeys[cell.getValue()] || cell.getValue();
    };

    enrichSellers = () => {
        const selectedData = this.tabRef.current.table.getSelectedData();
        const ids = selectedData.map((d) => d.id);

        this.setState({ isEnrichingSellers: true });

        authCall(
          `api/seller/enrich?customerId=${Context.partner.customerId}`,
          {
              method: 'POST',
              body: JSON.stringify(ids),
          },
          () => this.setState({ isEnrichingSellers: false }),
          (err) => {
              console.error('error enriching sellers', err)
              this.setState({ isEnrichingSellers: false });
          }
        ).catch(err => console.error('error enriching sellers', err));
    }

    downloadCsv = () => {
        const selectedData = this.tabRef.current.table.getSelectedData();
        if (!selectedData.length)
            this.tabRef.current.table.options.downloadRowRange = 'all';
        else
            this.tabRef.current.table.options.downloadRowRange = 'selected';
        this.tabRef.current.table.download('csv', 'data.csv', {delimiter: ';'});
    };

    render() {
        const isRoleAdmin = siteContext().role.ROLE_ADMIN;
        const kitKey = `${Context.partner.sponsor}_kitdecommunication`;

        const columns = this.baseTable.map((e) => ({
            ...e,
            formatter: this.formatters[e.field] || null,
            cellClick: this.clickhandler[e.field] || null,
        }));

        columns.unshift({
            formatter: 'rowSelection',
            titleFormatter: 'rowSelection',
            hozAlign: 'center',
            headerSort: false,
            download: false,
            maxWidth: 75,
        });

        columns.splice(1, 0, {
            title: 'Actions',
            field: 'id',
            formatter: this.formatActionsList,
            hozAlign: 'left',
            resizable: false,
            minWidth: 155,
            headerSort: false,
            download: false,
        });


        const options = {
            downloadDataFormatter: (data) => data,
            downloadReady: (fileContents, blob) => blob,
            downloadRowRange: 'selected',
            layout: 'fitDataFill',
            resizableColumns:true,
            pagination: 'local',
            paginationSize: 20,
            autoResize: true,
            initialSort: [
                {column:"registrationDate", dir:"desc"}
            ]
        };

        const {filter, isEnrichingSellers} = this.state;
        const filtered = filter
            ? this.state.vendors.filter(
                (v) =>
                    this.test(v.contactEmail, filter) ||
                    this.test(v.name, filter) ||
                    this.test(v.contactPhone, filter) ||
                    this.test(v.category, filter) ||
                    this.test(v.iban, filter) ||
                    this.test(v.shortcode, filter) ||
                    this.test(v.epci_name, filter)
            )
            : this.state.vendors;
        const popupMessage = this.state.displayNewPwd ? "login:"+this.state.username+" Nouveau mdp : "+this.state.newPwd:"";
        return (
            <div className='adminPage'>
                {this.state.loaderOn ? <Loader /> : null}
                <div className='title'>{clientT('adminPage.title')}</div>
                {this.state.errorMessage ? (
                    <div className='register_error'>{this.state.errorMessage}</div>
                ) : null}
                <div>
                    <input
                        id={"searchinput"}
                        className='adminSearch'
                        placeholder={clientT('adminPage.searchplaceholder')}
                    />
                    <button className='adminSearch' onClick={() =>{this.state.filter= document.getElementById("searchinput").value; this.initSellers()}}>rechercher</button>
                </div>
                {filtered.length >= 9000 ? <div className='title'>Plus de 9000 établissements, tout les résultats n'ont pas été chargés </div> : <div className='title' >{filtered.length}  établissements</div>}
                <div className='action_list'>
                    {siteContext().role.ROLE_ADMIN && (
                        <AppButton
                            onClick={() => {
                                // this.props.history.replace('/customeraccount');
                                window.open(
                                  `https://prod.bienvenus.com/customeraccount?part=${
                                      Context.partner.sponsor}&ta=${siteContext().accessToken}`);
                            }}
                            event="Connexion compte client"
                        >
                          {clientT('adminPage.customerAccountLogin')}
                        </AppButton>
                    )}

                    {isRoleAdmin ? (
                      <AppButton
                        event="EnrichSellers"
                        onClick={this.enrichSellers}
                      >
                        {isEnrichingSellers ? <Loader /> : clientT('adminPage.enrichSellers')}
                      </AppButton>
                    ) : null}
                    <AppButton event="Export CSV" onClick={this.downloadCsv}>{clientT('adminPage.exportCsv')}</AppButton>
                </div>
                <div id='message'>{popupMessage}</div>
                <div></div>
                <div>
                    <ReactTabulator
                        ref={this.tabRef}
                        data={[...filtered]}
                        columns={columns}
                        layout={'fitData'}
                        options={options}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(AdminPage);
