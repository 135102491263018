import React, {Component} from 'react';
import '../../../style/ticketsInfoPopup.scss';
import {Context} from '../../../tools/context';
import {popupTexts} from "../../../Utils/Constants";
import {getTicketDenomination} from "../../../tools/toolbox";
import {clientT, t} from "../../../tools/i18n";
import {replace} from "sinon";
import AppButton from '../../AppButton';

export default class TicketsInfoPopup extends Component {

  renderPopupContent = () => {
    const {type, title, lines, richContent} = this.props;

    if (type && type !== 'custom') {
      const partTexts = Context.partner.popupTexts;
      let content = partTexts && partTexts[type] ? partTexts[type] : popupTexts[type];
      content = content || {};
      if (content.noPopup) return null;
      const lines = clientT(`popup.${type}.lines`, {fallback: ''}) || content && content.lines
      const partTitle = clientT(`popup.${type}${lines ? '.title' : ''}`, {fallback: ''}) || (lines ? content.title : content);
      const partLines = lines || [];
      return this.renderMessage(partTitle, partLines, content.richContent);
    }

    return this.renderMessage(title, lines, richContent);
  }

  keyReplacer = (input) => {
    if (!input || !input.replace) return '';

    let output = input.replace(new RegExp('{{ticket}}', 'g'), getTicketDenomination());
    const {replaceKeys} = this.props;

    if (!replaceKeys || !replaceKeys.length) return output;

    replaceKeys.forEach(({k, v}) => {
      output = output.replace(new RegExp(`{{${k}}}`, 'g'), v);
    })

    return output;
  }

    renderMessage = (title, lines = [], rich) => {
        console.log("no popup ?", Context.partner.popupTexts.noPopup);
        if (Context.partner.popupTexts.noPopup) {
            if (!title || !title.length || !lines || !lines.length) return null;
            return (
                <div className="contentContainer">
                    <div className="infoBurnTitle" style={{ color: Context.partner.infoBurnPopup?.color }}>
                        {t('infoBurnPopup.primaryTitle')}
                    </div>
                    <div className="infoBurnText">
                        {t('infoBurnPopup.primaryText')}
                    </div>
                    <div className="infoBurnTitle" style={{ color: Context.partner.infoBurnPopup?.color }}>
                        {t('infoBurnPopup.secondaryTitle')}
                    </div>
                    <div className="infoBurnText">
                        <div>
                            <span className="boldText">{t('infoBurnPopup.boldText')}</span>
                            <div className="innerMargin">{t('infoBurnPopup.secondaryText')}</div>
                        </div>
                    </div>
                </div>
            );
        }

        // Handle rich content
        if (rich) {
            return <div dangerouslySetInnerHTML={{__html: this.keyReplacer(title)}} />;
        }

        // Ensure lines is an array to avoid runtime errors
        lines = Array.isArray(lines) ? lines : [];

        return (
            <div className={`ticketInfoBaseWrapper`}>
                {title && <div className="popuptitle" dangerouslySetInnerHTML={{__html: this.keyReplacer(title)}} />}
                {lines.length > 0 && lines.map((line, index) =>
                    <div key={index} dangerouslySetInnerHTML={{__html: this.keyReplacer(line)}} />
                )}
            </div>
    );
  }

  renderBlock = () => {
    let {title, lines} = this.props;
    console.log(lines)
    if (!lines || !lines.length) lines = [];
    return (
      <div className={`ticketInfoBaseWrapper`}>
        {title && <div className="popuptitle" >{title}</div>}
        {lines.length > 0 && lines.map((line, index) =>
          <div key={index} >{lines[index]}</div>
        )}
      </div>
    )
  }

  noBubble = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    return false;
  }

  render() {
    const {close, bottomButtons, crossButton, renderBlock, addendum, children} = this.props;
    const content = renderBlock ? this.renderBlock() : this.renderPopupContent();

    if (!content) return null;

    return (
        <div className="popupOverlay" onClick={close}>
            <div className="popupBase" onClick={this.noBubble}>
                {crossButton && <AppButton event="Close popup" client className="cancelBtn" onClick={close}>x</AppButton>}
                {content}
                {children}
                {!crossButton && <div className="buttonWrapper">
                    {bottomButtons?.length ?
                        bottomButtons.filter(a => a.label).map(b => (
                            <AppButton client event={b.label} className={b.class || 'mainButton'} onClick={b.action}>{b.label}
                            </AppButton>
                        )) : <AppButton client event="Fermer" className="mainButton" onClick={close}>{t('common.close')}</AppButton>
                    }
                </div>}
                {addendum}
            </div>
        </div>
    )
  }
}
