import AppButton from "../../components/AppButton";
import React from "react";

export default ({previous, next}) => (
  <div className="buttonBlock">
    <AppButton
      onClick={previous}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8H1" stroke="#009989" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 15L1 8L8 1" stroke="#009989" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </AppButton>
    <AppButton
      type={next ? 'button' : "submit"}
      onClick={next ? next : undefined}
    >
      <span>Étape suivante</span>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 8H15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 15L15 8L8 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </AppButton>
  </div>
)