const generateSEPA = (data) => (
`<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pain.001.001.03" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <CstmrCdtTrfInitn>
    <GrpHdr>
      ${generateGrpHdr(data)}
    </GrpHdr>
    <PmtInf>
      ${generatePmtInf(data)}
    </PmtInf>
  </CstmrCdtTrfInitn>
</Document>`
);

const generateGrpHdr = (data) => (
`<MsgId>${data.msgId}</MsgId>
      <CreDtTm>${data.creDtTm}</CreDtTm>
      <NbOfTxs>${data.nbOfTxs}</NbOfTxs>
      <CtrlSum>${data.ctrlSum}</CtrlSum>
      <InitgPty>
        <Nm>${data.nm}</Nm>
      </InitgPty>`
);

const generatePmtInf = (data) => (
`<PmtInfId>${data.pmtInfId}</PmtInfId>
      <PmtMtd>${data.pmtMtd}</PmtMtd>
      <BtchBookg>${data.btchBookg}</BtchBookg>
      <NbOfTxs>${data.nbOfTxs}</NbOfTxs>
      <CtrlSum>${data.ctrlSum}</CtrlSum>
      <PmtTpInf>
        <SvcLvl>
          <Cd>SEPA</Cd>
        </SvcLvl>
        <CtgyPurp>
          <Cd>SUPP</Cd>
        </CtgyPurp>
      </PmtTpInf>
      <ReqdExctnDt>${data.reqdExctnDt}</ReqdExctnDt>
      <Dbtr>
        <Nm>${data.nm}</Nm>
      </Dbtr>
      <DbtrAcct>
        <Id>
          <IBAN>${data.iban}</IBAN>
        </Id>
        <Ccy>EUR</Ccy>
      </DbtrAcct>
      <DbtrAgt>
        <FinInstnId>
          <BIC>${data.bic}</BIC>
        </FinInstnId>
      </DbtrAgt>
      <UltmtDbtr>
        <Nm>${data.nm}</Nm>
      </UltmtDbtr>
${data.transactions?.map((transaction) => generateCdtTrfTxInf(transaction)).join('\n')}`
);

const generateCdtTrfTxInf = (transaction) => (
`      <CdtTrfTxInf>
         <PmtId>
           <EndToEndId>${transaction.endToEndId}</EndToEndId>
         </PmtId>
         <Amt>
           <InstdAmt Ccy="EUR">${transaction.instdAmt}</InstdAmt>
         </Amt>
         <CdtrAgt>
           <FinInstnId>
             <BIC>${transaction.bic}</BIC>
           </FinInstnId>
         </CdtrAgt>
         <Cdtr>
           <Nm>${transaction.nm}</Nm>
         </Cdtr>
         <CdtrAcct>
           <Id>
             <IBAN>${transaction.iban}</IBAN>
           </Id>
         </CdtrAcct>
         <Purp>
           <Cd>SUPP</Cd>
         </Purp>
         <RmtInf>
           <Ustrd>${transaction.ustrd}</Ustrd>
         </RmtInf>
       </CdtTrfTxInf>`
);

export {
  generateSEPA,
}