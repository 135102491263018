import React, {Component} from 'react';
import '../../style/TicketsPage.scss';
import {authClient} from '../../tools/apitools';
import {getQueryString} from '../../tools/toolbox';
import MyPromos from './MyPromos';
import TicketConsumer from "./TicketConsumer";
import TicketExchange from "../../components/TicketExchange";
import ConfirmedTicket from "../../components/ConfirmedTicket"
import Context from '../../tools/context';
import moment from 'moment';
import {popupTypes} from '../../Utils/Constants';
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";
import {clientT, t} from "../../tools/i18n";
import AppLink from "../../components/AppLink";
import {track} from "../../tools/tracking";
import {withRouter} from "react-router-dom";

class PromosPage extends Component {
    constructor() {
        super();

        this.initialState = {
            tickets: [],
            selectedTicket: null,
            confirmPopup: false,
            sellerName: '',
            totalPrize: '',
            typePopup: '',
            title: '',
            step: 0,
            isLoading: true,
        };

        this.state = {...this.initialState};
    }

    componentDidMount() {
        this.getGifts();
    }

    onGetGiftResponse = (res) => {
        const buffer = res?.couponList
          ?.filter(ticket => {
              if (ticket.used)
                  return false;
              if (!ticket.giftType || ticket.giftType.toLowerCase() !== "standard")
                  return false;
              if (ticket.campaignType === 'PaymentCard')
                  return (ticket.sorewardsCardBalance === null || ticket.sorewardsCardBalance > 0);
              if (ticket.expiration && moment().isAfter(ticket.expiration))
                  return false;
              return true;
          })

        this.setState({isLoading: false, tickets: buffer || []});
    }

    onGetGiftError = (status, err) => {
        console.error('Either customerId is incorrect or partner is not correctly set', err);
        this.setState({isLoading: false, typePopup: 'custom', title: "Une erreur est survenue"});
    }

    getGifts = async () => {
        authClient(`api/bienvenus/user/ticketV2?customer=${Context.partner.customerId}`, {method: 'GET'},
            this.onGetGiftResponse, this.onGetGiftError);
    }

    handleSelection = (ticket) => {
        this.setState({
            selectedTicket: ticket,
            confirmPopup: true,
        })
        track('selectPromo', {
            prize: this.state.selectedTicket.prize,
            uuid: this.state.selectedTicket.uuid,
        }, true);
    }

    onBurnResponse = (res) => {
        this.setState({
            sellerId: res?.sellerId,
            sellerName: res.sellerName,
            totalPrize: res.totalPrize,
            confirmPopup: false,
            step: 1,
        });
    }

    onBurnError = (error, status) => {
        this.setState({typePopup: popupTypes[error.message] || popupTypes.unknownError, confirmPopup: false});
    }

    handleBurn = async () => {
        const {selectedTicket} = this.state;
        if (!selectedTicket) return;

        try {
            authClient(
                `api/bienvenus/coupons/burn/${Context.partner.customerId}/?shortcode=${selectedTicket.sellerData.shortcode}`,
                {
                    method: 'POST',
                    body: JSON.stringify([selectedTicket.uuid])
                },
                this.onBurnResponse,
                this.onBurnError,
            );
        } catch (err) {
            console.error(err);
        }
    }

    reset = () => {
        this.setState({...this.initialState})
        this.getGifts();
    }

    render() {
        const {
            tickets, selectedTicket,
            confirmPopup, step, isLoading,
        } = this.state;

        const steps = clientT('myPromo.timeline', {fallback: []})
        return (
            <div className="TicketsPage">
                <div className="clientTitle">
                    {clientT('myPromo.title')}
                </div>
                {step < steps.length && <div className="stepper">
                    <div className="current">{steps[step]}</div>
                    {steps[step + 1] && <div className="futur">{clientT('common.next')} : {steps[step + 1]}</div>}
                    <div className="steps">
                        {steps.map((s, i) => <div
                            key={`step_${i}`}
                            data-step={step}
                            data-i={i}
                            className={`step ${step >= i ? 'done' : 'todo'}`}
                        />)}
                    </div>
                </div>}
                {step === 0 && <MyPromos
                    tickets={tickets}
                    handleSelection={this.handleSelection}
                    selectedTicket={selectedTicket}
                    isLoading={isLoading}
                />}
                {step === 1 && <ConfirmedTicket
                    selectedTickets={[selectedTicket]}
                    sellerShortCode={selectedTicket.sellerData.shortcode}
                    sellerName={selectedTicket.sellerData.name}
                    sellerId={selectedTicket.sellerData.id}
                    reset={this.reset}
                    promo
                />}
                {confirmPopup &&
                    <TicketsInfoPopup
                      title={clientT('myPromo.confirmation.title')}
                      bottomButtons={[
                          {label: t('common.cancel'), action: () => this.setState({confirmPopup: false, selectedTicket: null}), class: 'secButton'},
                          {label: clientT('myPromo.confirmation.confirm'), action: this.handleBurn},
                      ]}
                      close={() => this.setState({confirmPopup: false})}
                    >
                        <div className={"confirmBurnPopup"}>
                            <div className="label">{clientT('myPromo.confirmation.text')}</div>
                            <div className="warning">{clientT('myPromo.confirmation.warning')}</div>
                        </div>
                    </TicketsInfoPopup>
                }
                {this.state.typePopup &&
                <TicketsInfoPopup
                    type={this.state.typePopup}
                    title={this.state.title}
                    close={() => this.setState({typePopup: '', gotoActivation: false})}
                    addendum={this.state.gotoActivation && <div className="refundBlock">
                        <AppLink href={`/ticket-activation`}
                                 dangerouslySetInnerHTML={{__html: clientT('ticketActivation.goto')}}
                        /></div>}
                />}
            </div>
        );
    }

}

export default withRouter(PromosPage);

export {PromosPage}
