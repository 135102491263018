import React, { Component } from 'react';
import Context from '../../tools/context';
import '../../style/TicketsPage.scss';
import moment from 'moment';
import {clientT} from "../../tools/i18n";
import AppButton from "../../components/AppButton";
import LoaderSpinner from "react-loader-spinner";
import {authClient} from "../../tools/apitools";
import {withRouter} from "react-router-dom";

class TicketActivation extends Component {
    constructor() {
        super();
        this.state = {
            activationError: '',
            activationCode: '',
            activationLoader: false,
        }
        moment.locale('fr');
    }

    handleActivateCode = (input) => {
        this.setState({activationError: '', activationLoader: true});
        if (!input) return this.setState({activationError: clientT('ticketActivation.emptyError'), activationLoader: false})

        authClient(`/api/prizes/claim?pwd=${input}&customerId=${Context.partner.customerId}`, {
              method: 'GET'
          },
          (res) => {
              if (!res.length)
                  return this.setState({activationError: clientT('ticketActivation.invalidError'), activationLoader: false})
              this.props.history.push("/my-tickets");
          },
          (_e, status) => {
              let error;
              if (status === 404) error = clientT('ticketActivation.invalidError')
              else error = 'Une erreur est survenue';

              this.setState({activationError: error, activationLoader: false});
          });

    }

    render () {
        return (
            <div className='my-tickets-container TicketsPage'>
                <h1 className="clientTitle">{clientT('ticketActivation.title')}</h1>
                <hr />
                <div className="clientSubTitle activation">
                    <div>{
                        clientT('ticketActivation.label')
                    }
                    </div>
                    <div>
                        {this.state.activationError && <div className="error">{this.state.activationError}</div>}
                        <input
                          placeholder="IFNKHXT89UI"
                          value={this.state.activationCode}
                          onChange={(e) => this.setState({activationCode: e.target.value})}
                        />
                        <AppButton event="Valider" client className="mainButton" onClick={() => this.handleActivateCode(this.state.activationCode)} style={{width: '100px'}}>
                            {this.state.activationLoader ?
                              <LoaderSpinner
                                type="ThreeDots"
                                color={"white"}
                                height={9}
                                width={40}
                              /> : clientT('ticketActivation.buttonLabel')
                            }
                        </AppButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TicketActivation);
