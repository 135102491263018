import React, { Component } from 'react';
import Context from '../../tools/context';
import { authClient } from '../../tools/apitools';
import '../../style/TicketsPage.scss';
import moment from 'moment';
import Ticket from "./ticket";
import {clientT} from "../../tools/i18n";
import ConfirmedTicket from "../../components/ConfirmedTicket";
import AppLink from "../../components/AppLink";
import LoaderSpinner from "react-loader-spinner";
import Coupon from "./coupon";
import PaymentCards from "./ticket/PaymentCard";

class MyTicketsUsed extends Component {
    constructor() {
        super();
        this.ticketNumber = Context.partner.client?.usedTicketNumber || 0;
        this.state = {
            tickets: [],
            selectedTicket: null,
            showAll: !!this.ticketNumber,
            isLoading: true,
        }
        moment.locale('fr');
        this.getGifts();
    }

    onGetGiftResponse = (res) =>{
        const couponList = res?.couponList;

        const buffer = couponList
          ?.filter(ticket => {
              if (ticket.campaignType === 'PaymentCard')
                  return (ticket.sorewardsCardBalance !== null && ticket.sorewardsCardBalance <= 0) || ticket.used;
              if (ticket.expiration && moment().isBefore(ticket.expiration))
                  return true;
              return ticket.used;
          })
          ?.sort((a, b) => new Date(b.burnDate) - new Date(a.burnDate));
        if (buffer.length !== 0) {
            this.setState({isLoading: false, tickets: buffer});
        } else
            this.setState({isLoading: false, tickets: ['not']});
    }

    onGetGiftError = (status, err) => {
        console.error('Either customerId is incorrect or partner is not correctly set', err);
        this.setState({isLoading: false, tickets: ['not']});
        // this.props.history.push('temporary');
    }

    getGifts = async () => {
            authClient(`api/bienvenus/user/ticketV2?customer=${Context.partner.customerId}`, {method: 'GET'},
              this.onGetGiftResponse,this.onGetGiftError);
    }

    handleSelection = (selectedTicket) => {
        this.setState({selectedTicket})
    }

    toDisplay = () => {
        const { tickets, showAll, isLoading } = this.state;

        if (isLoading)
            return (
              <div className="loader">
                <LoaderSpinner type="Oval" height={80} width={80} />
              </div>
            );

        let paymentCards = [];
        let ticketsWithoutCoupons = [];

        tickets.map(ticket => {
            if (ticket.campaignType === 'PaymentCard')
                paymentCards.push(ticket);
            else
                ticketsWithoutCoupons.push(ticket);
        });

        const someTickets = this.ticketNumber
          ? ticketsWithoutCoupons.slice(0, this.ticketNumber)
          : ticketsWithoutCoupons;

        const mergedTickets = someTickets.reduce((acc, ticket) => {
            const existingTicket = acc.find(t => t.campaignId === ticket.campaignId && t.campaignType === "PaidPass");
            if (existingTicket) {
                existingTicket.uuids.push(ticket.uuid);
            } else {
                acc.push({
                    ...ticket,
                    uuids: [ticket.uuid]
                });
            }
            return acc;
        }, []);

        const otherTickets = !this.ticketNumber || showAll
          ? []
          : ticketsWithoutCoupons.slice(this.ticketNumber);

        if (tickets[0] !== 'not')
            return (
                <div className='used'>
                    <div className="ticketList usedTicketList">
                        {paymentCards?.length ? <PaymentCards tickets={paymentCards} used /> : null}
                        {mergedTickets.map((ticket, index) => <Ticket
                          key={index}
                          ticket={ticket}
                          index={index}
                          handleSelection={this.handleSelection}
                        />)}
                        {!!this.ticketNumber && <button className="showHideButton" onClick={
                            () => this.setState(prev => ({showAll: !prev.showAll}))
                        }>
                            {clientT(showAll ? 'usedTickets.showTickets' : 'usedTickets.hideTickets')}
                        </button>}
                        {otherTickets.map((ticket, index) => <Ticket
                          ticket={ticket}
                          index={index}
                          handleSelection={this.handleSelection}
                        />)}
                    </div>
                </div>
            );
        else
            return (
                <div>
                    <p>{clientT('usedTickets.noTicket')}</p>
                </div>
            );
    }

    render () {
        return (
            <div className='my-tickets-container TicketsPage'>
                <h1 className="clientTitle">{clientT('usedTickets.title')}</h1>
                <hr />
                {this.toDisplay()}
                {this.state.selectedTicket &&
                  <div className="popupRecapUsed">
                    <ConfirmedTicket
                      selectedTickets={[this.state.selectedTicket]}
                      sellerName={this.state.selectedTicket.sellerRecipient}
                      sellerShortCode={this.state.selectedTicket.sellerRecipientId}
                      reset={() => this.setState({selectedTicket: null})}
                      usedTicket
                      popup
                    />
                  </div>
                }
            </div>
        )
    }
}

export default MyTicketsUsed;
