// Utility function to format date ranges
export const formatDate = (rangeArray) => {
    // Ensure dates are in Date object form or convert them if they are strings
    if (!Array.isArray(rangeArray)) {
        return "2022-11-01T09:00:00.000Z,2022-11-02T09:00:00.000Z"; // Default value for demonstration
    }
    const formattedDates = rangeArray.map(date =>
        (date instanceof Date ? date : new Date(date)).toISOString()
    );
    return formattedDates.join(',');
}
