import React, {Component} from 'react';
import '../../style/ticketValidation.scss';
import {Context} from "../../tools/context";
import AppLink from '../../components/AppLink';
import {clientT, t} from "../../tools/i18n";
import {track} from "../../tools/tracking";
import AppButton from '../../components/AppButton';
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";

class TicketValidation extends Component {
  componentDidMount() {
    window.scrollTo(0, document.body.scrollHeight);

    let {selectedTickets, partnerData} = this.props;

    if (!Array.isArray(selectedTickets)) return;
    track('viewTicketValidation', {
      prize: selectedTickets.map(ticket => ticket.prize).join('|'),
      uuid: selectedTickets.map(ticket => ticket.uuid).join('|'),
      shortcode: partnerData?.partnerCode
    });
  }


  render() {
    const {selectedTickets, partnerData, handleBurn} = this.props;
    const total = selectedTickets.reduce((acc, t) => acc + t.value, 0);
    const totalThreshold = selectedTickets.reduce((acc, t) => acc + t.threshold, 0);
    const condition = selectedTickets[0]?.condition || '';
    const refundPrizes = encodeURIComponent(selectedTickets.map(p => p.prize).join('|'));

    return (
      <div className="ticketValidation">
        <div className="summary">
          <p>{clientT('myTicketsV2.action.selected')}</p>
          <p>{clientT('myTicketsV2.action.amount', {
            count: selectedTickets.length,
            amount: total
          })}</p>
          <p>{!!totalThreshold && t('myTicketsV2.action.threshold', {threshold: totalThreshold})}
            {' '}<span dangerouslySetInnerHTML={{__html: condition}}/></p>
          {partnerData.partnerName &&
          <p
            dangerouslySetInnerHTML={{__html: clientT('myTicketsV2.action.partner', {partnerName: partnerData.partnerName})}}/>}
        </div>
        <div className="confirmation">
          {clientT('myTicketsV2.step2.confirmation')}
        </div>
        <div className="selected-pan">
          <div className="ticket-steps-wrapper actions">
            <AppButton
              className={"secButton"}
              client
              event="Précédent"
              onClick={() => this.props.nextStep(true)}
            >
              {t('common.previous')}
            </AppButton>
            <AppButton
              className={"mainButton"}
              event="Confirme_oui"
              client
              disabled={!partnerData.partnerId}
              onClick={handleBurn}
            >
              {t('common.understood')}
            </AppButton>
          </div>
          {Context.partner.features.allowUserRefund && <div className="refundBlock">
            <AppLink href={`/refund/ask/${partnerData.partnerCode}/${refundPrizes}?noBurn=true`}
                     dangerouslySetInnerHTML={{__html: clientT('refund.goto')}}
            /></div>}
        </div>
      </div>
    )
  }
}

export default TicketValidation;
