import React from "react";

export default function Card({ imgUrl, name, isActive }) {
  return (
    <div className={`Card ${isActive ? "active" : ""}`}>
      <img src={imgUrl} alt="Card" />
      <span>{name}</span>
    </div>
  );
};
