import React, {Component} from 'react';
import "../style/ticketExchange.scss";
import {getTicketDenomination} from "../tools/toolbox";
import {clientT, t} from "../tools/i18n";
import AppButton from "./AppButton";

export default class TicketExchange extends Component {

  redirectToSummaryPage = () => {
    if (this.props.exchangeData)
      window.location.href = this.props.exchangeData;
  }

  render() {
    const {showTerminationPage, handleBurn, selectedTickets, back} = this.props;

    if (showTerminationPage) {
      return (
        <div className="ticketExchangeWrapper">
          <div className="terminationContainer bg-liteGreen h-full">
            <p className="terminationHeading">Merci !</p>
            <img src="./assets/tcaAssets/09083448/chatFork-1.png"/>
            <p className="terminationTxt">Retrouvez votre carte cadeau {"TheFork"} en cliquant sur le lien ci-
              dessous</p>
            <img onClick={() => {
              this.redirectToSummaryPage()
            }} style={{border: '1px solid #ffffff', marginBottom: '28px', cursor: "pointer"}}
                 src="./assets/tcaAssets/09073743/giftcard-fr-1.png"/>
            <button onClick={() => {
              this.redirectToSummaryPage()
            }} className="terminationBtn text-liteGreen">Terminer
            </button>
          </div>
        </div>
      )
    }

    const total = selectedTickets.reduce((acc, t) => acc + t.value, 0);
    return (
      <>
        <div className="ticketExchangeWrapper">
          <p className="ticketExchangeHeading text-liteGreen">Validez votre choix</p>
          <img src="./assets/tcaAssets/09073743/giftcard-fr-1.png"/>
        </div>
        <div className="selected-pan">
          {!showTerminationPage &&
          <div className={`ticket-steps-wrapper bg-liteGreen`}>
            <div className="step1-actions">
              <div className="ticketsExchangeTextWrapper">
                <p>Vous allez échanger votre {getTicketDenomination()}</p>
                <p>pour une carte cadeau {"TheFork"}</p>
                <p>d’une valeur de {total}€</p>
              </div>

              <div className="step2-actions">
                <AppButton
                  className={"mainButton transButton"}
                  client
                  event="Précédent"
                  onClick={back}
                >
                  {t('common.previous')}
                </AppButton>
                <AppButton
                  className={`mainButton text-liteGreen confirmExchangeBtn`}
                  event="Suivant"
                  client
                  onClick={handleBurn}>{t('common.confirm')}</AppButton>
              </div>
            </div>
          </div>
          }
        </div>
      </>
    )
  }
}
