import React, {Component} from 'react';
import '../../style/ticketConsumer.scss';
import {Context} from "../../tools/context";
import {authClient} from '../../tools/apitools';
import InfoPicto from "../../components/InfoPicto";
import AppLink from '../../components/AppLink';
import {clientT, t} from "../../tools/i18n";
import {track} from "../../tools/tracking";
import {
  getPartnerDenomination,
  getRedirectionLink
} from '../../tools/toolbox';
import AppButton from '../../components/AppButton';
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";
import QrScannerShortcode from "../../components/QrScannerShortcode";

class TicketConsumer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortcode: props?.partnerData?.partnerCode || '',
      infoPopup: false,
      showQRScanner: false,
      showScanQRCode: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, document.body.scrollHeight);
    let {selectedTickets} = this.props;

    if (!Array.isArray(selectedTickets)) return;
    track('viewTicketConsumer', {
      prize: selectedTickets.map(ticket => ticket.prize).join('|'),
      uuid: selectedTickets.map(ticket => ticket.uuid).join('|'),
      shortcode: this.state.shortcode
    });

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth >= 768 && this.state.showScanQRCode)
      this.setState({showScanQRCode: false})
    else if (window.innerWidth < 768 && !this.state.showScanQRCode)
      this.setState({showScanQRCode: true})
  }

  handleInput = async (event) => {
    const value = event.target.value.toUpperCase();
    this.setState({shortcode: value})

    this.props.handleInputShortCode(value);
  }

  proceedScan = (shortcode) => {
    if (!shortcode) return;
    this.props.burnWithShortcode && this.props.burnWithShortcode(shortcode);
  }

  renderScanQrCode = () => {
    const {showScanQRCode} = this.state;
    const {selectedTickets} = this.props;
    if (!selectedTickets || !selectedTickets.length) return null;

    if (!showScanQRCode || !Context.partner?.showScanQRCode)
      return null;

    return (
      <div className="scan-qr-pan">
        <span>{t('common.or')}</span>
        <AppButton
          className={`mainButton`}
          event="Suivant"
          client
          onClick={() => this.setState({showQRScanner: true})}
        >
          {t('common.scanQRCode')}
        </AppButton>
        {this.state.showQRScanner
          ? <QrScannerShortcode
            closeQrPopup={() => this.setState({showQRScanner: false})}
            callback={this.proceedScan}
          />
          : null
        }
      </div>
    )
  }

  renderSelectPan = () => {
    const {selectedTickets, partnerData} = this.props;
    return (
      <div
        className={"selected-pan"}
      >
        {Context.partner?.features?.showTicketExchangeBanner && selectedTickets.length === 1 &&
        <div
          onClick={this.props.gotoExchange}
          className="coupon-exchange-wrapper"
        >
          <div className="left-container">
            <img src="./assets/tcaAssets/08115822/Gift-Cards.png"/>
            <div>
              <p className="font-weight-bold">Aucun partenaire ne correspond à vos critères ?</p>
              <p>Transformez vos chèques en carte cadeau TheFork utilisable dans plus de 5 000
                restaurants</p>
            </div>
          </div>
          <div className="right-container">
            <button>CHOISIR la carte cadeau thefork</button>
            <img src="./assets/tcaAssets/08115821/logo-1.png"/>
          </div>
        </div>
        }
        <div className={`ticket-steps-wrapper tickets-steps-wrapper-consumer`}>
          <div className="step1-actions">
            <div className="step2-actions">
              <AppButton
                className={"mainButton"}
                event="Suivant"
                client
                disabled={!partnerData.partnerId}
                onClick={() => this.props.nextStep()}
              >
                {t('common.next')}
              </AppButton>
              <AppButton
                className={"secButton"}
                client
                event="Précédent"
                onClick={() => this.props.nextStep(true)}
              >
                {t('common.previous')}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {selectedTickets, partnerData} = this.props;
    const total = selectedTickets.reduce((acc, t) => acc + t.value, 0);
    const totalThreshold = selectedTickets.reduce((acc, t) => acc + t.threshold, 0);
    const condition = selectedTickets[0]?.condition || '';
    const {partnerError, infoPopup} = this.state;
    const redirectionLink = getRedirectionLink(selectedTickets)
        ;
    const infoPopupBtn = Context.partner.infoBurnPopup
    return (
      <div className="TicketConsumer">
        <div className="summary">
          <p>{clientT('myTicketsV2.action.selected')}</p>
          <p>{clientT('myTicketsV2.action.amount', {
            count: selectedTickets.length,
            amount: total
          })}</p>
          <p>{!!totalThreshold && t('myTicketsV2.action.threshold', {threshold: totalThreshold})}
            {' '}<span dangerouslySetInnerHTML={{__html: condition}}/></p>
        </div>
        <div className="instruction">
          {!partnerData.partnerName ?
            <div className='title'>{clientT('myTicketsV2.step2.title')}</div>
            :
            <div className="subtitle" dangerouslySetInnerHTML={{__html: clientT('myTicketsV2.step2.subtitle', {partnerName: partnerData.partnerName})}} />
          }
        </div>
          <div className="ticket">
            <input
              maxLength="10"
              type="text"
              value={this.state.shortcode}
              placeholder={clientT('myTicketsV2.step2.placeholder')}
              onChange={(event) => {
                this.handleInput(event)
              }}
              onFocus={(e) => setTimeout(
                () => e.target.scrollIntoView({behavior: 'smooth'}), 500)}
            />
            {infoPopupBtn && <div className="ticketInfoWrapper"
                                  onClick={() => this.setState({infoPopup: true})}
            >
              <InfoPicto height='13px' width='13px' style={{marginRight: '2px'}}/>
              <span>{t('refund.confirmation.moreInfo')} </span>
            </div>}
          </div>
          {partnerError && <div className="ticket-detail">
            <p>{partnerError}</p>
          </div>}
          {this.renderScanQrCode()}
          {redirectionLink && <div style={{marginTop: '40px', textAlign: 'center'}}>
            <AppLink
              client
              className="ticket-redirection"
              target="_blank"
              href={redirectionLink}>{t('myTicketsV2.step2.redirection')}
            </AppLink>
          </div>}
        {infoPopup &&
        <TicketsInfoPopup
          bottomButtons={[
            {label: t('common.understood'), action: () => this.setState({infoPopup: false})},
            Context.partner?.infoBurnPopup?.linkButton ? {
              label: t('infoBurnPopup.partner'), action: () => {
                if (!selectedTickets.length) return;
                window.location.href = getRedirectionLink(selectedTickets);
              }
            } : {},
          ]}
          type="custom"
          title={t('infoBurnPopup.title', {
            title1: t('infoBurnPopup.primaryTitle'),
            text1: t('infoBurnPopup.primaryText'),
            title2: t('infoBurnPopup.secondaryTitle'),
            text2: t('infoBurnPopup.secondaryText'),
            boldText: t('infoBurnPopup.boldText'),
            innerMarginText: t('infoBurnPopup.innerMarginText')
          })}

          richContent
        />
        }
        {this.renderSelectPan()}
      </div>
    )
  }
}

export default TicketConsumer;
