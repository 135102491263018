import Context from './context';
import {categories, categoryList, departments, technologies, epcis, parkingList, parkings} from '../Utils/Constants';
import {t} from './i18n'

const fields = () => ({
  name: {
    label: "Nom de l'enseigne commerciale",
  },
  legalname: {
    label: 'Raison sociale',
  },
  registrationNumber: {
    label: 'Numéro de SIRET',
    pattern: '\\d{14}',
    errorMsgKey: 'registrationNumberErrorMsg',
    customError: 'Le SIRET doit contenir 14 chiffres',
    optional: Context.partner.registrationNumberOptional ? true : false
  },
  address: {
    label: 'Adresse',
  },
  zipcode: {
    label: 'Code Postal',
    pattern: '\\d{5}',
    defaultLabel: '-- Sélectionner un code postal --',
    errorMsgKey: 'ZipCodeError',
    type:
      Context.partner?.zipcode?.length ? 'select' : 'text',
    values: Context.partner.zipcode,
    // errorMsg: this.state.showZipCodeErrorMessage ? this.errorMessages.zipcodeErrorMessage : null,
  },
  city: {
    label: 'Ville',
    type:
      Context.partner.city && Context.partner.city.length ? 'select' : 'text',
    defaultLabel: '--Choisir une ville--',
    values: Context.partner.city,
  },
  tag: {
    label: 'Tag',
    type:
      Context.partner.tag && Context.partner.tag.length ? 'select' : 'text',
    defaultLabel: '--Choisir une valeur--',
    values: Context.partner.tag,
  },
  country: {
    label: 'Pays',
    type: Context.partner?.clientProfile?.countryList?.length? 'select' :'text',
    defaultLabel: '-- Sélectionner un pays --',
    values: Context.partner?.clientProfile?.countryList || null
  },
  description: {
    label: 'Description de votre établissement',
    type: 'textArea',
    // defaultLabel: '--Choisir une description--',
    defaultLabel: t('formLabels.description'),
    values: Context.partner.description,
    rows: 4,
    optional: true
  },
  departments: {
    label: 'Département',
    type: 'select',
    // defaultLabel: '--Choisir un département--',
    defaultLabel: t('formLabels.department'),
    values: departments,
  },
  category: {
    label: 'Catégories',
    type: getCategoriesType(),
    // defaultLabel: '--Choisir une catégorie--',
    defaultLabel: t('formLabels.category'),
    values: getCategories(),
  },
  parking: {
    label: 'Parking le plus proche',
    type: getParkingsType(),
    // '--Choisir le parking le plus proche--',
    defaultLabel: t('formLabels.parking'),
    values: getParkings(),
    optional: true
  },
  website: {
    label: 'Site internet',
    type: 'text',
    url: "https:\/\/www.example.com",
    optional:true
  },
  tech: {
    label:"Technologie utilisée",
    type: 'select',
    defaultLabel: '--Choisir une technologie--',
    values:technologies,
    optional:true
  },
  epci_name: {
    label:"Communauté de communes",
    type: 'select',
    defaultLabel: '--Choisir une communauté de communes--',
    values: Context.partner?.clientProfile?.epcis || epcis
  },
  pass_department: {
    label: 'Pass Département',
    type: 'select',
    defaultLabel: '--De quel département suis-je saisonnier ?--',
    values: [
        {value: "33", label: "Girondes (33)"},
        {value: "40", label: "Landes (40)"},
        {value: "47", label: "Lot-et-Garonne (47)"},
    ]
  },
  pass_activity: {
    label: 'Pass Activité',
    type: 'select',
    defaultLabel: '--Dans quel secteur d’activité suis-je saisonnier ?--',
    values: [
      {value: "restauration", label: "Restauration"},
      {value: "hebergement_touristique", label: "Hébergement touristique"},
      {value: "site_de_visites", label: "Site de visites"},
      {value: "activite_touristique_loisirs", label: "Activité Touristique & Loisirs"},
      {value: "autre", label: "Autre"},
    ]
  },
  pass_interest: {
    label: 'Pass Département',
    type: 'select',
    defaultLabel: '--Comment ai-je connu le Pass Saisonnier ?--',
    values: [
      {value: "employeur", label: "Mon employeur"},
      {value: "bouche_ao", label: "Bouche à oreille"},
      {value: "reseaux_sociaux", label: "Réseaux sociaux"},
      {value: "site_web", label: "Site web"},
      {value: "autre", label: "Autre"},
    ]
  },
  naf: {
    label: 'Code NAF',
    type: 'text',
    placeholder: '4540Z',
    errorMsgKey: 'codeNAFErrorMsg'
  },
  contactPhone: {
    label: 'Téléphone de l\'établissement',
    type: 'phone',
    placeholder: '06XXXXXXXX',
    errorMsgKey: 'contactPhoneError',
  },
  ownerPhone: {
    label: 'Téléphone mobile de contact',
    subLabel: '',
    type: 'phone',
    errorMsgKey: 'ownerPhoneError',
  },
  loginEmail: {
    label: 'Email de connexion',
    pattern: '^[^@\\s]+@[^@\\s\\.]+\\.[^@\\.\\s]+(\\.[a-z]+)*$',
    errorMsgKey: 'EmailError',
    customError: "Veuillez renseigner une adresse email valide.",
    disabled: true,
  },
  contactEmail: {
    label: 'Email de contact de l\'établissement',
    pattern: '^[^@\\s]+@[^@\\s\\.]+\\.[^@\\.\\s]+(\\.[a-z]+)*$',
    errorMsgKey: 'EmailError',
    customError: "Veuillez renseigner une adresse email valide.",
    disabled: true,
  },
  password1: {
    label: 'Mot de passe',
    type: 'password',
    pattern: '.{8,}',
    errorMsgKey: 'PasswordError',
    customError: 'Votre mot de passe doit contenir au moins 8 caractères',
    // errorMsg: this.state.showPasswordErrorMessage ? this.errorMessages.passwordErrorMessage : null,
  },
  password2: {
    label: 'Confirmez votre mot de passe',
    type: 'password',
    pattern: '.{8,}',
    customError: 'Votre mot de passe doit contenir au moins 8 caractères',
    errorMsgKey: 'PasswordError',
    // errorMsg: this.state.showPasswordErrorMessage ? this.errorMessages.passwordErrorMessage : null,
  },
  iban: {
    label: 'IBAN',
    subLabel: 'IMPORTANT: Vous recevrez les remboursements sur ce compte',
    pattern: '[a-zA-Z]{2}\\s?\\d{2}\\s?\\d{4}\\s?\\d{4}\\s?\\S{4}\\s?\\S{4}\\s?\\S{4}\\s?\\S{2,}',
    customError: 'L\'IBAN doit être sous le format FR12 1234 1234 1234 12A4 123412',
    optional: Context.partner.ibanOptional ? true : false
  },
  bic: {
    label: 'BIC',
    pattern: '\\S{8,11}',
  },
  title: {
    label: 'Civilité',
    type: 'select',
    defaultLabel: '--Civilité--',
    values: [
      {value: "Monsieur", label: "Monsieur"},
      {value: "Madame", label: "Madame"},
    ],
  },
  firstname: {
    label: 'Prénom',
  },
  lastname: {
    label: 'Nom',
  },
  gender: {
    label: 'Genre',
    type: 'select',
    defaultLabel: '--Genre--',
    values: [
      {value: "male", label: "Homme"},
      {value: "female", label: "Femme"},
      {value: "none", label: "Autre"},
    ],
  },

  signatureTitle: {
    label: 'Civilité',
    type: 'select',
    defaultLabel: '--Civilité--',
    values: [
      {value: "Monsieur", label: "Monsieur"},
      {value: "Madame", label: "Madame"},
    ],
  },
  customTextField1:{
    label:'custom'
  },
  signatureFirstname: {
    label: 'Prénom',
  },
  signatureLastname: {
    label: 'Nom',
  },
  fonction:{
    label:'Fonction',
  },
  birthdate: {
    label: 'Date de naissance',
    type: 'date',
    pattern: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
    errorMsgKey: 'BirthdateError',
  },
  image: {
    label: 'Image 1',
    type: 'importImage',
    optional: true,
  },
  image2: {
    label: 'Image 2',
    type: 'importImage',
    optional: true,
  },
  image3: {
    label: 'Image 3',
    type: 'importImage',
    optional: true,
  },
  imagepartner: {
    label: 'Importer par plateforme',
    type: 'importImage',
    optional: true,
  },
  userdocument: {
    label: 'Importer un document',
    type: 'import',
    optional: true,
    maxSize: 10000000
  },
  productName: {
    label: 'Nom de la prestation',
    type: 'text',
    placeholder: 'Une entrée offerte'
  },
  productPrice: {
    label: 'Tarif par personne',
    type: 'text',
    placeholder: '15€'
  },
  productDate: {
    label: 'Date de la prestation',
    type: 'date-range',
  },
  residenceType: {
    label: 'Type de résidence',
    type: 'select',
    defaultLabel: '--Type de résidence--',
    values: [
      {value: "Principale", label: "Principale"},
      {value: "Secondaire", label: "Secondaire"},
    ],
  },
});

const fieldBase = {
  // Mon établissement
  'myCompany': {
    name: true,
    legalname: true,
    contactPhone: true,
    contactEmail: true,
    registrationNumber: true,
    address: true,
    zipcode: true,
    city: true,
    epci_name: false,
    tag: false,
    website: true,
    tech: true,
    parking: false,
  },
  // Département de l'établissement
  "companyDepartment": {
    departments: false,
  },
  // Prestation
  "prestation": {
    productName: true,
    description: true,
    productPrice: true,
    productDate: true,
  },
  // Catégorie de l'établissement
  "companyCategory": {
    category: true,
    naf: true,
  },
  // Images de l'établissement (560 × 360 px)
  "companyPictures": {
    image: false,
    image2: false,
    image3: false,
  },
  IBAN  : {
    iban: false,
  },
  BIC: {
    bic: false,
  },
  // Responsable de l'établissement
  "companyOwner": {
    title: false,
    firstname: false,
    lastname: false,
    ownerPhone: true,
  },
  "Signataire de la convention": {
    signatureTitle: false,
    signatureFirstname: false,
    signatureLastname: false,
    fonction:false
  },
  // Mes identifiants de connexion
  'credentials': {
    loginEmail: true,
    password1: true,
    password2: true,
  },
};

const adminBase = {
  'Mon établissement': {
    name: true,
    legalname: true,
    contactPhone: true,
    contactEmail: true,
    registrationNumber: true,
    address: true,
    zipcode: true,
    city: true,
    departments: true,
    country: true,
    epci_name: true,
    naf: true,
    tag: true,
    website: true,
    tech: true,
    parking: true,
    image: true,
    image2: true,
    image3: true,
  },
  'Partenaire': {
    imagepartner: true,
  },
  "User document": {
    userdocument: true,
  },
  "Prestation": {
    productName: true,
    description: true,
    productPrice: true,
    productDate: true,
  },
  "Catégorie de l'établissement": {
    category: true,
    naf: true,
  },
  IBAN  : {
    iban: true,
  },
  BIC: {
    bic: true,
  },
  "Responsable de l'établissement": {
    title: true,
    firstname: true,
    lastname: true,
    ownerPhone: true,
  },
  "Signataire de la convention": {
    signatureTitle: true,
    signatureFirstname: true,
    signatureLastname: true,
    fonction: true,
    birthdate: true,
  },
  'Identifiants de connexion': {
    loginEmail: true,
    password1: true,
    password2: true,
  },
};

const formatters = {
  uppercase: (input) => input.toUpperCase ? input.toUpperCase() : input,
  lowercase: (input) => input.toLowerCase ? input.toLowerCase() : input,
  capitalize: (input) => input && input.toUpperCase ? `${input[0].toUpperCase()}${input.slice(1).toLowerCase()}` : input,
}

const userProfileFieldBase = {
  Informations: {
    title: false,
    firstname: true,
    lastname: true,
    gender: false,
    birthdate: true,
    contactEmail: true,
    customTextField1: false,
    address: false,
    zipcode: true,
    city: false,
    country: true,
    residenceType: false,
    userdocument: false,
    epci_name: false,
    pass_department: false,
    pass_activity: false,
    pass_interest: false,
  },
};

const formatFields = (scheme, field, translationKey = '') => {
  const base = scheme || fieldBase;
  let output = {};

  const gotSub = Context.partner.register && Context.partner.register.subLabels;
  const sub = Context.partner.register?.subLabels;

  const override = (Context.partner[field] && Context.partner[field].updateFields) || {};

  Object.keys(base).forEach((g) => {
    const group = base[g];
    if (!Object.keys(group).filter((k) => group[k] && fields()[k]).length)
      return;

    output[g] = Object.keys(group)
      .filter((k) => group[k] && fields()[k])
      .map((f) => ({
        ...fields()[f],
          id: f,
          label: t(`form.${translationKey ? `${translationKey}.` : ''}${f}`, { fallback: fields()[f].label }),
          placeholder: t(`form.${translationKey ? `${translationKey}.` : ''}${f}Placeholder`, { fallback: fields()[f].placeholder }),
          subLabel: gotSub ? sub[f] : fields()[f].subLabel,
          ...override[f]
        }
      ));
  });

  if (Object.keys(output).length === 1) {
    output = { oneGroup: Object.values(output)[0] };
  }

  return output;
};

const formatFieldToArray = (scheme, required, optional) => {
  const base = scheme || fieldBase;
  let output = [];

  Object.keys(base).forEach((g) => {
    const group = base[g];

    output = output.concat(
      Object.keys(group).filter(
        (k) => group[k] && fields()[k]
          && ((!fields()[k].optional || (required || []).includes(k)) && !(optional || []).includes(k))
      )
    );
  });

  return output;
};

const setFields = (scheme, list) => {
  Object.keys(scheme).forEach((g) => {
    const group = scheme[g];
    Object.keys(group).forEach((f) => {
      group[f] = list.includes(f);
    });
  });

  return scheme;
};

const updateFieldsList = (scheme, list, set) => {
  list.forEach((k) => {
    Object.keys(scheme).forEach((g) => {
      const group = scheme[g];
      if (Object.keys(group).includes(k)) {
        group[k] = set;
      }
    });
  });

  return scheme;
};

const updateFields = (scheme, list, set) => {
  list.forEach((k) => {
    Object.keys(scheme).forEach((g) => {
      const group = scheme[g];
      const field = group.find(f => f.id === k);
      if (field) {field.optional = !set;}
    });
  });

  return scheme;
};

const getFields = (
  field,
  base = window.location.pathname?.toLowerCase().includes("adminpage")
    ? adminBase
    : fieldBase,
  translationKey = '',
) => {
  if (
    !Context.partner[field] ||
    (!Context.partner[field].addFields &&
      !Context.partner[field].removeFields &&
      !Context.partner[field].requiredFields &&
      !Context.partner[field].optionalFields
    )
  )
    return formatFields(base, field, translationKey); //context partner fields

  let scheme = JSON.parse(JSON.stringify(base)); // lazy clone
  scheme = updateFieldsList(scheme, Context.partner[field].addFields || [], true);
  scheme = updateFieldsList(
    scheme,
    Context.partner[field].removeFields || [],
    false
  );

  let fieldList = formatFields(scheme, field, translationKey);
  fieldList = updateFields(
    fieldList,
    Context.partner[field].requiredFields || [],
    true
  );
  fieldList = updateFields(
    fieldList,
    Context.partner[field].optionalFields || [],
    false
  );

  return fieldList
};

const getFlatFieldKeys = (field) => {
  if (
    !Context.partner[field] ||
    (!Context.partner[field].addFields &&
      !Context.partner[field].removeFields &&
      !Context.partner[field].requiredFields &&
      !Context.partner[field].optionalFields
    )
  )
    return formatFieldToArray(); //context partner fields

  let scheme = JSON.parse(JSON.stringify(fieldBase)); // lazy clone
  scheme = updateFieldsList(scheme, Context.partner[field].addFields || [], true);
  scheme = updateFieldsList(
    scheme,
    Context.partner[field].removeFields || [],
    false
  );

  return formatFieldToArray(scheme, Context.partner[field].requiredFields, Context.partner[field].optionalFields);
};

const getCompletionFields = () => {
  if (!Context.partner.profile || !Context.partner.profile.requiredFields)
    return {}; //context partner fields

  let scheme = JSON.parse(JSON.stringify(fieldBase)); // lazy clone
  scheme = setFields(scheme, Context.partner.profile.requiredFields, true);

  return formatFields(scheme);
};

const partnerName = () => {
  switch (Context.partner.sponsor) {
    case 'stbrieucarmoragglo':
      return ' et Saint Brieuc Armor Agglomération';
    case 'lavalagglo':
      return ' et Laval Agglomération';
    default:
      return '';
  }
};

const getCheckboxes = (profile) => {
  const list =
    Context.partner.optins && Context.partner.optins.length
      ? Context.partner.optins
      : [
          {
            id: 'eligibleTypeOfShop',
            label: `Afin de profiter du service Ticket Commercant, je certifie que
            mon entreprise répond aux critères suivants*: - Commerce de
            détail hors GMS (hypermarché, supermarché, supérette),
            Bar-tabac-presse-jeux, Pharmacie, Banque, Assurance, Agence
            immobilière, Auto-école, Garage, Vente hors magasin (marché,
            porte à porte, vente via des automates).`,
            optional: false,
          },
          {
            id: Context?.partner?.sponsor
              ? `${Context.partner.sponsor}_optInMarketing`
              : `optInMarketing`,
            label: t('optInMarketing', {partnerName: partnerName()}),
            optional: true,
          },
          {
            id: 'optInTermsAndConditions',
            label: `J'ai lu et j'accepte les <a target=\"_blank\" href="https://ticket-commercant.fr/cgu/">
              conditions générales d'utilisation.*</a>`,
            optional: false,
          },
        ];

  return list;
};

const getCategoriesType = () => {
  if (Context?.partner?.filteredCategories) return 'filteredSelect';

  return "select"
}

const getCategories = () => {
  if (Context?.partner?.filteredCategories) {
    const output = {};
    for (let lab in Context.partner.filteredCategories) {
      output[lab] = setCategoriesLabel(Context.partner.filteredCategories[lab]);
    }

    return output;
  }

  if (!Context.partner.categories?.length) {
    return categories.map((c) => ({ value: c, label: categoryList[c] }));
  }

  return setCategoriesLabel(Context.partner.categories);
};

const setCategoriesLabel = (input) => {
  const output = [];
  input.forEach((c) => {
    if (c.value) {
      const line = categoryList[c.value];
      output.push({ value: c.value, label: line ? c.label : '' });
    } else {
      const line = categoryList[c];
      output.push({ value: c, label: line });
    }
  });

  return output;
}

const getCategoryKeys = () => {
  if (Context?.partner?.filteredCategories) {
    const output = {};
    for (let lab in Context.partner.filteredCategories) {
      Context.partner.filteredCategories[lab].forEach(c => {
        if(c.value) {
          output[c.value] = c.label;
        }
      });
    }

    return output;
  }

  if (!Context.partner.categories?.length) {
    return categoryList;
  }

  const output = {...categoryList};
  Context.partner.categories.forEach(c => {
    if(c.value) {
      output[c.value] = c.label;
    }
  });

  return output;
};

const getParkingsType = () => {
  if (Context?.partner?.filteredParkings) return 'filteredSelect';

  return "select"
}

const getParkings = () => {
  if (Context?.partner?.filteredParkings) {
    const output = {};
    for (let lab in Context.partner.filteredParkings) {
      output[lab] = setParkingsLabel(Context.partner.filteredParkings[lab]);
    }

    return output;
  }

  if (!Context.partner.parkings?.length) {
    return parkings.map((c) => ({ value: c, label: parkingList[c] }));
  }

  return setParkingsLabel(Context.partner.parkings);
};

const setParkingsLabel = (input) => {
  const output = [];
  input.forEach((c) => {
    if (c.value) {
      const line = c.label || parkingList[c.value];
      output.push({ value: c.value, label: line ? c.label : '' });
    } else {
      const line = parkingList[c];
      output.push({ value: c, label: line });
    }
  });

  return output;
}

const getParkingsKeys = () => {
  if (Context?.partner?.filteredParkings) {
    const output = {};
    for (let lab in Context.partner.filteredParkings) {
      Context.partner.filteredParkings[lab].forEach(c => {
        if(c.value) {
          output[c.value] = c.label;
        }
      });
    }

    return output;
  }

  if (!Context.partner.parkings?.length) {
    return parkingList;
  }

  const output = {...parkingList};
  Context.partner.parkings.forEach(c => {
    if(c.value) {
      output[c.value] = c.label;
    }
  });

  return output;
}

export {
  fields,
  getFields,
  getCheckboxes,
  getCompletionFields,
  getFlatFieldKeys,
  getCategories,
  getCategoryKeys,
  getParkings,
  getParkingsKeys,
  partnerName,
  fieldBase,
  userProfileFieldBase,
  formatters,
};
