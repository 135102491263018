import React, {Component} from 'react';
import QrReader from 'react-qr-reader';
import '../style/qrScannerShortcode.scss';

export default class QrScannerShortcode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qrOpen: true,
      errorMessage: null,
    }
  }

  extractShortcodeFromData = (data) => {
    const regex = /\/transactions\/([a-zA-Z0-9]{6})/;
    return data.match(regex)?.[1];
  }

  handleScan = data => {
    console.log("Message " + data);
    if (!data)
      return;

    const shortcode = this.extractShortcodeFromData(data);

    if (!shortcode) {
      this.setState({errorMessage: "QR code invalide"});
      return;
    }

    this.setState({
      errorMessage: null
    }, () => this.props.callback(shortcode));
  }

  handleError = err => {
    console.error(err)
    this.setState({errorMessage: "Impossible de scanner le QR code"});
  }

  render() {
    const {errorMessage} = this.state;
    const {closeQrPopup} = this.props;

    return (
      <div className="QrScannerShortcode">
        <div className="qrScannerShortcode__header">
          <h3>Scanner le QR code</h3>
          <span className="icon icon-close-grey" onClick={closeQrPopup} />
        </div>
        <div id="qrScannerShortcode" className="qrScannerShortcode__scanner">
            <QrReader
              id="qrReader"
              className="qrScannerShortcode__scanner__qrReader"
              delay={100}
              facingMode="environment"
              onError={this.handleError}
              onScan={this.handleScan}
            />
          </div>
        <div className="qrScannerShortcode__feedback">
          {errorMessage
            ? <div className="qrScannerShortcode__feedback__error">
              {errorMessage}
            </div>
            : <h3>En attente du scan<span>.</span><span>.</span><span>.</span></h3>
          }
        </div>
      </div>
    )
  }
}
