import React from "react";
import '../../style/course/header.scss';
import {Context} from "../../tools/context";

export default function CourseHeader() {
  const headerConfig = Context.partner.course?.header;

  if (!headerConfig) return null;

  return (
    <div className="CourseHeader">
      <div dangerouslySetInnerHTML={{__html: headerConfig.innerHtml}}/>
    </div>
  );
};
