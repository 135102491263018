import * as React from "react";
import '../style/edito.scss';

function Edito({innerHtml}) {
  return (
    <div className="Edito">
      <div
        dangerouslySetInnerHTML={{__html: innerHtml}}
      />
    </div>
  );
}

export default Edito;
