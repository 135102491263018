import {clientT} from "../../../../tools/i18n";

export default function Balance({ isActive, balance, maxBalance }) {
  const labelKey = balance === 0
    ? "empty"
    : balance === maxBalance
      ? isActive
        ? "full"
        : "inactive"
      : "partial";

  return (
    <div
      className={`Balance ${labelKey}`}
      dangerouslySetInnerHTML={{
        __html: clientT(`myTicketsV2.PaymentCard.balance.${labelKey}`, {
          balance,
          maxBalance
        })
      }}
    />
  );
};
