import React, { Component } from 'react';
import ImportField from "./ImportField";

const ImportImageField = (props) => (
  <ImportField
    {...props}
    accept={"image/jpeg,.jpg,.jpeg,image/gif,.gif,image/png,.png,image/bmp,.bmp"}
    showPreview
  />
);

export default ImportImageField;