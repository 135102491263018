import {MyPromos} from "./MyPromos";
import {MyTickets} from "./MyTickets";
import {MyTicketsUsed} from "./MyTicketsUsed";
import {Profile} from "./Profile";
import {Refund} from "./Refund";
import {TicketActivation} from "./TicketActivation";
import {Disconnect} from "./Disconnect";
import {Help} from "./Help";
import {Cross} from "./Cross";
import {Hourglass} from "./Hourglass";
import {Play} from "./Play";
import Icon from "../Icon";

const pictos = {
  MyPromos,
  MyTickets,
  MyTicketsUsed,
  Profile,
  Refund,
  TicketActivation,
  Disconnect,
  Help,
  Cross,
  Hourglass,
  Play
}

export const Picto = (props) => {
  const PictoComponent = pictos[props.iconKey];
  if (!PictoComponent) return <Icon {...props} name={props.iconKey} />;

  return <PictoComponent {...props} />
};