import React, {Component, Fragment} from "react";
import {Dropdown} from "semantic-ui-react";
import {offersData, discountData, thresholdData} from "../../Utils/Constants";
import ErrorPopup from "../../components/Popup/ErrorPopup";
import {authCall} from "../../tools/apitools";
import Context from "../../tools/context";
import AppButton from "../../components/AppButton";
import {clientT} from "../../tools/i18n";
import DateRangePicker from "../../components/DateRangePicker";
import DatePicker from "react-datepicker";
import i18n from "i18next";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

export default class AddProductHome extends Component {
  constructor(props) {
    super(props);
    console.log({props})
    this.state = {
      isErrorPopup: false,
      product: {
        description: null,
        discountType: 'VALUE',
        value: '',
        threshold: '',
        thresholdType: 'BASKET',
        id: null,
        campaignId: null,
        giftData: null,
        offerType: 'VALUE',
        ...props.initialData
      }
    };

    if (this.state.product.expirationDate) {
      this.state.product.expirationDate = new Date(this.state.product.expirationDate);
    }

    if (this.state.product.description === this.generateDescription()) {
      this.state.product.description = '';
    }

    if (props.initialData.giftData) {
      const data = props.initialData.giftData.find(d => d.key === 'conditions');
      if (data) {
        this.state.conditions = data.value;
      }
    }
  }

  generateDescription = () => {
    const {
      discountType,
      threshold,
      thresholdType,
      value,
    } = this.state.product;
    let typeLabel = '';
    let thresholdLabel = ''

    if (discountType === 'VALUE')
      typeLabel = '€ de remise';
    else if (discountType === 'PERCENT')
      typeLabel = '% de remise';

    switch (thresholdType) {
      case 'BASKET':
        thresholdLabel = `dès ${threshold}€ d'achat`;
        break;
      case 'ORDERS':
        thresholdLabel = `tous les ${threshold} achats`;
        break;
      default:
        thresholdLabel = ``;
        break;
    }
    return `${value} ${typeLabel} ${thresholdLabel}`;
  }

  handleSubmit = (e) => {
    if (this.isDataInvalid()) return;

    const state = {
      ...this.state.product,
      description: this.state.product.description || this.generateDescription(),
    }

    console.log(state)
    this.props.submit(state, {conditions: this.state.conditions});
  };

  isDataInvalid = () => {
    const {
      value,
      threshold,
      offerType,
      description,
    } = this.state.product;

    if (offerType === 'GWP') {
      return !description;
    }

    if (!value || !threshold) return true;
    if (isNaN(value) || isNaN(threshold)) return true;

    return false;
  }

  setProductState = (input) => {
    this.setState(p => ({
      product: {
        ...p.product,
        ...input,
      }
    }))
  }

  render() {
    const {
      description,
      discountType,
      value,
      threshold,
      thresholdType,
      id,
      campaignId,
      giftData,
      offerType,
      expirationDate,
    } = this.state.product;

    const {
      isErrorPopup,
      showCondition,
      showExpiration,
      conditions,
    } = this.state;

    const isService = offerType === 'GWP';
    const thresholdTypeValues = {
      BASKET: {of: 'De ', unit: '€', for: 'dès'},
      ORDERS: {of: 'De ', unit: 'achat(s)' , for: 'tous les'},
    }
    return (
      <div className="page productBox">
        <p className="titleHeader mt-7">
          {clientT(id == null ? 'addProductHome.createPlanTitle' : 'addProductHome.updatePlanTitle')}
        </p>
        <p className="titleChild subHeading">
          {clientT('addProductHome.titles.1')}
          <span className="star pl-1">*</span>
        </p>
        <div className="form-group form-inline nopad mt-16">
          <label>J’offre</label>
          <div className="custom-select">
            <Dropdown
              onChange={(e, {value}) => {
                this.setProductState({offerType: value, description: null});
              }}
              options={offersData}
              placeholder="Sélectionner"
              selection
              value={offerType}
            />
          </div>
        </div>
        {!isService && (
          <Fragment>
            <div
              className="form-group form-inline nopad mt-16"
              id="discountDiv"
            >
              <label>{isService ? 'd’une valeur de' : 'de'}</label>
              <input
                id="discountAmount"
                type="number"
                className={`customInputField ${
                  !value && "validate-error"
                }`}
                placeholder="00"
                value={value}
                onChange={(e) => {
                  this.setProductState({value: e.target.value});
                }}
              />
              {isService ? <label style={{marginLeft: '20px'}}>€</label> :
                <div className="custom-select discountSelection">
                  <Dropdown
                    onChange={(e, {value}) => {
                      this.setProductState({discountType: value});
                    }}
                    options={discountData}
                    placeholder="Sélectionner"
                    selection
                    value={discountType}
                  />
                </div>
              }
            </div>
            {(discountType || isService) && (
              <div
                className="form-group form-inline nopad mt-23"
                id="thresholdDiv"
              >
                <label>{thresholdTypeValues[thresholdType].for}</label>
                <input
                  id="thresholdValue"
                  type="number"
                  className={`customInputField ${
                    !threshold && "validate-error"
                  }`}
                  placeholder="000"
                  value={threshold}
                  onChange={(e) =>
                    this.setProductState({threshold: e.target.value})
                  }
                  style={{marginLeft: "38px"}}
                />
                <div className="custom-select" style={{marginLeft: "23px"}}>
                  <Dropdown
                    onChange={(e, {value}) => {
                      this.setProductState({thresholdType: value});
                    }}
                    options={thresholdData}
                    placeholder="Sélectionner"
                    selection
                    value={thresholdType}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}
        <div
          className="form-group form-inline nopad mt-16"
          style={{flexDirection: 'column', alignItems: 'flex-start'}}
          id="discountDiv"
        >
          <label>Description</label>
          <div className="descriptionBlock">
            <input
              id="productDescription"
              type="text"
              className={`customInputField large ${
                (isService ? !description : false) && "validate-error"
              }`}
              placeholder="Description du service / produit offert"
              value={isService
                ? description || ""
                : (description || this.generateDescription())
              }
              onChange={(e) => {
                this.setProductState({description: e.target.value});
              }}
            />
            <AppButton
              type="button"
              className="btn btn-primary"
              onClick={() => this.setProductState({description: null})}
              disabled={!description}
            >
              <span>{clientT('addProduct.resetDescription')}</span>
            </AppButton>
          </div>
        </div>
        <div
          className="form-group form-inline nopad mt-16"
          style={{flexDirection: 'column', alignItems: 'flex-start'}}
          id="discountDiv"
        >
          <AppButton
            style={{border: 'none', background: 'none'}}
            onClick={() => this.setState(p => ({showCondition: !p.showCondition}))}
          >
            <label>conditions <span className="caret"/></label>
          </AppButton>
          {showCondition &&
          <input
            id="productDescription"
            type="text"
            className={`customInputField large`}
            placeholder="conditions d'accès au service / produit offert"
            value={conditions}
            onChange={(e) => {
              this.setState({conditions: e.target.value});
            }}
          />
          }
        </div>
        <div
          className="form-group form-inline nopad mt-16"
          style={{flexDirection: 'column', alignItems: 'flex-start'}}
          id="discountDiv"
        >
          <AppButton
            style={{border: 'none', background: 'none'}}
            onClick={() => this.setState(p => ({showExpiration: !p.showExpiration}))}
          >
            <label>Date d'expiration <span className="caret"/></label>
          </AppButton>
          {showExpiration &&
          <DatePicker
            selected={expirationDate}
            onChange={(e) => this.setProductState({expirationDate: e})}
            dateFormat={"dd/MM/yyyy"}
            className="date-picker customInputField initial"
            placeholderText={"Date d'expiration"}
            locale={i18n.language === 'fr' ? fr : en}
          />
          }
        </div>

        <div className="sticky-bottom">
          <AppButton
            type="button"
            className="btn btn-primary"
            onClick={this.handleSubmit}
            disabled={this.isDataInvalid()}
          >
            <span className="icon start icon-md icon-save"/>
            <span>{clientT('addProductHome.saveButton')}</span>
          </AppButton>
        </div>
        {isErrorPopup && (
          <ErrorPopup error={isErrorPopup}/>
        )}
      </div>
    );
  }
}
