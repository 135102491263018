import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Context from "./context";
import {
  getCookie,
  getPartnerDenomination,
  getPartnerKey,
  getQueryString,
  getTicketDenomination,
  getGiftCardDenomination,
  siteContext
} from "./toolbox";
import {partnerName} from "./formFieldsDatabase";
import {profile} from "../setupConf";
import {envConfig} from "../Utils/Constants";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: 'fr',
    debug: false,
    returnObjects: true,
    interpolation: {escapeValue: false},
    backend: {
      // for all available options read the backend's repository readme file
      // loadPath: '/locales/{{lng}}/{{ns}}.json'
      // loadPath: (lngs, namespaces) => {
      //   return `/locales/${lngs.map(l => l.split('-')[0])}/${namespaces}.json`;
      // }
      loadPath: (lngs, namespaces) => {
        const {endpoint} = envConfig[getQueryString("env")||profile];
        return `${endpoint}/api/jsonConfiguration/${namespaces}/${lngs.map(l => l.split('-')[0])}`;
      }
    }
  }).then(() => {
    // const partner = getPartnerKey();
    if(Context.partner.customerId) {
      i18n.loadNamespaces(''+Context.partner.customerId);
    }
})

const loadNamespace = (name) => {
    return new Promise((resolve, reject) => {
        i18n.loadNamespaces(name, (error, t) => {
            if (error) {
                reject(error); // Reject the promise if there's an error
            } else {
                resolve(t); // Resolve with the translation function or some relevant data
            }
        });
    });
}


const t = (key, option = {}) =>
  i18n.t([key, ''], {ns: [''+Context.partner.customerId], ...option})
  || i18n.t([key, (option.fallback !== undefined ? option.fallback : key)],
  {ns: ['translation'], ...option})

const clientT = (key, option) =>
  t(key, {
    ticket: getTicketDenomination(),
    tickets: getTicketDenomination(true),
    promo: t('common.promo'),
    promos: t('common.promos'),
    partner: getPartnerDenomination(),
    partners: getPartnerDenomination(true),
    giftcard: getGiftCardDenomination(),
    giftcards: getGiftCardDenomination(true),
    partnerName: t('common.partnerName', {fallback: ''}) || partnerName(),
    platform: Context.partner?.partnerPlatform || t('common.platform'),
    ...option})


export default i18n;
export {loadNamespace, t, clientT};