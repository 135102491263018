import React, {Component} from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import '../style/TicketsPage.scss';
import Context from "../tools/context";
import {clientT, t} from "../tools/i18n";
import AppLink from './AppLink';
import AppButton from './AppButton';
import {track} from "../tools/tracking";
import Feedback from "../pages/Client/feedback";
import {Hourglass} from "./Picto/Hourglass";

class ConfirmedTicket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usedTime: 0,
    }
  }

  componentDidMount() {
    const {selectedTickets, sellerShortCode} = this.props;

    if (!Array.isArray(selectedTickets)) return;
    track('viewTicketConsumer', {
      prize: selectedTickets.map(ticket => ticket.prize).join('|'),
      uuid: selectedTickets.map(ticket => ticket.uuid).join('|'),
      shortcode: sellerShortCode
    });

      setInterval(() => {
        this.setState(p => ({usedTime: p.usedTime+1}))
      }, 1000)
  }

  renderDetails = () => {
    const {selectedTickets, sellerName} = this.props;
    const total = selectedTickets.reduce((acc, t) => acc + t.value, 0);
    const totalThreshold = selectedTickets.reduce((acc, t) => acc + t.threshold, 0);
    const codes = selectedTickets.map(t => t.prize).join(', ');
    const condition = selectedTickets[0].condition || '';
    let fields = total
      ? [{name: clientT('myTicketsV2.step3.amount'), value: `${total}€${totalThreshold ? ` dès ${totalThreshold}€ d'achat` : ''}`}]
      : []
    fields = fields.concat([
      {name: clientT('myTicketsV2.step3.date', {count: selectedTickets.length}),
        value: `${moment().format('DD/MM/YYYY')} à ${moment().format('H[h]mm')}`},
      {name: clientT('myTicketsV2.step3.recipient'), value: sellerName},
      {name: clientT('myTicketsV2.step3.conditions'), value: <span dangerouslySetInnerHTML={{__html: condition}}/> },
      {name: clientT('myTicketsV2.step3.code', {count: selectedTickets.length}),
        value: codes, className: 'smaller'},
    ]);

    return fields.filter(f => f.name && f.name.trim()).map((f, i) => (
      <div key={i} className={f.className}>
        {f.name} :
        <div className="data">{f.value}</div>
      </div>
    ))
  }

  renderDetailsPromo = () => {
    const {selectedTickets, sellerName} = this.props;
    const ticket = selectedTickets[0];
    const fields = [
      {name: clientT('myTicketsV2.step3.amount'), value: ticket.description},
      {name: clientT('myTicketsV2.step3.date', {count: selectedTickets.length}),
        value: `${moment().format('DD/MM/YYYY')} à ${moment().format('H[h]mm')}`},
      {name: clientT('myTicketsV2.step3.recipient'), value: sellerName},
      {name: clientT('myTicketsV2.step3.code', {count: selectedTickets.length}),
        value: ticket.prize, className: 'smaller'},
    ];

    return fields.filter(f => f.name && f.name.trim()).map((f, i) => (
      <div key={i} className={f.className}>
        {f.name} :
        <div className="data">{f.value}</div>
      </div>
    ))
  }

  handleReset = () => {
    if (this.feedbackRef && this.feedbackRef.submit) {
      this.feedbackRef.submit();
    }

    this.props.reset();
  }

  render() {
    const refundPrizes = encodeURIComponent(this.props.selectedTickets.map(p => p.prize).join('|'))

    const {
      promo, sellerName, usedTicket,
      sellerId, popup, sellerShortCode,
      selectedTickets
    } = this.props;

    const {usedTime} = this.state;
    return (
      <div className="confirmed-ticket">
        <div className='my-tickets-container' style={{paddingBottom: '24px'}}>
          <div className="sellerName" dangerouslySetInnerHTML={{
            __html: Context.partner?.client?.noThanks
              ? clientT('myTicketsV2.step3.titleNoThanks')
              : clientT('myTicketsV2.step3.title', {partnerName: sellerName})
          }}/>
          {selectedTickets?.length > 1 ? <div className="countTickets">(x{selectedTickets?.length})</div> : null}
          {!popup && <div className="usedTime">
            <Hourglass/>
            {clientT('myTicketsV2.step3.used', {count: usedTime})}
          </div>}
          {Context.features.kawaiiMode &&
            <img src={'/assets/icons/shibaTCA.png'} style={{width: 'auto', height: '110px'}}/>
          }
          <div className="detail">
            {promo ? this.renderDetailsPromo() : this.renderDetails()}
          </div>
          {/*<p> N'oubliez pas de revenir dès lundi prochain pour télécharger vos 10 nouveaux Tickets My Provence !</p>*/}
          {!usedTicket &&
            <div className="explaination">
              {Context.partner?.client?.confirmationText || clientT('myTicketsV2.step3.instruction')}
            </div>
          }
          {/*<p>Vous pouvez consulter votre historique dans la section Mes tickets utilisés</p>*/}
          {Context?.partner?.features?.feedback && !usedTicket &&
            <Feedback sellerId={sellerId} noButton ref={ref => this.feedbackRef = ref}/>
          }
          <AppButton
            onClick={this.handleReset}
            event="Terminer"
            client
            className='mainButton'>{t('common.finish')}
          </AppButton>

          {!popup && !promo &&
            <AppLink href='/historique' client>
              <AppButton className='secButton'>{clientT('myTicketsV2.step3.showUsed')}
              </AppButton>
            </AppLink>
          }
          {Context.partner.features.allowUserRefund && !promo &&
            <div className="refundBlock">
              <div className="separator"/>
              <AppLink href={`/refund/ask/${sellerShortCode}/${refundPrizes}`}
                       dangerouslySetInnerHTML={{__html: clientT('refund.goto')}}
              /></div>
          }
        </div>
      </div>
    );
  }
}

export default ConfirmedTicket;
