import React, {useEffect, useState} from "react";
import {t} from "../../tools/i18n";
import '../../style/course/raceStatus.scss';
import Context from "../../tools/context";

export default function RaceStatus({current = 0, count}) {
  const steps = t('course.page.header.status');

  const [imagePosition, setImagePosition] = useState(0);
  const [textPosition, setTextPosition] = useState(0);
  const [currentCountPosition, setCurrentCountPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      const imageWidth = document.querySelector('.raceStatus__steps img')?.offsetWidth;
      const containerWidth = document.querySelector('.raceStatus__steps')?.offsetWidth;
      const textWidth = document.querySelector('.raceStatus__steps span')?.offsetWidth;

      setImagePosition(Math.min(containerWidth - imageWidth, Math.max(0, Math.floor(current / count * containerWidth - imageWidth / 2))));
      setTextPosition(Math.min(containerWidth - textWidth, Math.max(0, Math.floor(current / count * containerWidth - textWidth / 2))));
      setCurrentCountPosition(document.querySelector('.raceStatus__countList h3')?.offsetHeight * current);
    }

    updatePosition();
    setTimeout(updatePosition, 200);
  }, [current, count]);

  return (
    <div className="RaceStatus">
      <div className="raceStatus__header">
        <h3>{t('course.page.header.title')}</h3>
        <div className="raceStatus__countContainer" style={{paddingLeft: 48}}>
          <div
            className="raceStatus__countList"
            style={{
              top: 0,
              width: 48,
              animation: 'translateToPositionList 1s 0.5s ease forwards',
            }}
          >
            {Array(count + 1).fill(0).map((_, index) => <h3 className="raceStatus_count">{index}</h3>)}
          </div>
          <h3>/{count}</h3>
        </div>
      </div>
      <div className="raceStatus__steps">
        <img
          src={Context.partner?.course?.page?.runUrl}
          alt="run"
          style={{
            left: 0,
            opacity: 0,
            animation: imagePosition ? 'translateToPositionImage 1s 0.5s ease forwards' : '',
          }}
        />
        <div className="raceStatus__progressBar">
          <div
            className="raceStatus__progress"
            style={{
              width: 0,
              opacity: 0,
              animation: 'translateToPositionProgress 1s 0.5s ease forwards',
          }}
          />
        </div>
        <span
          dangerouslySetInnerHTML={{__html: steps[current]}}
          style={{
            translate: 0,
            opacity: 0,
            animation: textPosition ? 'translateToPositionText 1s 0.5s ease forwards' : '',
          }}
        />
        <style>
          {`
            @keyframes translateToPositionList {
              from {
                translate: 0;
              }
              to {
                translate: 0 -${currentCountPosition}px;
              }
            }

            @keyframes translateToPositionImage {
              from {
                translate: 0;
                opacity: 0;
              }
              to {
                translate: ${imagePosition}px 0;
                opacity: 1;
              }
            }

            @keyframes translateToPositionProgress {
              from {
                width: 0;
                opacity: 0;
              }
              to {
                width: ${current / count * 100}%;
                opacity: 1;
              }
            }

            @keyframes translateToPositionText {
              from {
                translate: 0;
                opacity: 0;
              }
              to {
                translate: ${textPosition}px 0;
                opacity: 1;
              }
            }
          `}
        </style>
      </div>
    </div>
  )
};
