import React, {Component} from 'react';
import '../../style/TicketsPage.scss';
import moment from 'moment';
import {Context} from '../../tools/context';
import Ticket from "./ticket";
import {getQueryString, getRedirectionLink} from "../../tools/toolbox";
import AppLink from '../../components/AppLink';
import AppButton from '../../components/AppButton';
import {t, clientT} from '../../tools/i18n'
import {track} from "../../tools/tracking";
import LoaderSpinner from 'react-loader-spinner';
import Coupon from "./coupon";
import PaymentCards from "./ticket/PaymentCard";

class MyTickets extends Component {
  componentDidMount() {
    const {selectedTickets, shortcode} = this.props;

    if (!Array.isArray(selectedTickets)) return;
    track('viewMyTickets', {
      prize: selectedTickets.map(ticket => ticket.prize).join('|'),
      uuid: selectedTickets.map(ticket => ticket.uuid).join('|'),
      shortcode: shortcode
    });
  }

  getNextSunday = () => {
    const sunday = 7;
    const today = moment().isoWeekday();

    return today <= sunday
      ? moment().isoWeekday(sunday)
      : moment().add(1, 'weeks').isoWeekday(sunday);
  };

  renderTiCoHeader = () => {
    if (Context.partner?.platform !== 'ticketcommercant') return null;

    return <div className="ticoHeader">
      {/* <img src={`/assets/${Context.partner.sponsor}_logo.png`}
           alt="" title="logo"
      />
      <AppLink href="https://ticket-commercant.fr//" client>Ceci n'est pas votre ville ?</AppLink> */}
      <AppLink style={{marginTop: '15px'}} href="https://bienvenus.zendesk.com/hc/fr" client>
        <AppButton client event="Comment ça marche"
                   className="mainButton">{clientT('myTicketsV2.buttonLabel')}</AppButton>
      </AppLink>
    </div>
  }

  handleValid = () => {
    if (this.props.shortcode && getQueryString('noBurn') === 'true') {
      const refundPrizes = encodeURIComponent(this.props.selectedTickets.map(p => p.prize).join('|'));
      window.location.href = `/refund/ask/${this.shortcode}/${refundPrizes}?noBurn=true`;
      return;
    }

    this.props.nextStep && this.props.nextStep();
  }

  renderSelectPan = () => {
    const {
      selectedTickets,
    } = this.props;
    if (!selectedTickets || !selectedTickets.length) return null;

    const total = selectedTickets.reduce((acc, t) => acc + t.value, 0);
    const totalThreshold = selectedTickets.reduce((acc, t) => acc + t.threshold, 0);
    const condition = selectedTickets[0]?.condition || '';
    const redirectionLink = getRedirectionLink(selectedTickets);

    return (
      <div className="selected-pan">
        <div className={`ticket-steps-wrapper`}>
          <div className="step1-actions">
            <div>
              <p>{clientT('myTicketsV2.action.amount', {
                count: selectedTickets.length,
                amount: total
              })}</p>
              <p>{!!totalThreshold && t('myTicketsV2.action.threshold', {threshold: totalThreshold})}
                {' '}<span dangerouslySetInnerHTML={{__html: condition}}/></p>
              {/*{this.state.partnerName &&*/}
              {/*  <p dangerouslySetInnerHTML={{__html: clientT('myTicketsV2.action.partner', {partnerName: this.state.partnerName})}}/>}*/}
            </div>
            <div className="step2-actions">
              <AppButton
                className={`mainButton`}
                event="Suivant"
                client
                onClick={this.handleValid}>
                {t('common.next')}</AppButton>
              {redirectionLink &&
              <AppLink
                className={"Button secButton"}
                client
                target="_blank"
                href={redirectionLink}
              >
                {clientT('myTicketsV2.action.discoverPartners')}
              </AppLink>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  toDisplay = () => {
    const {tickets, isLoading} = this.props;

    if (isLoading && !tickets.length)
      return (
        <div className="loader">
          <LoaderSpinner type="Oval" height={80} width={80}/>
        </div>
      )


    if (!tickets.length) return <div className="noTicket">
      <div>{clientT('myTicketsV2.step1.noticket')}</div>
      {Context.features.kawaiiMode && <div><img style={{
        width: '150px', height: 'auto', marginTop: '40px'
      }} src={'assets/icons/chatTCASleep.png'}/></div>}
    </div>

    let paymentCards = [];
    let coupons = [];
    let ticketsWithoutCoupons = [];

    tickets.map(ticket => {
      if (ticket.campaignType === 'PaymentCard') {
        paymentCards.push(ticket);
      } else if (ticket.campaignData?.find(d => d.key === 'passType' && d.value === 'coupon')) {
        coupons.push(ticket);
      } else {
        ticketsWithoutCoupons.push(ticket);
      }
    });

    const mergedTickets = ticketsWithoutCoupons.reduce((acc, ticket) => {
      const existingTicket = acc.find(t => t.campaignId === ticket.campaignId && t.campaignType === "PaidPass");
      if (existingTicket) {
        existingTicket.uuids.push(ticket.uuid);
      } else {
        acc.push({
          ...ticket,
          uuids: [ticket.uuid]
        });
      }
      return acc;
    }, []);

    const hasMultipleCampaigns = ticketsWithoutCoupons.some(i => i.campaignId !== Context.partner.course?.campaignId);

    return (
      <div className='ticketList'>
        {Context.partner?.client?.myTicketsTitle &&
        <div className="explanation">{Context.partner?.client?.myTicketsTitle}</div>}
        {paymentCards?.length ? <PaymentCards tickets={paymentCards}/> : null}
        {mergedTickets
          .filter(t => hasMultipleCampaigns ? t.campaignId !== Context.partner.course?.campaignId : true)
          .map((ticket, index) => (
            <Ticket
              key={ticket.uuid}
              ticket={ticket}
              index={index}
              selected={this.props.selectedTickets.find(t => t.uuid === ticket.uuid)}
              handleSelection={this.props.handleSelection}
            />
          ))
        }
        {coupons?.length ? <div className="clientTitle">{clientT('myTicketsV2.couponsTitle')}</div> : null}
        {coupons.map(coupon => <Coupon key={coupon.uuid} coupon={coupon} />)}
      </div>
    )
  };

  render() {
    const partnerListURL = (Context.partner.partnerListUrl || '/#map').replace('/#map', '/landing#map');

    return (
      <div className='my-tickets-container'>
        {this.renderTiCoHeader()}
        <div className="subtitle">{clientT('myTicketsV2.step1.subtitle')}</div>
        {this.toDisplay()}
        {this.renderSelectPan()}
        {clientT('myTicketsV2.partnerList', {fallback: ''})
        && <AppLink client href={partnerListURL || "#"}>
          {clientT('myTicketsV2.partnerList')}
        </AppLink>
        }

      </div>
    );
  }
}

export default MyTickets;
