import React, { Component } from 'react';
import Context from '../../tools/context';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
export default class PhoneField extends Component {
  handleNumberChange = (valid, number, output, formatted) => {
    this.props.handleChange({
      target: {
        id: this.props.id,
        value: {
          number,
          code: output.dialCode,
          valid,
          formatted: formatted.replace(/ /g, ''),
        },
      }
    });
  }
  handleFlagChange = (number, output, formatted, valid) => {
    Context.dialCode = output.dialCode;
    this.props.handleChange({
      target: {
        id: this.props.id,
        value: {
          number,
          code: output.dialCode,
          valid,
          formatted: formatted.replace(/ /g, ''),
        },
      }
    });
  }

  getCustomPlaceholder(selectedCountryPlaceholder, selectedCountryData) {
    if (selectedCountryData?.dialCode === "33")
      return "06 XX XX XX XX"
    return selectedCountryPlaceholder;
  }

  render(){
    const {id, label,
      optional,
      errorMsg, value,
      subLabel,
      disabled,
      fieldOnly,
      onKeyPress,
      inputRef,
      configuration,
    } = this.props;

    const countries = {
      '262': 're',
      '33': 'fr',
      '596': 'mq',
      // '262': 'yt',
      '508': 'pm',
      '590': 'bl',
      // '590': 'mf',
      '681': 'wf',
      '689': 'pf',
      '687': 'nc',
      '594': 'gf',
    }

    if (fieldOnly) {
      return <div onKeyPress={onKeyPress}>
          <IntlTelInput
          ref={inputRef}
          fieldId={id}
          containerClassName="intl-tel-input"
          inputClassName="tel-input"
          onPhoneNumberChange={this.handleNumberChange}
          onSelectFlag={this.handleFlagChange}
          preferredCountries={Context.partner?.phone?.preferredCountries || ['fr']}
          value={value ? value.number : ''}
          onlyCountries={Context.partner?.phone?.countries
          || [...Object.values(countries), 'gp', 'yt', 'mf']}
          defaultCountry={Context.partner?.phone?.defaultCountry || 'fr'}
          disabled={disabled}
          customPlaceholder={this.getCustomPlaceholder}
          {...configuration}
        />
      </div>
    }

    return (
      <p onKeyPress={onKeyPress}>
        <label htmlFor={id}>
          {label}{!optional ? <span
          className="required">*</span> : null} <span
          className="subLabel">{subLabel}</span>
        </label>
        <IntlTelInput
          ref={inputRef}
          fieldId={id}
          containerClassName="intl-tel-input"
          inputClassName="tel-input"
          format={true}
          onPhoneNumberChange={this.handleNumberChange}
          onSelectFlag={this.handleFlagChange}
          preferredCountries={Context.partner?.phone?.preferredCountries || ['fr']}
          value={value ? value.number : ''}
          onlyCountries={[...Object.values(countries), 'gp', 'yt', 'mf']}
          defaultCountry={value && countries[value.code] ? countries[value.code] :
            (Context.partner?.phone?.defaultCountry || 'fr')}
          disabled={disabled}
          customPlaceholder={this.getCustomPlaceholder}
          {...configuration}
        />
        {errorMsg ?
          <span className="error">
                  {errorMsg}
                </span>
          : null}
      </p>
    )
  }
}