import React, {Component} from 'react';
import '../../style/TicketsPage.scss';
import moment from 'moment';
import {Context} from '../../tools/context';
import Ticket from "./ticket";
import Promo from './ticket/Promo'
import {getQueryString, getRedirectionLink} from "../../tools/toolbox";
import AppLink from '../../components/AppLink';
import AppButton from '../../components/AppButton';
import {t, clientT} from '../../tools/i18n'
import {track} from "../../tools/tracking";
import LoaderSpinner from 'react-loader-spinner';
import TCAGiftCard from "./ticket/TCAGiftCard";

class MyPromos extends Component {
  componentDidMount() {
    const {selectedTickets} = this.props;

    if (!Array.isArray(selectedTickets)) return;
    track('viewMyTickets', {
      prize: selectedTickets.map(ticket => ticket.prize).join('|'),
      uuid: selectedTickets.map(ticket => ticket.uuid).join('|'),
    });
  }

  toDisplay = () => {
    const {tickets, isLoading, selectedTicket} = this.props;

    if (isLoading && !tickets.length)
      return (
        <div className="loader">
          <LoaderSpinner type="Oval" height={80} width={80}/>
        </div>
      )


    if (!tickets.length) return <div className="noTicket">
      <div>{clientT('myPromo.step1.noticket')}</div>
    </div>

    return (
      <div className='ticketList'>
        {Context.partner?.client?.myTicketsTitle &&
        <div className="explanation">{Context.partner?.client?.myTicketsTitle}</div>}
        {tickets.map((ticket, index) => <Promo
          key={ticket.uuid}
          ticket={ticket}
          index={index}
          selected={selectedTicket && (selectedTicket.uuid === ticket.uuid)}
          handleSelection={this.props.handleSelection}
          promo={false}
          type={Context.partner?.client?.ticketType}
        />)}
      </div>
    )
  };

  render() {
    return (
      <div className='my-tickets-container'>
        <div className="subtitle">{clientT('myPromo.step1.subtitle')}</div>
        {this.toDisplay()}
      </div>
    );
  }
}

export default MyPromos;
