import PinHole from "../../components/PinHole";
import {customer, getMetaCategories, getMetaCategoryColors, getQueryString} from "../../tools/toolbox";
import ReactMapGL, {Marker, WebMercatorViewport} from "react-map-gl";
import PinPopup from "./PinPopup";
import {reactFormatter, ReactTabulator} from "react-tabulator";
import React, {Component} from "react";
import {ajaxCall} from "../../tools/apitools";
import {Context} from "../../tools/context";
import {maxBy, minBy} from "lodash";
import {clientT} from "../../tools/i18n";
import Image from "../../components/Image";
import Icon from "../../components/Icon";
import {Link} from 'react-router-dom';

class VendorsMap extends Component {
  constructor(props) {
    super(props);

    this.tabRef = React.createRef();
    this.state = {
      vendors: [],
      metaCateg: [],
      categFilter: [],
      nameFilter: '',
      filtered: [],
      selectedPin: null,
      tagFilter: null,
      viewport: {
        zoom: 15,
        longitude: 5.4,
        latitude: 43.3,
        width: '100%',
        height: 600,
      },
    };

    this.coupons = getQueryString('coupon');
  }

  componentDidMount() {
    this.getVendors();
  }

  getVendors = async () => {
    const res = await ajaxCall(
      `api/sellers/${Context.partner.sponsor}?customerId=${customer()}`,
      {}, true).then(
      (r) => {
        if (!r.ok) return;
        return r.json();
      });

    if (res) {
      const metaCateg = [];
      const vendors = res
        .filter(re => (re.name || re.street || re.city)
          && (!Context.partner.postcode || !re.zipcode || re.zipcode.startsWith(Context.partner.postcode))
          && (this.props.sellersFilter?.length > 0 ? this.props.sellersFilter.includes(re.datamap?.status) : false)
        )
        .map(r => {
          const meta = getMetaCategories(r.category);
          if (!metaCateg.includes(meta)) {
            metaCateg.push(meta);
          }

          return {
            ...r,
            metaCateg: meta,
          }
        });
      this.setState({
          vendors,
          metaCateg,
        }, () => this.filter()
      );
    }
  }

  filter = () => {
    const {nameFilter, categFilter, tagFilter} = this.state;
    const filtered = this.state.vendors.filter(v =>
      (!nameFilter || (v.name && v.name.toLowerCase().includes(nameFilter.toLowerCase())))
      && (!categFilter.length || categFilter.includes(v.metaCateg))
      && (!tagFilter || tagFilter === v.tag)
    );

    let viewport = {...this.state.viewport};
    if (filtered.length) {
      const minLat = minBy(filtered, (f) => f.latitude).latitude;
      const maxLat = maxBy(filtered, (f) => f.latitude).latitude;
      const minLng = minBy(filtered, (f) => f.longitude).longitude;
      const maxLng = maxBy(filtered, (f) => f.longitude).longitude;

      const {longitude, latitude, zoom} = new WebMercatorViewport(this.state.viewport)
        .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: 10,
          offset: [0, 0]
        });

      viewport = {
        ...viewport,
        longitude,
        latitude,
        zoom,
      };
    }


    this.setState({
      filtered,
      viewport,
    })
  }

  handleCategFilter = (cat) => {
    const categFilter = [...this.state.categFilter];
    const idx = categFilter.findIndex(c => c === cat);
    if (idx >= 0) {
      categFilter.splice(idx, 1);
    } else {
      categFilter.push(cat);
    }

    this.setState({categFilter}, () => this.filter());
  }

  caseFormater = (cell, params, onRendered) => {
    return this.capitalizeFirstLetter(cell?.getValue()?.toLowerCase())
  };

  capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  formatWebsite = (cell, params, onRendered) => {
    const cellValue = cell.getValue();

    if (!cellValue) return null;
    return reactFormatter(
      <a href={cellValue} target="_blank" style={{display: 'flex', justifyContent: 'center'}}>
        <Icon name={"website"} style={{height: '20px'}}/>
      </a>
    )(cell, params, onRendered);
  };

  renderTagFilter = () => {
    if (!Context.partner.tag || !Context.partner.tag.length) return null;
    const tags = Context.partner.tag.sort((a, b) => a.label.localeCompare(b.label));

    return <div className="tagFilterList">
      {tags.map(t => <button
        onClick={() => {
          this.setState(p =>({tagFilter: p.tagFilter === t.value ? null : t.value}), () => {
            this.filter();
          })
        }}
        className={`tagFilter ${t.value === this.state.tagFilter ? 'selected' : ''}`}
        key={t.value}
      >
        {t.label}
      </button>)}
    </div>
  }

  render() {
    const columns = [ //Define Table Columns
      {
        title: clientT('form.mapName'),
        field: "name",
        headerSortTristate: true,
        headerFilter: true,
        minWidth: 125,
        headerFilterPlaceholder: 'Filtrer',
        formatter: this.caseFormater
      },
      {
        title: clientT('form.address'),
        field: "street",
        headerSortTristate: true,
        headerFilter: true,
        minWidth: 150,
        headerFilterPlaceholder: 'Filtrer',
        formatter: this.caseFormater
      },
      {
        title: clientT('form.city'),
        field: "city",
        headerSortTristate: true,
        headerFilter: true,
        minWidth: 100,
        headerFilterPlaceholder: 'Filtrer',
        formatter: this.caseFormater
      },
      {
        title: clientT('form.category'),
        field: "metaCateg",
        headerSortTristate: true,
        headerFilter: true,
        minWidth: 100,
        headerFilterPlaceholder: 'Filtrer',
      },
      {
        title: '',
        field: "website",
        headerSort: false,
        width: 100,
        formatter: this.formatWebsite
      }
    ];

    if (this.props.displayShortcode) {
      columns.pop();
      columns.push({
        title: clientT('form.shortcode'),
        field: "shortcode",
        headerSortTristate: true,
        headerFilter: true,
        minWidth: 100,
        headerFilterPlaceholder: 'Filtrer',
      });
      columns.push({
        title: '',
        field: "website",
        headerSort: false,
        width: 100,
        formatter: this.formatWebsite
      });
    }

    const options = {
      layout: 'fitColumns',
      pagination: "local",
      paginationSize: 10,
      selectable: 1,
      initialSort: [{
        column: "name",
        dir: "asc",
      }],
      locale: 'fr-fr',
      langs: {
        'fr-fr': {
          pagination: {
            "first": "Début",
            "first_title": "Première Page",
            "last": "Fin",
            "last_title": "Dernière Page",
            "prev": "Précédent",
            "prev_title": "Page Précédente",
            "next": "Suivant",
            "next_title": "Page Suivante",
            "all": "Toute",
          },
          "headerFilters": {
            "default": "Filtrer", //default header filter placeholder text
            "columns": {
              "name": "Filtrer", //replace default header filter text for column name
            }
          }
        },
      },
    };

    return (
      <div>
        {this.renderTagFilter()}
        <div className="mapBlock">
          <div className="categoryFilters">
            <div className="header">
              {clientT('landing.categoryFilter')}
            </div>
            {this.state.metaCateg.sort().map((cat, index) => (
              <div
                key={index}
                className="categButton"
                onClick={() => {
                  this.handleCategFilter(cat)
                }}>
                <div className="pin">
                  <PinHole height={28} width={28} color={getMetaCategoryColors(cat)}/>
                </div>
                <div className="label">
                    <span style={this.state.categFilter.includes(cat) ? {
                      borderBottom: 'solid 2px',
                      borderColor: getMetaCategoryColors(cat),
                    } : {}}>{cat}</span>
                </div>
              </div>
            ))}
          </div>
          <div id="map" className="map">
            <div className="searchBar">
              <input onChange={e => {
                this.setState({nameFilter: e.target.value}, () => this.filter());
              }}
                     placeholder={clientT('landing.nameSearch')}
              />
              <div className="searchText">{clientT('landing.search')}</div>
              <div className="searchIcon"><Image id="searchIcon" src={'assets/icons/search.png'}/></div>
            </div>
            <ReactMapGL
              {...this.state.viewport}
              style={{maxWidth: '100%'}}
              onViewportChange={viewport => this.setState({viewport})}
              mapStyle={'mapbox://styles/mapbox/streets-v11'}
              mapboxApiAccessToken='pk.eyJ1IjoibHZlcmRvbmsiLCJhIjoiY2s4OHY0b2llMDBkeTNpbXBhNmRnMmU3dCJ9.adHYqa4eOwGNKzBR9EJB7A'
            >
              {this.state.filtered
                .filter(fi => fi.longitude && fi.latitude)
                .map((f, index) =>
                  <Marker
                    longitude={f.longitude}
                    latitude={f.latitude}
                    key={index}
                  >
                    <div
                      style={{position: "relative"}}
                      onClick={(e) => {
                        this.setState({selectedPin: this.state.selectedPin?.id === f.id ? null : f}, () => {
                          this.tabRef.current.table.deselectRow()
                          this.tabRef.current.table.selectRow(f.id)
                          this.tabRef.current.table.setPageToRow(f.id)
                        });
                      }}
                    >
                      <PinHole height={20} width={20} color={getMetaCategoryColors(f.metaCateg)}/>
                    </div>
                  </Marker>
                )}
              {this.state.selectedPin && <Marker
                longitude={this.state.selectedPin.longitude}
                latitude={this.state.selectedPin.latitude}
              > <PinPopup
                data={this.state.selectedPin}
                coupons={this.coupons}
                refund={this.props.refund}
                platform={this.props.platform}
                onClick={() => this.setState({selectedPin: null})}
              />
              </Marker>}
            </ReactMapGL>
            {this.props.moreSellers && Context.partner.customerId && clientT('landing.moreSellers', {fallback: ''}) &&
              <Link className='moreSellers' to={`/etablissements?coupon=${this.coupons}`}>
                {clientT('landing.moreSellers')}
              </Link>
            }
          </div>
        </div>
        <ReactTabulator
          className="tab"
          ref={this.tabRef}
          columns={columns}
          data={this.state.filtered}
          layout={'fitData'}
          options={options}

          rowClick={(e, row) => {
            const data = row._row.data;
            if (!data.longitude || !data.latitude) return;
            this.setState({
              viewport: {
                ...this.state.viewport,
                longitude: data.longitude,
                latitude: data.latitude,
                zoom: 14,
              },
              selectedPin: data,
            })
          }}
        />
      </div>
    )
  }
}

export default VendorsMap;