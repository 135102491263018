import React from 'react';
import Context from "../../../tools/context";
import './index.scss';
import TCATickets from "./TCATickets";
import PassTickets from "./PassTickets";
import TCAGiftCard from "./TCAGiftCard";
import PaymentCard from "./PaymentCard";

const Ticket = (props) => {
  const {ticket, showPromo} = props;

  if (ticket.campaignData && ticket.campaignData.some(d => d.key === 'passType')) {
    return <PassTickets {...props} />
  }

  const ca = ticket.giftType === 'Standard';
  if ((showPromo && ca) || (!showPromo && !ca)) return null;

  if (Context.partner.landingPlatform === 'tca') {
    return <TCAGiftCard {...props} promo={!ca} type={Context.partner?.client?.ticketType} />
  }

  return <TCATickets {...props} promo={!ca} type={Context.partner?.client?.ticketType} />
}

export default Ticket;