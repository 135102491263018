import React, {Component, useState} from 'react';
import {flatten} from 'lodash';
import Context from "../../tools/context";
import FormField from "../../components/formField";
import {fields} from '../../tools/formFieldsDatabase';
import MapStep from "./MapStep";
import StepButtons from "./StepButtons";
import {customer, siteContext} from "../../tools/toolbox";
import {clientT} from "../../tools/i18n";

export default ({
                  handleNext,
                  handlePrevious,
                  handleChange,
                  handleSubmit,
                  history,
                  state,
                  step,
                  ...props
                }) => {
  const [error, setError] = useState({});
  const [showMap, setShowMap] = useState(false);

  if (!Context?.partner?.register?.form) {
    window.alert('Erreur de chargement, inscription indisponible');
    return false;
  }

  if (!Context.partner.register.form[step]) {
    handleNext();
    return false;
  }
  const form = Context.partner.register.form[step];

  const handleLocalChange = e => {
    const key = e.target.id;
    if (error[key]) {
      const er = {...error};
      delete er[key];
      setError(er);
    }
    handleChange(e);
  }

  const checkPhone = (key, flatForm) => {
    if (!flatForm.includes(key)) return true;

    const phone = state[key]
    if (!phone.valid || !phone.number) {
      const er = {};
      er[key] = 'Le numero de téléphone n\'est pas valide'
      setError(er);
      return false;
    }

    return true;
  }

  const existingAccount = async () => {
    if (!state.loginEmail && !state.ownerPhone) return true

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "*/*");
    const data = await fetch(`${siteContext().endpoint}api/seller/checkinfo?customerId=${customer()}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        loginEmail: state.loginEmail,
        contactEmail: state.contactEmail,
      }),
    }).then(r => r.json());

    let er = {};
    let valid = true;
    if (data.loginEmailExists) {
      er.stepButton = '<a class=" error" href="/reset_password"> Cet email est préinscrit ou déja utilisé, cliquez ICI pour récupérer votre mot de passe. </a>';

      valid = false;
    }
    if (data.contactEmailExists) {
      er.contactEmail = 'Un compte existe déjà avec cet email';
      valid = false;
    }
    setError(er);

    return valid;
  }

  const handleLocalSubmit = async (e) => {
    e.preventDefault();

    const flatForm = flatten(Object.keys(form).map(t => Object.keys(form[t])));

    if (flatForm.includes('password1') && flatForm.includes('password2')
      && state.password1 !== state.password2) {
      setError({password1: 'Les deux mots de passe ne correspondent pas.'});
      return false;
    }

    if (!checkPhone('contactPhone', flatForm)) return false;
    if (!checkPhone('ownerPhone', flatForm)) return false;

    if (flatForm.includes('loginEmail') || flatForm.includes('contactEmail')) {
      const allow = await existingAccount();
      if (!allow) return false;
    }

    if (flatForm.includes('address')) {
      setShowMap(true);
      return false;
    }

    if (step === 3) {
      handleSubmit()
    } else {
      handleNext();
    }
    return false;
  }

  const getExistingField = (k) => {
    if (fields()[k]) return fields()[k];

    if (!Context.partner.optins) return null;

    const field = Context.partner.optins.find(f =>
      f.id === k || f.id.toLowerCase() === `${Context.partner.sponsor}_${k}`.toLowerCase());
    if (field) {
      field.type = 'checkbox';
      return field;
    }

    return null;
  }

  const steps = clientT(`registerV2.subTitle`)
  return (
    <>
      {step < steps.length && <div className="stepper">
        <div className="futur">Étape {step+1}/{steps.length}</div>
        <div className="steps">
          {steps.map((s, i) => <div
            key={`step_${i}`}
            data-step={step}
            data-i={i}
            className={`step ${step>=i ? 'done' : 'todo'}`}
          />)}
        </div>
      </div>}
      <div className='header'
           dangerouslySetInnerHTML={{__html: clientT(`registerV2.subTitle.${step}`)}}
      />
      <form onSubmit={handleLocalSubmit} className='formWrapper'>
        {Object.keys(form).map(t => (
          <div key={t}>
            <div className="formSubTitle">{t}</div>
            <div>
              {Object.keys(form[t]).map(k => {
                if (!Object.keys(form[t][k]).length) {
                  const field = getExistingField(k);
                  if (field) {
                    return <FormField
                      {...field}
                      id={k}
                      key={k}
                      value={state[k] || ''}
                      handleChange={handleLocalChange}
                      register
                      errorMsg={error[k]}
                    />
                  }
                }

                return <FormField
                  {...form[t][k]}
                  id={k}
                  key={k}
                  value={state[k] || ''}
                  handleChange={handleLocalChange}
                  register
                  errorMsg={error[k]}
                />
              })}
            </div>
          </div>
        ))}

        <StepButtons previous={step
          ? handlePrevious
          : () => history.replace('/connexion')
        }/>
        {error['stepButton'] &&
        <span   dangerouslySetInnerHTML={{ __html:  error['stepButton'] }} >

        </span>}
      </form>
      {showMap && <MapStep
        state={state}
        handleChange={handleChange}
        handleNext={handleNext}
        handleClose={() => setShowMap(false)}
      />}
    </>
  )
}