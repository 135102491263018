import {siteContext, saveContext, logout, customer, setCookie, redirect, getQueryString, isStringJson} from "./toolbox";
import {initSiteContext} from "../envConfig"
import {ContextUtil, Context} from "./context";
import {getFields, getFlatFieldKeys, userProfileFieldBase,getCheckboxes} from "./formFieldsDatabase";

const logToOauth = async ({ login, password, refreshToken }, callback, reject) => {
  if (!siteContext().endpoint) initSiteContext();
  siteContext().role = {};
  ContextUtil.update(`role`, {});
  saveContext();

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  const body = !refreshToken
    ? {
        username: login,
        password: password,
        grantType: "password",
        scope: "read+write",
      }
    : {
        grantType: "refresh_token",
        refreshToken: refreshToken,
      };
  const cid = customer()
  const result = await fetch(`${siteContext().endpoint}/api/users/auth?customerId=${cid}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
  })
    .then((r) => {
      if (r.ok && r.status === 200) {
        return r.json();
      }

      reject(r.status === 500);
    });

  if (!result) {
    return reject();
  }

  siteContext().accessToken = result.access_token;
  siteContext().refreshToken = result.refresh_token;
  saveContext();

  await getAccount()
  const redirect = await getSeller();
    if(!redirect) {
        callback();
    }
};

const getCustomerConfig = async () => {
  if (!siteContext().endpoint) initSiteContext();

  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  const cid = customer();

  // TODO Context.partner.customerId
  const result = await fetch(`${siteContext().endpoint}api/customers/${cid}/configuration`, {
    method: "GET",
    headers: headers,
  })
    .then((r) => {
      if (r.ok && r.status === 200) {
        return r.json();
      }
    });

  const features = {...Context.features};
  if (result.featureList && result.featureList.length) {
    result.featureList.forEach(feat => {
      features[feat.key] = feat.enabled;
    })
  }
  ContextUtil.update(['partner','theme'], result.theme);
  ContextUtil.update('features', features);

  return;
};

const getUserData = async (keys, callback, reject) => {
  if (!siteContext().endpoint) initSiteContext();
  const key_list = keys.join();
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("CIBLER_AUTH", siteContext().clientToken);
  const cid = customer();

  // TODO Context.partner.customerId
  const result = await fetch(`${siteContext().endpoint}api/users/data/${cid}?keys=${key_list}`, {
    method: "GET",
    headers: headers,
  })
    .then((r) => {
      if (r.ok && r.status === 200) {
        return r.json();
      }
      reject(r);
    });

  if (!result) {
    return reject();
  }

  callback(result);
};


const checkUserData = async (callback, reject) => {
  const adaptater = {
    lastname: 'name',
    contactEmail: 'email',
    zipcode: 'zipcode',
    question_1: `${Context.partner.sponsor}_custom_1`,
    question_2: `${Context.partner.sponsor}_custom_2`,
  };
  const keys = [];
  const fields = getFields('clientProfile', userProfileFieldBase);
  Object.keys(fields).forEach(name => {
    const field = fields[name]
    field.forEach(f => {
      if (!f.optional) {
        f.id = adaptater[f.id] || f.id;
        keys.push(f.id);
      }
    })
  })

  const key_list = keys.join();
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("CIBLER_AUTH", siteContext().clientToken);
  const cid = customer();

  // TODO Context.partner.customerId
  const result = await fetch(`${siteContext().endpoint}api/users/data/${cid}?keys=${key_list}`, {
    method: "GET",
    headers: headers,
  })
    .then((r) => {
      if (r.ok && r.status === 200) {
        return r.json();
      }
      reject(r);
    });

  if (!result) {
    return reject();
  }

  if (result.zipcode?.startsWith('13')) {
    const ind = keys.findIndex(k => k===`${Context.partner.sponsor}_custom_2`);
    keys.splice(ind, 1);
  }

  if(keys.some(k => !result[k] && result[k] !== 0)) {
   return reject();
  }

  callback();
}

const postUserData = async (body, callback, reject) => {
  if (!siteContext().endpoint) initSiteContext();
  const cid = customer();

  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("CIBLER_AUTH", siteContext().clientToken);

  // TODO Context.partner.customerId
  const result = await fetch(`${siteContext().endpoint}/api/users/data/${cid}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
    .then((r) => {
      if (r.ok && r.status === 200) {
        return r;
      }

      reject && reject(r);
    });

  if (!result) {
    return reject && reject();
  }

   callback && callback(result);
  return result;
};

const getAccount = async () => {
  const ac = await ajaxCall(`api/account`, {});

  if (!ac.ok || ac.status !== 200) {
    return;
  }

  const account = await ac.json();
  if (!account) return;

    siteContext().sellerToken = account.clientToken;
    setCookie('sellerLoginToken', siteContext().sellerToken, 7);
  (account.roles || []).map(r => {
    siteContext().role[r] = true;
      ContextUtil.update(`role.${r}`, true);
  })

  saveContext();
  return;
}

const loginWithUserPhone = (body, keys, callback, reject) =>{
  const id = customer();
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  const key_list = keys.join();

  fetch(`${siteContext().endpoint}/api/bienvenus/login?customerId=${id}${keys?.length ? `&keys=${key_list}` : ""}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
  })
    .then((r) => {
      console.log(r.status.ok)
      if (r.ok && r.status === 200) {
        return r.json();
      } else {
        reject();
      }
    })
    .then(result => {
      if (!result) return;

      callback(result);
    })
    .catch(reject);
}

const validatePhoneCode = (body,callback,reject) =>{
  const id = customer();
  body.customerId=id;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  fetch(`${siteContext().endpoint}/api/bienvenus/validate`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
  })
    .then((r) => {
      console.log(r.ok)
      if (r.ok && r.status === 200) {
        return r.text();
      } else {
        try {
          return r.json();
        } catch (e) {
          reject(e);
        }
      }
    })
    .then(result => {
      if (!result || result.message) return Promise.reject(result);

      callback(result);
    })
    .catch(reject);
}

const reverseLogin = async (body) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");
  myHeaders.append("Content-Type", "application/json");

  return await fetch(
    `${siteContext().endpoint}/api/bienvenus/reverselogin?customerId=${customer()}`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(body),
    }
  ).then(r => r.ok && r.status===200 ? r.text() : null);
}

const getIsMember = async (number, callback, reject) => {

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  const r = await fetch(`${siteContext().endpoint}/api/seller/${Context.seller.shortcode}/member/${number}`, {
    headers: myHeaders,
  });

  return await r.json();
}
const checkProfileIsIncomplete = ()=> {
    const r = Context.seller;
    const keyList = getFlatFieldKeys('register');
    const exclude = ['password1', 'password2', 'loginEmail'];

    return keyList.some(k => {
        if (exclude.includes(k)) return false;
        if (r[k]) return false;

        const dat = r.data.find(d => d.key === k);
        if (dat && dat.value) return false;

        if (k === 'address' && r.longitude && r.latitude) return false;

        console.log('key', k)
        return true;
    })
}

const postMember = (body,callback,reject) =>{
  const id = customer();
  body.customerId = id;

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  fetch(`${siteContext().endpoint}/api/seller/${Context.seller.shortcode}/member`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
  })
    .then((r) => {
      console.log(r.status.ok)
      if (r.ok && r.status === 200) {
        return r.json();
      } else {
        reject();
      }
    })
    .then(result => {
      if (!result) return;

      callback(result);
    })
    .catch(reject);
}
const getSeller = async (refreshData, callback) => {
  await authCall(
    'api/seller/',
    {},
    r => {
      if (!r) return;

      if(r && Array.isArray(r)){
            //Get current seller from list
            siteContext().alts = r
            r = r[0]
      }
      console.log('seller', r)
      Context.seller = r
      siteContext().seller = r;
      saveContext();

      const status = r.data.find(k => k.key === `${Context.partner.sponsor}_status`);
      // If the status is denied(except for admin and backuser) then redirect to login page
      const isDenied = status && status.value.toLowerCase() === 'denied';
      if (isDenied && !(siteContext().role.ROLE_ADMIN || siteContext().role.ROLE_BACKUSER)) {
          logout();
          window.location.replace('/connexion?status=denied');
      }

      if (refreshData) {
        callback && callback();
        return;
      }

      if (!status || !status.value) {
        const body = {};
        body[`${Context.partner.sponsor}_status`] =
          Context.partner.features.requireValidation ? 'created' : 'accepted';

        authCall(`api/seller/data/${r.id}`, {
          method: 'PUT',
          body: JSON.stringify(body),
        }, () => undefined, () => undefined)
      }



      let checkboxes = getCheckboxes(true);
      checkboxes.forEach(c => {
          if(c.optional)
              return;
          const dat = r.data.find(d => d.key === c.id);
          if (dat && dat.value) return;
          console.log('key', c)
          //window.location = '/profile?completeInfos=true';

      });
    }, () => {
      callback && callback();
    })
    if(!refreshData && checkProfileIsIncomplete()){
        redirect('/profile?completeInfos=true');
        return true;
    }
    return false;
}


const resetPassword = (login, callback, reject) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");
  myHeaders.append("Content-Type", "text/plain");

  const id = customer();

  fetch(`${siteContext().endpoint}/api/account/reset_password/init?customerId=${id}`, {
    method: "POST",
    headers: myHeaders,
    body: login,
  })
    .then((r) => {
      console.log(r.status.ok)
      if (r.ok && r.status === 200) {
        callback();
      } else {
        r.text().then((result) => {
          reject(result === 'e-mail address not registered')
        })
      }
    })
    .catch(() => reject());
};


const setNewPassword = (password, resetKey, callback, reject) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  fetch(`${siteContext().endpoint}/api/account/reset_password/finish?key=${
    resetKey}&newPassword=${password}`, {
    method: "POST",
    headers: myHeaders,
  })
    .then((r) => {
      console.log(r.status.ok)
      if (r.ok && r.status === 200) {
        callback();
      } else {
        r.text().then(() => {
          reject()
        })
      }
    })
    .catch(() => reject());
};

const ajaxCall = (url, params, noAuth) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  if (!noAuth) {
    myHeaders.append("Authorization", `Bearer ${siteContext().accessToken}`);

    if (params.headers) {
      params.headers.append("Authorization", `Bearer ${siteContext().accessToken}`);
    }
  }
  if(!url.startsWith("http")){
      url = siteContext().endpoint+url;
  }
  return fetch(`${url}`, {
    headers: myHeaders,
    ...params,
  });
};

const authClient = async (url, requestParams, callback, reject, isText) => {

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "application/json");
  myHeaders.append("CIBLER_AUTH", siteContext().clientToken);

  console.log('authclient');
  let status;
  try {
    const r = await fetch(`${siteContext().endpoint + url}`, {
      headers: myHeaders,
      ...requestParams
    })

    status = r.status;

    if (r.ok && r.status === 200) {
      const result = isText ? await r.text() : await r.json();
      callback && callback(result);
      return result;
    }

    const result = isText ? await r.text() : await r.json();
    reject && reject(result, r.status);
    return result;

  } catch (e) {
    reject && reject(e, status);
  }
}

const authCall = async (url, requestParams, callback, reject, textType) => {
  if (!siteContext().endpoint) initSiteContext();

  if (!siteContext().accessToken) {
    window.siteContext = null;
    localStorage.removeItem("siteContext");
    window.location.replace("/connexion");
  }

  if(!url.includes('customerId=')){
    url += `${url.includes('?') ? '&' : '?'}customerId=${customer()}`
  }


  const r = await ajaxCall(url, requestParams);

  if (!r.ok) {
    if (!siteContext().refreshToken) {
      logout();
      window.location.replace("/connexion");
      return;
    }

    if (r.status === 401) {
      logToOauth(
        {refreshToken: siteContext().refreshToken},
        async () => {
          const rr = await ajaxCall(url, requestParams);

          if (!r.ok) {
            return reject(r)
          }

          try {
            const result = await rr.json();

            return callback(result);
          } catch(e) {
            callback()
          }
        },
        () => {
          logout();
          window.location.replace("/");
        }
      );
    } else {
        const result = await r.text();
      return reject(result,r.status);
    }
  } else {
    try {
      const result = textType? await r.text():await r.json();

      return callback(result);
    } catch(e) {
      callback()
    }
  }
};

const sendRegisterInfo = async () => {
    const basedData = getQueryString('data');
    if (!basedData) return;

    const rawData = decodeURIComponent(escape(atob(basedData)));
    if (!rawData || !isStringJson(rawData)) return;

    const data = JSON.parse(rawData);
    return postUserData(data);
}

const addressGeocoding = async (address) => {
  const apikey = "AIzaSyCfh2KYAc_csi1Xwd7XDDctvAp7lsUGy1s"
  const data = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${
      apikey}&address=${encodeURIComponent(address)}`
  )
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      } else {
        return alert("Erreur serveur");
      }
    })
    .catch((error) => {
      alert("Erreur : " + error);
    });
  return data;
};

const reverseGeocoding = async (longitude, latitude) => {
  const apikey = "AIzaSyCfh2KYAc_csi1Xwd7XDDctvAp7lsUGy1s"
  const data = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
    latitude},${longitude}&key=${apikey}`)
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      } else {
        return alert("ERROR");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

const createUser = async (body) => {
  if (!siteContext().endpoint) initSiteContext();
  const id = customer();

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Accept-Encoding", "gzip, deflate, br");
  myHeaders.append("Connection", "keep-alive");
  if(siteContext().accessToken)
    myHeaders.append("Authorization", `Bearer ${siteContext().accessToken}`);
  const data = await fetch(`${siteContext().endpoint}/api/seller/?customerId=${id}`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        switch (response.status) {
          case 401:
            return {description: 'SellerAccountAlreadyExists'};
          default:
            break;
        }
        switch (response.message) {
          case 'SellerAlreadyExists':
            return {description: 'SellerAlreadyExists'};
          default:
            break;
        }
      }
      if (response.ok && response.status === 200) {
        return response.json();
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error)
    });
  return data;
};

const ACtrackEvent = ( event,data) => {
  try {
      if(siteContext().clientToken)
         authClient('api/users/event/'+event+"?customerId="+Context.partner.customerId+"&data="+encodeURIComponent(JSON.stringify(data)),{method: 'GET'});
  } catch (e) {
    console.log(e)
  }
}

const uploadImage = (file, fileName, imageNumber = 1, specificPartner, callback, reject) =>
  uploadImageMethod('api/seller/image', file, fileName, specificPartner, callback, reject, imageNumber)

const uploadImageMethod = (url, file, fileName, specificPartner, callback, reject, imageNumber) => {
  const id = customer();

  const formData = new FormData();
  formData.append('file', file, fileName);

  const headers = new Headers();

  const partner = specificPartner ? `&partner=${Context.partner.sponsor}` : '';

  authCall(`${url}?customerId=${id}${partner}&imageNumber=${imageNumber}`, {
    method: 'POST',
    headers,
    body: formData
  }, (r) => {
    callback && callback(r)}, (e) => {
    reject && reject(e)})
}

const deleteImage = (filename, imageNumber = 1, specificPartner, callback, reject) =>
  deleteImageMethod('api/seller/image', filename, specificPartner, callback, reject, imageNumber)

const deleteImageMethod = (url, filename, specificPartner, callback, reject, imageNumber) => {
  const id = customer();

  const headers = new Headers();

  const partner = specificPartner ? `&partner=${Context.partner.sponsor}` : '';

  authCall(`${url}?customerId=${id}&filename=${filename}${partner}&imageNumber=${imageNumber}`, {
    method: 'DELETE',
    headers,
  }, (r) => {
    callback && callback(r)}, (e) => {
    reject && reject(e)})
}

const uploadClientMethod = async (url, file, fileName, callback, reject) => {
  const id = customer();

  const formData = new FormData();
  formData.append('file', file, fileName);
  formData.append('name', fileName);
  formData.append('customerId', customer());

  const headers = new Headers();
  headers.append("CIBLER_AUTH", siteContext().clientToken);

  return await authClient(`${url}?customerId=${id}`, {
    method: 'POST',
    headers,
    body: formData
  }, (r) => {
    callback && callback(r)}, (e) => {
    reject && reject(e)})
}

export { logToOauth,ajaxCall,
  getUserData, postUserData,
  authClient, authCall,
  addressGeocoding, reverseGeocoding,
  createUser, ACtrackEvent,
  resetPassword, setNewPassword,
  uploadImage, uploadImageMethod,
  deleteImage, deleteImageMethod,
  uploadClientMethod,
  getSeller,loginWithUserPhone,
  validatePhoneCode, postMember,
  getIsMember, checkUserData,
  getAccount, getCustomerConfig,
  reverseLogin,checkProfileIsIncomplete,sendRegisterInfo
};