import React, {useEffect, useState} from "react";
import '../../../style/course.scss';
import CourseHeader from "../../../components/Course/Header";
import CourseFooter from "../../../components/Course/Footer";
import {authClient, getUserData} from "../../../tools/apitools";
import {t} from '../../../tools/i18n';
import RaceStatus from "../../../components/Course/RaceStatus";
import Loader from "../../../components/Loader";
import Context from "../../../tools/context";
import {siteContext} from "../../../tools/toolbox";
import {OctogoneCourse} from "../../../components/Picto/Course/Octogone";

export default function Course() {
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingSellers, setLoadingSellers] = useState(true);
  const [loadingGifts, setLoadingGifts] = useState(true);
  const [user, setUser] = useState({});
  const [sellers, setSellers] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [error, setError] = useState("");

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("CIBLER_AUTH", siteContext().clientToken);

  useEffect(() => {
    const keys = ['firstname', 'name'];

    setLoadingUser(true);
    getUserData(keys,
      (res) => {
        setLoadingUser(false);
        setUser(res)
      },
      (e) => {
        setLoadingUser(false);
        setError(e)
      });
  }, []);

  useEffect(() => {
    const getSeller = (shortcode) => {
      return authClient(
        `api/public/seller/data/${shortcode.toUpperCase()}`,
        { headers: headers },
        (res) => res,
        (e) => console.error(e)
      );
    }

    const onGetTargetsSuccess = (res) => {
      const promises = res.map(shortcode => getSeller(shortcode));
      Promise.all(promises)
        .then((data) => {
          setSellers(data);
          setLoadingSellers(false);
        });
    }

    const getSellers = () => {
      setLoadingSellers(true);

      try {
        authClient(
          `api/targetDatas/campaign/${Context.partner?.course?.campaignId}`,
          { headers: headers },
          (res) => onGetTargetsSuccess(res),
          (e) => {
            setError(e);
            setLoadingSellers(false);
          }
        );
      } catch (err) {
        console.error(err)
      }
    }

    getSellers()
  }, []);

  useEffect(() => {
    document.title = t('course.metadata.title');
  }, []);

  useEffect(() => {

    const onGetGiftResponse = (res) => {
      setGifts(res.filter(ticket => ticket.used).sort((a, b) => new Date(b.burnDate) - new Date(a.burnDate)));
      setLoadingGifts(false);
    }

    const getGifts = async () => {
      setLoadingGifts(true);

      try {
        authClient(
          `api/bienvenus/user/ticket?customer=${Context.partner.customerId}`,
          { method: 'GET', headers: headers },
          onGetGiftResponse,
          (err) => {
            console.error('Either customerId is incorrect or partner is not correctly set', err);
            setLoadingGifts(false);
          }
        );
      } catch (err) {
        console.error(err);
      }
    }

    getGifts();
  }, []);

  const Seller = ({seller, burnDate}) => {
    if (!seller)
      return null;

    const sellerImage = seller.data?.find(data => data.key === 'store_image')?.value ||
      seller.data?.find(data => data.key === 'store_image_2')?.value ||
      seller.data?.find(data => data.key === 'store_image_3')?.value;
    const sellerCity = seller.data?.find(data => data.key === 'city')?.value;
    const sellerDepartment = seller.data?.find(data => data.key === 'department')?.value;
    const visitedDate = burnDate ? new Date(burnDate) : null;
    const visited = visitedDate && visitedDate < new Date();

    return (
      <div className={`CourseSeller ${visited ? 'visited' : ''}`}>
        <div className="courseSeller__image-container">
          <div className="courseSeller__seller-image">
            <OctogoneCourse id="octogone" />
            {sellerImage
              ? <img src={sellerImage} alt="Seller image"/>
              : <div className="seller__image_placeholder" />
            }
          </div>
        </div>
        <div className="courseSeller__seller-infos">
          <h2>{seller.name?.toUpperCase()}</h2>
          {sellerCity ? <p>{sellerCity}</p> : null}
          {!!!sellerCity && sellerDepartment ? <p>{sellerDepartment}</p> : null}
          {visitedDate
            ? <p><i>{t('transactions.visited')} {visitedDate.toLocaleDateString()}</i></p>
            : null
          }
        </div>
      </div>
    )
  }

  if (loadingUser || loadingSellers || loadingGifts)
    return (
      <Loader
        overrideBackgroundColor={"var(--courseBackgroundColor"}
        overrideSpinnerColor={"var(--courseSecondaryBackgroundColor)"}
      />
    );

  const sellersVisitedCount = sellers.filter(s => gifts.find(g => g.sellerRecipientId === s.shortcode)).length;

  return (
    <div className="Course">
      <div className="course__wrapper">
        <CourseHeader />
        <div className="course__container">
          <div className="course__header">
            <h1><i>{t('course.page.hello')}</i></h1>
            <h1><strong>{user.firstname}</strong></h1>
          </div>
          <RaceStatus current={sellersVisitedCount} count={sellers?.length} />
          <div className="course__content" dangerouslySetInnerHTML={{__html: t('course.page.content')}} />
          <div className="course__list">
            {sellers
              ?.sort((a, b) => {
                const visitedA = gifts.find(g => g.sellerRecipientId === a.shortcode);
                const visitedB = gifts.find(g => g.sellerRecipientId === b.shortcode);
                const dateA = gifts.find(g => g.sellerRecipientId === a.shortcode)?.burnDate;
                const dateB = gifts.find(g => g.sellerRecipientId === b.shortcode)?.burnDate;
                return visitedA && visitedB
                  ? new Date(dateB) - new Date(dateA)
                  : visitedA
                    ? -1
                    : visitedB
                      ? 1
                      : 0;
              })
              ?.map((seller, index) => (
                <Seller
                  key={index}
                  seller={seller}
                  burnDate={gifts.find(g => g.sellerRecipientId === seller?.shortcode)?.burnDate}
                />
              ))
            }
          </div>
        </div>
        <CourseFooter />
      </div>
    </div>
  );
};
