import React from "react";

export const OctogoneCourse = (props) => (
  <svg
    width={props.width || "100%"}
    height={props.height || "100%"}
    viewBox="0 0 200 219"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="shape">
        <path
          d="M89.2551 15.7915L21.6114 54.1676C16.2889 57.1872 13 62.8344 13 68.9538V148.736C13 155.099 16.554 160.93 22.2102 163.846L92.2465 199.952C97.1685 202.49 103.018 202.471 107.924 199.902L172.627 166.014C175.363 164.581 177.657 162.431 179.262 159.793L181.522 156.08C183.143 153.417 184 150.359 184 147.242V70.4629C184 64.379 180.749 58.7589 175.475 55.726L106.119 15.8408C100.902 12.841 94.4889 12.8223 89.2551 15.7915Z"
          fill="black"
        />
      </clipPath>
    </defs>
    <path
      d="M100.017 16.5415C103.449 16.5415 106.848 17.4512 109.813 19.155L173.559 55.6951C179.607 59.1522 183.356 65.62 183.356 72.5508V145.631C183.356 152.562 179.607 159.013 173.559 162.487L109.813 199.027C106.831 200.731 103.449 201.64 100.017 201.64C96.5845 201.64 93.1856 200.731 90.2199 199.027L26.4745 162.487C20.4265 159.03 16.6778 152.562 16.6778 145.631V72.5508C16.6778 65.62 20.4265 59.1688 26.4745 55.6951L90.2199 19.155C93.2023 17.4512 96.5845 16.5415 100.017 16.5415ZM100.017 0C93.7521 0 87.4875 1.60452 81.8894 4.83011L18.1273 41.3702C6.91436 47.8048 0 59.6816 0 72.5508V145.631C0 158.5 6.91436 170.377 18.1273 176.812L81.8727 213.352C87.4875 216.561 93.7354 218.182 100 218.182C106.265 218.182 112.529 216.577 118.127 213.352L181.873 176.812C193.086 170.377 200 158.5 200 145.631V72.5508C200 59.6816 193.086 47.8048 181.873 41.3702L118.144 4.83011C112.529 1.62106 106.281 0 100.017 0Z"
      fill={props.color || "white"}
    />
  </svg>
);
