import React, {Component} from 'react';
import {clientT} from '../../tools/i18n';

export default class ImportField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      fileSrc: props.value || '',
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value && typeof this.props.value === 'string') {
      this.setState({fileSrc: this.props.value})
    }
  }

  deleteImage = () => {
    this.setState({file: null, fileSrc: ''});
    document.getElementById(this.props.id).value = "";
    this.props.handleChange({
      target: {
        id: this.props.id,
        value: null,
      }
    })
  }

  onFileDropped = (e) => {
    if (!e.target.files || !e.target.files.length) return false;
    const file = e.target.files[0];
    if (this.props.maxSize && file.size > this.props.maxSize ) {
      document.getElementById(this.props.id).value = "";
      this.setState({errorMsg: clientT('form.customErrors.userdocument', {maxSize: this.props.maxSize/1000000})});
    }else{
      this.setState({errorMsg: null});
    }
    const fileSrc = window.URL.createObjectURL(file);
    this.setState({file, fileName: file.name, fileSrc})

    this.props.handleChange({
      target: {
        id: e.target.id,
        value: file,
      }
    })
  }

  render() {
    const {
      id, label, subLabel, value,
      optional, accept, showPreview
    } = this.props;

    let fileName = null;
    if(value?.split) {
      const sp = value.split('/');
      fileName = sp[sp.length-1];
    } else if (value?.name) {
      fileName = value.name;
    }

    return (
      <p>
        {label ?
          <label htmlFor={id}>
            {label}{!optional ? <span
            className="required">*</span> : null} <span
            className="subLabel">{subLabel}</span>
          </label> : null}
        {
          fileName && !showPreview && <div>{fileName}</div>
        }
        <div className={"import-file"}>
          <input
            id={id}
            required={!optional && !fileName}
            type="file"
            accept={accept}
            onChange={this.onFileDropped}
          />
          {this.state.fileSrc ? <button onClick={this.deleteImage}>X</button> : null}
          {this.state.errorMsg && <div className="error">{this.state.errorMsg}</div>}
        </div>
        {
          this.state.fileSrc && showPreview &&
            <img
              onError={() => this.setState({fileSrc: ''})}
              src={this.state.fileSrc || value}
            />
        }
      </p>
    )
  }
}