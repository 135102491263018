import React, {useState} from "react";
import "./index.scss";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";

export default function Coupon({
  coupon,
}) {
  const data = coupon?.campaignData?.map(d => ({[d.key]: d.value})).reduce((acc, curr) => ({...acc, ...curr}), {}) || {};
  const [background, setBackground] = useState(data.background || "");
  const [step, setStep] = useState(1);
  const [codeCopied, setCodeCopied] = useState(false);
  const code = data?.useGenericCode === 'true' ? coupon.genericCode : coupon.prize;

  const height = () => {
    if (!data.height) return 'auto';

    return data.height;
  }

  const handleClick = () => {
    console.log("handleClick")
  }

  const redirectUrl = data?.redirect_url?.replace('{{code}}', code);

  const CTAStyle = {
    backgroundColor: data.textColor || '',
    border: 'none',
    color: data.bgColor || '',
  }

  const CopyButton = ({text}) => {
    const copy = () => {
      navigator.clipboard.writeText(text);
      setCodeCopied(true);
    }

    return (
      <button style={{...CTAStyle, background: 'transparent'}} onClick={copy}>
        <ContentCopyIcon color={data.textColor} />
      </button>
    );
  }

  return (
    <div
      className="Coupon"
       style={{
         backgroundImage: `url("${background}")`,
         backgroundColor: data.bgColor || '',
         borderColor: data.borderColor || '',
         height: height(),
         color: data.textColor || '',
       }}
       onClick={handleClick}
    >
      {step === 1
        ? data.condition
          ? <div className="step1-content" dangerouslySetInnerHTML={{__html: data.condition}} />
          : null
        : <div className="step2-content">
            {data.condition2
              ? <div dangerouslySetInnerHTML={{__html: data.condition2}} />
              : null
            }
            <div className="copy-container">
              <span>{codeCopied ? "Code copié" : "Copier le code"} : <strong>{code}</strong></span>
              <CopyButton text={code} />
            </div>
          </div>
      }
      {step === 1
        ? <button
            style={CTAStyle}
            onClick={() => setStep(2)}
          >
            {data.buttonText1 || "Récupérer"}
          </button>
        : redirectUrl
          ? <a
              style={CTAStyle}
              href={redirectUrl}
              target="_blank"
            >
              {data.buttonText2 || "Découvrir"}
            </a>
          : null
      }
    </div>
  );
};
