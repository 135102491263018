import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";
import '../../style/landingHeader.scss'
import {Context} from "../../tools/context";
import Logo from '../../components/Logo';
import LogoNegative from '../../components/LogoNegative';
import AppLink from "../../components/AppLink";
import Image from "../../components/Image";
import {clientT} from "../../tools/i18n";
import LanguageSelector from "../../components/LanguageSelector";
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {Picto} from "../../components/Picto";

const Header = ({isLaunchDate, backButton}) => {
  const [showMenu, setShowMenu] = React.useState(false);

  const tiCoLogo = Context.partner.platform === 'ticketcommercant'
    && <Image className="ticoLogo" src={`assets/ticket_co_${Context.partner.sponsor}.png`} />

  const links = Context.partner?.landing?.landingHeaderLinks?.filter
    ? Context.partner?.landing?.landingHeaderLinks.filter(t => t.key !== 'landingClient' || !isLaunchDate)
    : null;

  const menuOnly = Context.partner?.landing?.menuOnly

  return (
    <>
      <header className="landingheader">
        {backButton &&
          <Link to={backButton.link || "/"} className={"backButton"}>
            <FontAwesomeIcon icon={faArrowLeft} />
            {backButton.label || ""}
          </Link>
        }
        <div className="logos">
          {tiCoLogo}
          <Logo className="logo color"/>
          <LogoNegative className="logo negative"/>
        </div>
        {links && links.length && (
          <div className={`links ${menuOnly ? 'menuOnly' : ''}`}>
            <div className="hbg-menu" onClick={() => setShowMenu(!showMenu)}>
              <FontAwesomeIcon className="hbg-icon" icon="bars"/>
            </div>
            <nav className="navbar_bigScreen">
              {links.map((item, i) => {
                return <AppLink key={i} href={item.path}>{
                  (item.icon && <img src={item.icon} alt={item.label} width={200} />) ||
                  clientT(`menu.${item.key}`, {fallback: item.label})
                }</AppLink>
              })
              }
            </nav>
          </div>
        )}
        <LanguageSelector />
      </header>
      {showMenu && (
        <nav className="navbar_smallScreen">
          <button className="close" onClick={() => setShowMenu(false)}>
            <Picto iconKey={"Cross"} />
          </button>
          {links.map((item, i) => {
            return <AppLink key={i} href={item.path}>{
              clientT(`menu.${item.key}`, {fallback: item.label})
            }</AppLink>
          })
          }
        </nav>
      )}
    </>
  );
};

export default Header;
