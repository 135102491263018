import React, {Component, useState} from 'react';
import {flatten} from 'lodash';
import Context from "../../tools/context";
import FormField from "../../components/formField";
import {fields} from '../../tools/formFieldsDatabase';
import MapStep from "./MapStep";
import StepButtons from "./StepButtons";
import {customer, siteContext} from "../../tools/toolbox";
import {clientT} from "../../tools/i18n";
import AppButton from "../../components/AppButton";

export default ({
                  history,
                  ...props
                }) => {

  return (
      <div className='registerConfirmation'>
        <div className="title"
             dangerouslySetInnerHTML={{__html: clientT('registerV2.confirmation.title')}}
        />
        <div className="subTitle"
             dangerouslySetInnerHTML={{__html: clientT('registerV2.confirmation.subTitle')}}
        />
        <AppButton
          className="addProduct"
          onClick={() => history.replace('/addproduct')}
        >
          {clientT('registerV2.confirmation.addProduct')}
        </AppButton>
        <AppButton
          className="later"
          onClick={() => history.replace('/home')}
        >
          {clientT('registerV2.confirmation.later')}
        </AppButton>
      </div>
  )
}