import React from "react";

export const SparkBottom = (props) => (
  <svg width={props.width || "36"} height={props.height || "30"} viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.7853 27.863C9.1055 26.8904 7.68905 25.5946 6.39422 24.1824C5.1197 22.7649 3.94651 21.2362 3.07774 19.544C2.31103 17.9958 4.30897 16.5023 5.59793 17.6393C6.96838 18.8405 8.19174 20.1434 9.43542 21.4411C10.6639 22.764 11.8873 24.0669 12.9435 25.5619C13.908 26.9527 12.2563 28.6977 10.7801 27.843L10.7853 27.863Z"
        fill={props.color || "white"}
      />
      <path
        d="M13.6299 4.41719C17.4182 5.25144 21.0607 6.61285 24.6121 8.12541C25.6603 8.57937 27.1002 9.20921 28.1384 9.70833C29.6955 10.457 31.2173 11.2361 32.6934 12.0908C33.4367 12.5381 33.6833 13.4964 33.2433 14.227C32.8488 14.882 32.0518 15.151 31.3599 14.9034C29.7474 14.3605 28.1653 13.7673 26.5831 13.174C21.8467 11.3492 17.1003 9.56946 12.5406 7.33722C10.912 6.47921 11.84 4.02645 13.6299 4.41719Z"
        fill={props.color || "white"}
      />
  </svg>
);
