import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import "../style/register.scss";
import "../style/constant.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapGL, { Marker } from "react-map-gl";
import Context from "../tools/context";
import {isFirstTimeLogin, markFirstTimeLoginDone, siteContext} from "../tools/toolbox";
import {fields, getFields, getCompletionFields, getCheckboxes, getFlatFieldKeys} from '../tools/formFieldsDatabase'
import {
  addressGeocoding,
  reverseGeocoding,
  authCall, uploadImage, deleteImage,
} from "../tools/apitools";
import Loader from "../components/Loader";
import Logo from "../components/Logo";
import FormField from "../components/formField/";
import {getQueryString} from "../tools/toolbox";
import AppButton from "../components/AppButton";
import {formatDate} from "../Utils/dateUtils";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.userId = props.match && props.match.params ? props.match.params.id : null;
    this.completion = getQueryString('completion');
    this.completeInfos = getQueryString('completeInfos');
    this.fields = {};

    if (this.completion) {
      this.fields = getCompletionFields();
      if (!Object.keys(this.fields).length) {
        this.fields = getFields('profile');
        this.completion = false;
      }
    } else {
      this.fields = getFields('profile');
    }

    this.errorMessages = {
      errorAlert: "Merci de corriger les erreurs signalées en rouge",
      emailErrorMessage: "Compte existant",
      passwordError: "Les deux mots de passe ne correspondent pas",
      invalidAddressMessage:
        "Attention adresse inexistante merci de vérifier adresse et code postal avant de confirmer",
      phoneError: 'Le numero de téléphone n\'est pas valide',
      nafError: 'Le code NAF est composé de 4 chiffres suivis d\'une lettre'
    };

    this.mapParams = {
      width: 300,
      height: 300,
    };

    this.state = {
      formData: {},
      //Error messages
      message: '',
      errorMessage: '',
      PasswordError: '',
      EmailError: '',
      //Loader on/off
      loaderOn: false,
      loaderClassName: "hide",
      //mapParams
      zoom: 15,
      showMap: false,
      validAddress: false,
      longitude: 5.4,
      latitude: 43.3,
    };
  }

  componentDidMount() {
    // document.title = 'Completez ou modifiez vos données personnelles.';
    authCall(`api/seller/${this.userId ? '?sellerid='+this.userId : ''}`, {},
      (result) => {
        if(result && Array.isArray(result)){
          //Get current seller from list
          result = result[0]
        }
        let formated = {}

        result.data.forEach(d => {
          formated[d.key] = d.value === 'false' ? false : d.value === 'true' ? true : d.value;
        })

        formated = {...formated, ...result};
        const state = {
          ...this.state,
          formData: {
            ...formated,
            address: formated.street,
            contactPhone: {
              code: formated.contactPhoneCode || '33',
              number: formated.contactPhone || '',
              valid: true,
            },
            ownerPhone: {
              code: formated.ownerPhoneCode || '33',
              number: formated.ownerPhone || '',
              valid: true,
            },
          },
          longitude: formated.longitude,
          latitude: formated.latitude,
        }

        state.formData?.data.forEach(k => k.value ? state.formData[k.key] = k.value : null);

        if(this.completeInfos) {
          const tempField1 = {};
          Object.keys(this.fields).forEach(k => tempField1[k] = this.fields[k].filter(f => !state.formData[f.id]
            && f.id !== 'image' && f.id !== 'image2' && f.id !== 'image3' && f.id !== 'password1' && f.id !== 'password2' && f.id !== 'loginEmail'));
          const tempField2 = {};
          Object.keys(this.fields).filter(ke => tempField1[ke].length).forEach(k => tempField2[k] = tempField1[k]);

          this.fields = tempField2;
        }

        this.setState({...state});

        this.previousDatas = formated;
      }, () => this.setState({
        errorMessage: 'Une erreur est survenue'
      }))
  }

  renderMap = () => {
    return (
      <div className="mapContainer">
        <div className="mapWrapper">
          <div>
            <div className="mapQuestion">
              <p> Voici l'adresse indiquée.</p>
              <p>La position est-elle correcte?</p>
            </div>
          </div>
          <ReactMapGL
            width={this.mapParams.width}
            height={this.mapParams.height}
            zoom={this.state.zoom}
            longitude={this.state.longitude}
            latitude={this.state.latitude}
            mapStyle={'mapbox://styles/mapbox/streets-v11'}
            mapboxApiAccessToken="pk.eyJ1IjoibHZlcmRvbmsiLCJhIjoiY2s4OHY0b2llMDBkeTNpbXBhNmRnMmU3dCJ9.adHYqa4eOwGNKzBR9EJB7A"
            onViewportChange={(nextViewport) => {
              this.setState(nextViewport);
            }}
          >
            <Marker
              longitude={this.state.longitude}
              latitude={this.state.latitude}
            >
              <img
                className="mapMarker"
                alt="mapmarker"
                src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22%2368737d%22%3E%20%20%3Cdefs%2F%3E%20%20%3Cpath%20d%3D%22M%204%2017%20C%204%2022%2010%2028%2015%2028%20V%2032%20H%2017%20V%2028%20C%2022%2028%2028%2022%2028%2017%20H%2032%20V%2015%20H%2028%20C%2028%2010%2022%204%2017%204%20V%200%20H%2015%20V%204%20C%2010%204%204%2010%204%2015%20H%200%20V%2017%20H%2012%20V%2015%20H%207%20C%207%2011%2011%207%2015%207%20V%2012%20H%2017%20V%207%20C%2021%207%2025%2011%2025%2015%20C%2025%2015%2025%2015%2025%2015%20H%2020%20V%2017%20H%2025%20C%2025%2021%2021%2025%2017%2025%20V%2020%20H%2015%20V%2025%20C%2011%2025%207%2021%207%2017%20Z%22%2F%3E%3C%2Fsvg%3E"
              />
            </Marker>
          </ReactMapGL>
          <form onSubmit={this.handleCreateUser}>
            <div className="mapInputs">
              <input
                required
                placeholder="adresse"
                id="address"
                value={this.state.formData.address}
                onChange={this.handleMapInputs}
              />
              <input
                required
                placeholder="Code postal"
                id="zipcode"
                value={this.state.formData.zipcode}
                onChange={this.handleMapInputs}
                minLength="5"
                maxLength="5"
              />
              <input
                required
                placeholder="Ville"
                id="city"
                value={this.state.formData.city}
                onChange={this.handleMapInputs}
              />
            </div>
            <div className="mapButtons">
              <AppButton className="button button2"
                style={{fontSize: '14px'}}
                event="Cancel profile map"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    showMap: false,
                  });
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                className={`button button1 ${this.state.validAddress ? '' : 'disabled'}`}
                style={{fontSize: '14px'}}
                type="submit"
                disabled={!this.state.validAddress}
                event="Confirm profile map"
              >
                Confirmer
              </AppButton>
            </div>
          </form>
        </div>
      </div>
    );
  };

  handleDeleteImage = (id) => {
    switch (id) {
      case 'image':
        deleteImage(this.state.formData.id);
        break;
      case 'image2':
        deleteImage(this.state.formData.id, 2);
        break;
      case 'image3':
        deleteImage(this.state.formData.id, 3);
        break;
      case 'imagepartner':
        deleteImage(this.state.formData.id, 1, true);
        break;
      default:
        break;
    }
  }

  handleChange = (e) => {
    if (e.target.id.startsWith('image'))
      this.handleDeleteImage(e.target.id);
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [e.target.id]: e.target.value,
      }
    }));
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const {formData} = this.state;

    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (
      !window.location.pathname?.toLowerCase().includes("adminpage")
      && getFlatFieldKeys('profile').includes('contactPhone')
      && (!formData?.contactPhone?.valid || !formData.contactPhone.number)
    ) {
      this.setState({
        contactPhoneError:
        this.errorMessages.phoneError,
      });
      document
        .querySelector('label[for=contactPhone]')
        .scrollIntoView({ behavior: 'smooth' });
      return false;
    } else {
      this.setState({
        contactPhoneError: null,
      });
    }


    if (
      !window.location.pathname?.toLowerCase().includes("adminpage")
      && (!formData?.ownerPhone?.valid || !formData.ownerPhone?.number)
    ) {
      this.setState({
        ownerPhoneError:
        this.errorMessages.phoneError,
      });
      document
        .querySelector('label[for=ownerPhone]')
        .scrollIntoView({ behavior: 'smooth' });
      return false;
    } else {
      this.setState({
        ownerPhoneError: null,
      });
    }

    if (
      !window.location.pathname?.toLowerCase().includes("adminpage") && (
      this.previousDatas.street !== formData.address ||
      this.previousDatas.zipcode !== formData.zipcode ||
      this.previousDatas.city !== formData.city ||
      isNaN(this.state.longitude) || isNaN(this.state.latitude))
    ) {
      this.setState({
        showMap: true,
      });
      return false
    }

    this.handleCreateUser(e);
  };

  handleMapInputs = async (e) => {
    if (e) {
      e.preventDefault && e.preventDefault();
      await this.handleChange(e);
    }
    console.log(this.state.formData)
    if (
      this.state.formData.address?.length > 2
      && this.state.formData.zipcode
      && this.state.formData.city
    ) {
      const address = this.state.formData.address + ' ' +
        this.state.formData.zipcode + ' ' +
        this.state.formData.city;
      addressGeocoding(address).then((data) => {
        if (data?.results?.length) {
          const {lat, lng} = data.results[0].geometry.location;
          this.setState(prevState => ({
            longitude: lng,
            latitude: lat,
            validAddress: true,
          }));
        } else {
          console.log(data?.results?.length)
          this.setState({
            validAddress: false,
          });
        }
      }).catch(() => {this.setState({validAddress: false})});
    }
  };

  handleCreateUser = (e) => {
    e.preventDefault();

    const {formData} = this.state;

    const bodyKey = [
      'contactEmail','registrationNumber',
      'category','name',
      'shortcode','tag', 'ownerEmail'];

    const excludeKey = ['password1', 'password2',
      'data', 'shortcode', 'address', 'image', 'image2', 'image3', 'contactPhone', 'ownerPhone'];

    const emptiedField = bodyKey.find(f => this.previousDatas[f] && !formData[f]);

    if (emptiedField) {
      window.scrollTo(0,0);
      this.setState({
        errorMessage: `La valeur du champ "${
          fields()[emptiedField].label
        }" ne peut pas être supprimée.`
      })

      return;
    }

    bodyKey.push('iban');

    let productDate = null;
    if (Context.partner && Context.partner.sponsor === 'passprovence' && formData.productDate) {
      productDate = formatDate(formData.productDate);
    }

    const body = {
      id: formData.id,
      address: `${formData.address};${formData.city};${formData.zipcode}`,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      contactPhone: formData.contactPhone?.number,
      ownerPhone: formData.ownerPhone?.number,
      contactPhoneCode: formData.contactPhone?.code,
      ownerPhoneCode: formData.ownerPhone?.code,
      sellerData: {
        street: formData.address,
        // Only add productDate to sellerData if it's defined (i.e., sponsor is 'passprovence' and formData.productDate exists)
        ...(productDate && { productDate: productDate })
      }
    };

    Object.keys(formData).forEach(k => {
      if (bodyKey.includes(k)) {
        body[k] = formData[k];
        return;
      }

      if (formData[k] === this.previousDatas[k])
        return;

      if (!body[k] && !body.sellerData[k]
        && !excludeKey.includes(k)
      ) {
        body.sellerData[k] = formData[k];
      }
    })

    const randomImageVersion = Math.floor(Math.random() * 1000000);

    if (this.state.formData.image) {
      body.sellerData['store_image'] = `https://cdn.cibler.io/static/seller/${this.userId || Context.seller.id}/store.jpg?v=${randomImageVersion}`;
    }

    if (this.state.formData.image2) {
      body.sellerData['store_image_2'] = `https://cdn.cibler.io/static/seller/${this.userId || Context.seller.id}/store_2.jpg?v=${randomImageVersion}`;
    }

    if (this.state.formData.image3) {
      body.sellerData['store_image_3'] = `https://cdn.cibler.io/static/seller/${this.userId || Context.seller.id}/store_3.jpg?v=${randomImageVersion}`;
    }

    this.setState({
      loaderOn: true,
      showMap: false,
    });
    authCall(`api/seller/`, {
      method: "PUT",
      body: JSON.stringify(body),
    }, () => {
      window.scrollTo(0,0);
      this.setState({
        loaderOn: false,
        message: 'Enregistrement terminé',
        errorMessage: '',
      });
      if (this.completeInfos) {
        if (Context.partner.firstLoginRedirectToOffer && isFirstTimeLogin()) {
          markFirstTimeLoginDone();
          this.props.history.replace('addproduct');
        } else {
          this.props.history.replace('home')
        }
      }
    }, () => {
      window.scrollTo(0,0);
        this.setState({
          message: '',
          errorMessage: 'Une erreur est survenue pendant l\'enregistrement',
          loaderOn: false,
        });
    })

    if (formData.image) {
      uploadImage(formData.image, formData.id)
    }
    if (formData.image2) {
      uploadImage(formData.image2, formData.id, 2)
    }
    if (formData.image3) {
      uploadImage(formData.image3, formData.id, 3)
    }
    if (formData.imagepartner) {
      uploadImage(formData.imagepartner, formData.id, 1, true)
    }
  };

  render() {
    let partnerName;
    switch (Context.partner.sponsor) {
      case "stbrieucarmoragglo":
        partnerName = "Saint Brieuc Armor Agglomération";
        break;
      case "lavalagglo":
        partnerName = "Laval Agglomération";
    }

    const background = Context.partner.profile && Context.partner.profile.customBackground
      ? {
        background: `url('/assets/${Context.partner.sponsor}_register_background.jpg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'right',
      } : null;

    return (
      <>
        <div
          className="RegisterPage_container"
          style={background}
        >
          {this.state.loaderOn ? <Loader /> : null}
          <div className="Content_wrapper">
            {/* FORM START */}
            <div className={this.state.loaderOn === false ? "hide" : "loaderOn"}>
              <Loader />
            </div>
            <div className="signUpPage_wrapper">
              <div className="signUpPage_Header">
              {this.completion ?
                  <div>
                    Completez les champs suivants pour finaliser votre inscription.
                  </div>
                  : <div>
                    Vous pouvez mettre à jour vos informations.
                  </div>}
              </div>
              {this.state.errorMessage ?
                <div className="register_error">
                  {this.state.errorMessage}
                </div>
                : null}
              {this.state.message ?
                <div className="register_message">
                  {this.state.message}
                </div>
                : null}
              <form onSubmit={this.handleFormSubmit} className="formWrapper">
                {Object.keys(this.fields).map(k => (
                  <div key={k}>
                    {k !== 'oneGroup' ?
                      <div className="formSubTitle">{k}</div>
                      : null}
                    {this.fields[k]
                      .map(f => <FormField
                      {...f}
                      key={f.id}
                      optional={
                        window.location.pathname?.toLowerCase().includes("adminpage")
                          ? true
                          : this.completeInfos
                            ? f.optional
                            : true
                      }
                      disabled={f.id === 'loginEmail' || f.disabled}
                      value={
                        f.id === 'image'
                          ? `https://cdn.cibler.io/static/seller/${this.userId || Context.seller?.id}/store.jpg`
                          : f.id === 'image2'
                            ? `https://cdn.cibler.io/static/seller/${this.userId || Context.seller?.id}/store_2.jpg`
                            : f.id === 'image3'
                              ? `https://cdn.cibler.io/static/seller/${this.userId || Context.seller?.id}/store_3.jpg`
                              : this.state.formData?.[f?.id]
                      }
                      errorMsg={f.errorMsgKey ? this.state[f.errorMsgKey] : null}
                      handleChange={f.id === 'address' || f.id === 'zipcode' || f.id === 'city'
                        ? this.handleMapInputs
                        : this.handleChange}
                    />)}
                  </div>
                ))}
                {window.location.pathname?.toLowerCase().includes("adminpage")
                  ? Object.keys(this.state.formData)
                      .filter(k => !getFlatFieldKeys('profile').includes(k) && k !== 'data')
                      ?.map(k => (
                        <FormField
                          id={k}
                          key={k}
                          optional
                          label={k}
                          value={this.state.formData[k]}
                          handleChange={this.handleChange}
                        />
                      ))
                  : null
                }
                {this.userId
                && Context.partner.profile
                && Context.partner.profile.adminFields
                ?
                  <div key="adminFields">
                    <div className="formSubTitle">Champs administrateur</div>
                    {Context.partner.profile.adminFields
                      .map(f => <FormField
                        {...f}
                        key={f.id}
                        optional
                        value={this.state.formData[f.id]}
                        errorMsg={f.errorMsgKey ? this.state[f.errorMsgKey] : null}
                        handleChange={this.handleChange}
                      />)}
                  </div>
                : null}
                {this.completion ? null : getCheckboxes(true).map(f => <FormField
                  {...f}
                  key={f.id}
                  type="checkbox"
                  optional={window.location.pathname?.toLowerCase().includes("adminpage") || f.optional}
                  value={this.state.formData[f.id]}
                  handleChange={this.handleChange}
                />)}
                {Context.partner && Context.partner.sponsor === 'passprovence' &&
                  <div className="signUpcheckboxes">
                    <label style={{margin: "5px 0 0 0", textAlign: "center"}}>
                      Visualiser la convention en cliquant <a target="_blank" href="/documents/CGU_tickets_myprovence.pdf">ici</a>
                    </label>
                  </div>
                }
                <AppButton
                  className="button button2"
                  event="Update seller profile"
                  type="submit">
                  Enregistrer
                </AppButton>
              </form>
            </div>
          </div>
          {this.state.showMap && this.renderMap()}
        </div>
      </>
    );
  }
}

export default withRouter(Profile);
