import React, {Component} from "react";
import "../style/semantic/semantic.scss"
import "../style/addProduct.scss";
import AddProductHome from "../containers/Product/AddProductHome";
import {AddProductSuccess} from "../containers/Product/AddProductSuccess";
import {AddProductInfo} from "../containers/Product/AddProductInfo";
import {authCall, uploadImageMethod} from "../tools/apitools";
import {Context} from "../tools/context";
import {withRouter} from "react-router-dom";
import {clientT, t} from "../tools/i18n";
import {getPartnerDenomination} from "../tools/toolbox";
import TicketsInfoPopup from "../components/Popup/TicketsPopup";
import AppLink from "../components/AppLink";
import moment from "moment";

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {},
      loading: true,
    };
  }

  componentDidMount() {
    const productParam = new URLSearchParams(window.location.search);
    if (productParam.has("product_id")) {
      this.productId = productParam.get("product_id");
      this.getProduct(this.productId);
    } else {
      this.setState({loading: false});
    }
  }

  getProduct = (productId) => {
    authCall(
      `api/seller/offers/${productId}`,
      {},
      (result) => {
        if (result) {
          this.setState({
            initialData: {
              ...result,
              offerType: result.discountType === 'GWP' ? 'GWP' : 'VALUE',
              discountType: result.discountType === 'GWP' ? 'VALUE' : result.discountType,
            },
            loading: false,
          });
        } else {
          this.setState({loading: false});
        }
      },
      () => {
        this.setState({loading: false});
      }
    );
  };

  handleSubmit = (gift, data) => {
    const giftData = {
      sellerId: Context.seller && Context.seller.id,
      sellerName:
        Context.seller && Context.seller.name && Context.seller.name,
      giftDTO: {
        ...gift,
        name: gift.description.toUpperCase(),
        discountType: gift.offerType === 'GWP'
          ? 'GWP'
          : gift.discountType,
      },
      data,
    };

    authCall(
      "api/seller/offers",
      {
        method: this.productId ? "PUT" : "POST",
        body: JSON.stringify(giftData),
      },
      (result) => {
        this.setState({
          showPopup: true,
          initialData: {
            ...result,
            offerType: result.discountType === 'GWP' ? 'GWP' : 'VALUE',
            discountType: result.discountType === 'GWP' ? 'VALUE' : result.discountType,
          },
        })
      },
      (e) => {
        console.error(e);
      }
    );
  }

  render() {
    const {
      showPopup,
      initialData,
      loading,
    } = this.state;

    let expirationDate;
    if (initialData.expirationDate) {
      expirationDate = moment(new Date(initialData.expirationDate)).format('DD/MM/YYYY');
    }

    let conditions;
    if (initialData.giftData) {
      const data = initialData.giftData.find(d => d.key === 'conditions');
      if (data) {
        conditions = data.value;
      }
    }
    return (
      <div id="page-container">
        {!loading &&
        <AddProductHome
          submit={this.handleSubmit}
          initialData={initialData}
        />
        }
        {showPopup && <TicketsInfoPopup
          bottomButtons={[
            {label: t('common.close'), action: () => this.setState({showPopup: false})},
            {label: t('addProduct.goBack'), action: () => this.props.history.push('mes-avantages')},
          ]}
          type="custom"
        >
          <div className="popup">
            <div className="title">{clientT('addProduct.validationTitle')}</div>
            <div className="preview">
              <div className="description">{initialData.description}</div>
              {conditions && <div className="condition">{conditions}</div>}
              {expirationDate && <div className="expiration">Jusqu'au {expirationDate}</div>}
            </div>
            {Context.partner.shopPageUrl && <>
              <div>{clientT('addProduct.validationGoToShop')}</div>
              <div className="shopBlock">
                <AppLink className="shopLink" href={
                  Context.partner.shopPageUrl.replace('{{id}}', Context.seller.id)
                }>
                  Page établissement
                </AppLink>
              </div>
            </>}
          </div>
        </TicketsInfoPopup>}
      </div>
    );
  }
}

export default withRouter(AddProduct)