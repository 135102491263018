import React, { useState, useEffect } from 'react';

const LanguageDropdown = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('fr');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('lng');
    if (language) {
      setSelectedLanguage(language);
    }
  }, []);

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    // Update the URL with the new language query parameter and reload the page
    window.location.href = `?lng=${newLanguage}`;
  };

  const dropdownContainerStyle = {
    position: 'relative',
    marginRight: '20px',
    display: 'inline-block',
    width: '50px',
    height: '30px',
  };

  const dropdownStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: 'white',
    padding: '5px 12px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    color: 'black',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box'
  };

  const flagIconStyle = {
    width: '24px',
    height: '16px',
    cursor: 'pointer'
  };

  const selectStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer'
  };

  return (
    <div style={dropdownContainerStyle}>
      <div style={dropdownStyle}>
        <img
          src={`assets/icons/${selectedLanguage === 'fr' ? 'french' : 'english'}.png`}
          alt={`${selectedLanguage === 'fr' ? 'French' : 'English'} Flag`}
          style={flagIconStyle}
        />
      </div>
      <select value={selectedLanguage} onChange={handleChange} style={selectStyle}>
        <option value="fr">Français</option>
        <option value="en">English</option>
      </select>
    </div>
  );
};

export default LanguageDropdown;
