import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "moment/locale/fr";

import "../../style/homeBordeauxMonCommerce.scss";
import { Context } from "../../tools/context";
import AppLink from "../../components/AppLink";


moment.locale("fr");

const BordeauxMonCommerceBox = (title, text, index) => (
  <div key={index} className="box">
    <p className="box_title">{title}</p>
    <p className="box_text" dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

const HomeBordeauxMonCommerce = ({location}) => {
  const urlParams = new URLSearchParams(location.search);

  return (
    <div className="bordeauxmoncommerceHome">
      <div className="tmp_container">
      <div className="tmp_wrapper">
        <div className="tmp_welcome">
          <h1>BIENVENUE DANS VOTRE ESPACE PARTENAIRE</h1>
          {Context.partner.launchDate && <div className="tmp_welcome_text" style={{marginBottom: 0}}>
            <p>
              Ouverture au grand public le
                  <span className="date">&nbsp;{moment(Context.partner.launchDate).format('DD MMMM YYYY')}</span>
            </p>
          </div>}
          <Link to="/dashboard-new">
            <p className='homePage_whiteButton'>
              JE DECOUVRE MON TABLEAU DE BORD
            </p>
          </Link>
        </div>
        <div className="tmp_boxes">
          <p>Découvrez dans votre espace</p>
          <div className="box">
            <p className="box_title">Dès maintenant</p>
            <p className="box_text">
              Renseignez vos coordonnées bancaires pour le versement de vos
                  tickets en cliquant{" "}
              <AppLink href="/mon-solde">ici</AppLink>
            </p>
          </div>
          {Object.keys( Context.partner.partnerStep ).map( ( step, index ) => {
            let text = Context.partner.partnerStep[step].text;
            let title = Context.partner.partnerStep[step].title;
            if ( moment( urlParams.get( 'currentDate' ) || new Date() ).isAfter( Context.partner.partnerStep[step].date ) ) {
              const status = Context.seller?.data?.find( k => k.key === `${Context.partner.sponsor}_status` )
              title = Context.partner.partnerStep[step].titleAfterStep || title;
              if ( step === "step1" ) {
                  text = Context.seller?.shortcode;
              } else if ( step === "step2" ) {
                if ( status && status.value === "pending" ) {
                  text = Context.partner.partnerStep[step].text;
                } else if ( status && status.value === "accepted" ) {
                  text = `Télécharger votre kit de communication
                        <a
                          href=${Context.partner.bvnKitURL}
                          target="_blank"
                        >
                          ICI
                        </a>
                      `;
                }
              } else if (step === "step3") {
                if (status?.value === "accepted") {
                  return <div className="box">
                    <p className="box_title">{title}</p>
                    <p className="box_text">
                      Suivez l'ensemble des transactions{" "}
                      <AppLink href="/mon-solde">ICI</AppLink>
                    </p>
                  </div>
                }
              }
            }

            return BordeauxMonCommerceBox(
              title,
              text,
              index
            );
          } )}
        </div>
      </div>
    </div>
    </div>
  )
};

export default HomeBordeauxMonCommerce;
