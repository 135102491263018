import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import '../../style/landing.scss';
import {Context} from "../../tools/context";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css'; // theme
import moment from "moment";
import VendorsMap from "./VendorsMap";
import Header from "./Header";
import Banner from "./Banner";
import {clientT} from '../../tools/i18n';
import Edito from '../../components/Edito';

class LandingTicket extends Component {
  constructor() {
    super();

    this.state = {
      isLaunchDate: false,
      date: '',
    };
  }

  componentDidMount() {
    this.getLaunchDate();
    if (window.location.hash === '#map' && document.querySelector('.map')) {
      setTimeout(() => document.querySelector('.map').scrollIntoView({behavior: "smooth"}), 500);
    }
  }

  getLaunchDate = () => {
    const launchDate = Context.partner.launchDate ? new Date(Context.partner.launchDate) : null;
    if (launchDate && !isNaN(launchDate.getDate()) && (new Date() - launchDate) < 0) {
      const date = moment(launchDate).format('DD/MM HH:mm')
      this.setState({isLaunchDate: true, date});
    }
  }

  render() {
    const {date, isLaunchDate} = this.state;

    return (
      <div className={`landingTicket ${this.props.platform} ${this.props.landing ?? ""}`}>
        <Header isLaunchDate={isLaunchDate}/>
        <Banner
          partner={(!this.props.landing || this.props.landing === 'pro') &&
            <div className="backgroundBlock">
              <div className='userType' dangerouslySetInnerHTML={{__html: clientT('landing.partner')}} />
              <div className='btn_wrapper'>
                <div className='signup_btn'>
                  <Link className='signup' to='/inscription'>
                    {clientT('landing.signin')}
                  </Link>
                </div>
                <Link
                  className='login_link'
                  to='/connexion'
                >
                  {clientT('landing.login')}
                </Link>
              </div>
            </div>
          }
          customer={(!this.props.landing || this.props.landing === 'client') &&
            <div className="backgroundBlock">
              <div className='userType' dangerouslySetInnerHTML={{__html: clientT('landing.client')}} />
              {
                !isLaunchDate ? <div className='btn_wrapper'>
                    <div className='signup_btn'>
                      <Link className='signup' to='/connexion-client'>
                        {clientT('landing.signin')}
                      </Link>
                    </div>
                    <Link className='login_link' to='/connexion-client'>
                      {clientT('landing.login')}
                    </Link>
                  </div> :
                  <p className='launchDateText'>{clientT('landing.launchDate', {date})}</p>
              }
            </div>
          }
        />
        {clientT('landing.edito', {fallback: ""}) &&
          <Edito innerHtml={clientT('landing.edito')} />
        }
        {clientT('landing.registeredSellers', {fallback: ""}) &&
          <div className={"sellersType"}>
            {clientT('landing.registeredSellers')}
          </div>
        }
        <VendorsMap {...this.props} moreSellers sellersFilter={['accepted']} />
      </div>
    );
  }
}

export default LandingTicket;
