import React, {forwardRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import '../style/dateRangePicker.scss';
import i18n, {t} from 'i18next';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

function CustomDateRangePicker({
  dateRange,
  presets,
  placeholder,
  onChange,
}) {
  const [startDate, endDate] = dateRange;
  const [currentPreset, setCurrentPreset] = useState(presets.length - 1);

  const InputDatePicker = forwardRef(({value, onClick}, ref) => (
    <div className={"InputDatePicker"} onClick={onClick}>
      <FontAwesomeIcon icon={faCalendarAlt} />
      <input
        type={"text"}
        value={value || placeholder}
        className={"date-picker-input"}
        ref={ref}
      />
    </div>
  ));

  const handlePreset = (preset, index) => {
    setCurrentPreset(index);

    const dateRange = [preset.startDate, preset.endDate];

    onChange(dateRange);
  }

  const handleChange = (dateRange) => {
    setCurrentPreset(-1);
    onChange(dateRange);
  }

  return (
    <div className="DateRangePicker">
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        placeholderText={placeholder}
        dateFormat={"dd/MM/yyyy"}
        className="date-picker"
        customInput={<InputDatePicker />}
        locale={i18n.language === 'fr' ? fr : en}
      >
        {presets.length &&
          <div className="presets">
            {presets.map((preset, index) => {
              return (
                <button
                  type={"button"}
                  value={preset.value}
                  className={`preset ${index === currentPreset ? 'current' : ''}`}
                  onClick={() => handlePreset(preset, index)}
                >
                  {preset.label}
                </button>
              )
            })}
          </div>
        }
      </DatePicker>
    </div>
  );
}

export default CustomDateRangePicker;