import React, { Component } from "react";
import HistoryPopup from "../components/Popup/HistoryPopup";
import Loader from "../components/Loader";
import "../style/MonSolde.scss";
import { Link } from "react-router-dom";
import {Context} from '../tools/context';
import { authCall, getSeller } from "../tools/apitools";
import {customer, getQueryString} from "../tools/toolbox";
import {fields} from "../tools/formFieldsDatabase";
import AppLink from "../components/AppLink";
import AppButton from "../components/AppButton";
import {clientT} from "../tools/i18n";

export default class MonSolde extends Component {

  constructor(props) {
    super(props);

    const {seller} = Context;
    let bic = '', iban = '';
    const ibanData = seller?.iban;
    const bicData = seller?.data?.find(k => k.key === 'bic');

    if (ibanData && ibanData) {
      iban = ibanData;
    }

    if (bicData && bicData.value) {
      bic = bicData.value
    }

    this.state = {
      loader: true,
      showPopup : false,
      iban: iban,
      bic: bic,
      collectionHistory: [],
      withdrawHistory: [],
      totalBalance: 0,
      totalDue: 0,
      queryBalance: getQueryString('code'),
      pspBalance: 0.0,
      pspValidation: 'OK',
      ibanError: false,
      popupHeading: '',
      showExportPdfBtn: false
    };

    this.registerDate = new Date();

    this.saveData = this.saveData.bind(this);
  }

  componentDidMount() {
    getSeller(true, () => {
      this.setState({
        iban: Context.seller.iban,
        bic: Context.seller.data.find(k => k.key === 'bic')?.value,
      })
    });
    this.fetchBalance();
    this.fetchWithdraws();
  }

  fetchBalance = () => {
    authCall(
        `api/sellers/balance/${customer()}`,
        {}, (result) => {
          this.calculateAndProcessBalance(result);
        },
        async () => {
          // this.calculateAndProcessBalance(this.sampleBalance);
        }
    ).then(
        () => this.setState({loader: false})
    );
  };

  handlePSPMessages = (result, reCall) => {
    if (!Context?.seller?.ownerPhone) {
      return 'noPhone';
    }

    if (!Context?.seller?.wallet?.informationUrl) {
      getSeller(true, () => {
        if (!Context?.seller?.ownerPhone) {
          return this.setState({pspValidation: 'noPhone'});
        }
        if (!Context?.seller?.wallet?.informationUrl) {
          return;
        }

        if (!reCall) {
          authCall(
              `api/sellers/withdraw/psp?sellerCustomer=true`,
              {}, async (result) => {
                let pspValidation = this.handlePSPMessages(result, true);

                this.setState({
                  withdrawHistory: result.widthdrawList,
                  pspBalance: result.balance,
                  pspValidation,
                });
              },
              async () => {
                this.setState({pspValidation: this.handlePSPMessages({status: 'ERROR'}, true)});
                // this.setState({withdrawHistory: this.sampleWithdraws});
              }
          );
        }

        this.setState({pspValidation: result.status});
      });
      return 'noAccount';
    }

    return result.status;
  }

  fetchWithdraws = () => {
    if (Context.features?.KYCEnabled) {
      authCall(
          `api/sellers/withdraw/psp?sellerCustomer=true`,
          {}, async (result) => {
            let pspValidation = this.handlePSPMessages(result);

            this.setState({
              withdrawHistory: result.widthdrawList,
              pspBalance: result.balance,
              pspValidation,
            });
          },
          async () => {
            this.setState({pspValidation: this.handlePSPMessages({status: 'ERROR'})});
            // this.setState({withdrawHistory: this.sampleWithdraws});
          }
      );
      return;
    }
    authCall(
        `api/sellers/withdraw?sellerCustomer=true`,
        {}, (result) => {

          this.setState({
            withdrawHistory: result
          });
        },
        async () => {
          // this.setState({withdrawHistory: this.sampleWithdraws});
        }
    );
  };

  calculateAndProcessBalance = (data = []) => {
    data.sort((a, b) => new Date(b.date) - new Date(a.date));
    console.log(data)
    let totalCredit = data.reduce((sum, current) => {
      return sum + current.credit
    }, 0);

    let totalBalance = data.reduce((sum, current) => {
      return sum + current.debit
    }, 0);
    const totalDue = totalBalance - totalCredit;

    if (data.length) {
      this.registerDate = new Date(data[data.length-1].date);
    }

    this.setState({totalBalance, totalDue, collectionHistory: data}, () => {
      if (!this.state.queryBalance) return;
      this.setState({popupHeading:  clientT('myBalance.popupheading.collection')})
      this.populatePopupData(true, this.state.queryBalance);
    });
  };

  saveData = () => {
    this.setState({saving: true});
    const {iban, bic} = this.state;
    let body = {...Context.seller};
    body.sellerData = {};

    // Validate iban value
    if (iban) {
      const reg = new RegExp(fields().iban.pattern);
      if (reg.test(iban)) {
        body.iban = iban;
        this.setState({ibanError: false});
      } else {
        this.setState({ibanError: true, saving: false});
        return
      }
    }

    // Validate bic value
    if (bic) {
      body.sellerData.bic = bic
    }

    authCall(`api/seller/`, {
          method: "PUT",
          body: JSON.stringify(body),
        }, () => {
          // Refresh the seller data
          getSeller(true);
        }, () => {}
    ).then(
        () => this.setState({saving: false})
    );
  };

  getStatusText(status){
    switch (status.toLowerCase()){
      case "pending":
        return "en attente de traitement";
      case "processing":
        return "en cours de traitement";
      case "validated":
        return "virement completé";
      case "canceled":
        return "virement annulé";
      case "refundasked":
        return "Remboursement demandé par le client";
      case "refunded":
        return "Remboursé au client";
      default:
        return "non défini"
    }
  }

  populatePopupData = (isEarnings = true, codeFilter) => {
    const {collectionHistory, withdrawHistory} = this.state;
    let popupData = [];
    if (isEarnings) {
      popupData = collectionHistory.filter(e => e.debit > 0 &&
          (e.giftCode === codeFilter || !codeFilter))
          .map(entry => ({
            date: new Date(entry.date).toLocaleDateString(),
            amount: entry.debit.toFixed(2),
            extra: entry.customerName,
            code: entry.giftCode,
            phone: entry.phone
          }));
    } else {
      popupData = withdrawHistory.map(entry => ({
        date: new Date(entry.creationDate).toLocaleString(),
        amount: entry.amount.toFixed(2),
        status: entry.status,
        extra: this.getStatusText(entry.status)
      }));
    }

    this.setState({showPopup:true, isEarnings, popupData});
  };



  onClosePopup = () => {
    this.setState({showPopup: false, queryBalance: null, showExportPdfBtn: false});
  };

  renderPspStatus = (validation) => {
    switch (validation) {
      case 'OK':
        return (
            <div className="pspBalance">
             {clientT('myBalance.pspStatus.ok')} <span style={{color: '#56C596', fontWeight: 'bold'}}>{this.state.pspBalance}€</span>
            </div>
        )
      case 'REFUSED':
        return (
            <div className="pspMessage pspError">
              <span style={{fontWeight: 'bold'}}>IMPORTANT</span> : {clientT('myBalance.pspStatus.refused')}
            </div>
        )
      case 'noPhone':
        return (
            <div className="pspMessage pspError">
              <span style={{fontWeight: 'bold'}}>IMPORTANT</span> :{clientT('myBalance.pspStatus.noPhone')}
            </div>
        );
      case 'BLOCKED':
      case 'PROCESSING':
      case 'BANNED':
        return (
            <div className="pspMessage pspError">
              <span style={{fontWeight: 'bold'}}>IMPORTANT</span> :{clientT('myBalance.pspStatus.banned')}<AppLink href="mailto:contact@ticket-commercant.fr">contact@ticket-commercant.fr</AppLink>
            </div>
        )
      case 'noAccount':
      case 'ERROR':
      default:
        return (
            <div className="pspMessage pspError">
              <span style={{fontWeight: 'bold'}}>IMPORTANT</span> :{clientT('myBalance.pspStatus.noAccount')} <AppLink href="mailto:contact@ticket-commercant.fr">contact@ticket-commercant.fr</AppLink>
            </div>
        )
    }
  }

  renderPspButton = (validation) => {
    switch (validation) {
      case 'OK':
        return (
            <AppLink href={`${Context.seller?.wallet?.cashoutUrl}&theme=${Context.partner.sponsor}`}
               target="_blank"
            >
              <AppButton className="btn btn-primary">
              {clientT('myBalance.pspButton.ok')}
              </AppButton>
            </AppLink>
        )

      case 'noPhone':
        return (
            <AppLink href="/profile">
              <AppButton className="btn btn-primary">
              {clientT('myBalance.pspButton.noPhone')}
              </AppButton>
            </AppLink>
        );
      case 'REFUSED':
      case 'BLOCKED':
      case 'PROCESSING':
      case 'BANNED':
        return (
            <AppLink href={`${Context.seller?.wallet?.informationUrl}&theme=${Context.partner.sponsor}`}
               target="_blank"
            >
              <AppButton className="btn btn-primary">
              {clientT('myBalance.pspButton.refused')}
              </AppButton>
            </AppLink>
        )
      case 'noAccount':
      case 'ERROR':
      default:
        return <AppButton className="btn btn-primary" onClick={() => document.location.reload()}>
        {clientT('myBalance.pspButton.noAccount')}
        </AppButton>
    }
  }

  render() {
    const { loader, showPopup, totalBalance, totalDue, popupData, isEarnings, iban, bic, saving } = this.state;
    return (
        <>
          <div className="dashboard">
            <main className="main" id="main">
              <div className="tabs">
                <div className="tab-buttons">
                  <AppLink
                      className="btn btn-link btn-inline btn-tab"
                      href="/profile"
                  >
                    {clientT('myBalance.profile')}
                  </AppLink>
                  <AppLink
                      className="btn btn-link btn-inline btn-tab active"
                      style={{ float: "right", lineHeight: "2", cursor:"default" }}
                      href="/mon-solde"
                  >
                    {clientT('myBalance.balance')}
                  </AppLink>
                </div>
                <div id="mon-solde">
                  <div className="tab-content active">
                    <h3>
                     {clientT('myBalance.title.0')} <span id="sales">{totalBalance.toFixed(2)}</span>€
                    </h3>
                    <img
                        src="/assets/tcaAssets/14110734/Applause.png"
                        className="applause"
                    />
                    <h3 style={{ fontWeight: "300" }}>
                    {clientT('myBalance.title.1')} {(
                        Context.seller?.registrationDate
                            ? new Date(Context.seller.registrationDate)
                            : this.registerDate
                    ).toLocaleDateString("fr-FR")}
                    </h3>
                    {!Context.features?.KYCEnabled &&
                    <h4
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          paddingTop: "16px",
                        }}
                    >
                     {clientT('myBalance.heading')} <span id="balance">{totalDue.toFixed(2)}</span>€
                    </h4>
                    }
                    {Context.features?.KYCEnabled
                        ? this.renderPspStatus(this.state.pspValidation)
                        : ""}
                    <p id="showHistory" style={{cursor: 'pointer' }}>
                      <AppLink
                          href="#"
                          style={{
                            color: "#3a4581",
                            borderBottom: "1px solid #3a4581",
                          }}
                          onClick={() => { this.setState({popupHeading: "Historique de versement", showExportPdfBtn: true}); this.populatePopupData(false)}}
                      >
                       {clientT('myBalance.popupheading.payment')}
                      </AppLink>
                    </p>
                    <p id="showEarning">
                      <AppLink
                       href="#"
                          style={{
                            color: "#3a4581",
                            cursor: 'pointer',
                            borderBottom: "1px solid #3a4581",
                          }}
                          onClick={() => { this.setState({popupHeading: "Historique d'encaissements"}); this.populatePopupData()}}
                      >
                        {clientT('myBalance.popupheading.collection')}
                      </AppLink>
                    </p>
                    {Context.partner?.pspMessage &&
                    <div
                      className={`pspMessage psp${Context.partner?.pspMessage?.type}`}
                      dangerouslySetInnerHTML={{__html: Context.partner?.pspMessage?.message}}
                    />
                    }
                    <div className="form-group" style={{ marginTop: "60px" }}>
                          <label htmlFor="phoneInput">
                           {clientT('myBalance.phoneInput')}
                          </label>
                          <div className="input-group icon-left">
                            <input
                                type="text"
                                id="userIBAN"
                                className="form-control"
                                placeholder="XX 0000 0000 0000 0000 0000"
                                value={iban}
                                onChange={(e) => {this.setState({iban: e.target.value})}}
                                pattern="^[a-zA-Z]{2}\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\S{4}\s?\S{7,}$"
                            />
                            <h5 className="icon ibanText">IBAN</h5>
                          </div>
                          {
                            this.state.ibanError &&
                            <div style={{color: 'red', marginLeft: '20px'}}>{fields().iban.customError}</div>
                          }
                          <div
                              className="input-group icon-left"
                              style={{ marginTop: "15px" }}
                          >
                            <input
                                type="text"
                                id="userBIC"
                                className="form-control"
                                placeholder="0000 0000"
                                value={bic}
                                onChange={(e) => {this.setState({bic: e.target.value})}}
                                minLength="8"
                                maxLength="11"
                            />
                            <h5 className="icon ibanText">BIC</h5>
                          </div>
                        </div>
                    {loader && <Loader />}
                    <div className="sticky-button">
                      {Context.features?.KYCEnabled
                          ? this.renderPspButton(this.state.pspValidation)
                          : <AppButton event="Save mon solde" onClick={this.saveData} id="SaveIBAN" className="btn btn-primary" disabled={saving}>
                            {saving && <i className="fa fa-spin fa- spinner"/>}{clientT('myBalance.updateButtonTitle')}
                          </AppButton>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          {showPopup &&
          <HistoryPopup showExportPdfBtn={this.state.showExportPdfBtn} popupHeading={this.state.popupHeading} showPopup={showPopup} data={popupData} isEarnings={isEarnings} onClosePopup={this.onClosePopup}/>
          }
        </>
    );
  }
}
