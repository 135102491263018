/**
 * THE CONTENT OF THIS FILE WILL BE REPLACE WITH THE .conf.dev or .conf.prod 
 * DEPENDING ON WHERE IT IS DEPLOYED
 * SO DON'T PAY ATTENTION ON THE ACTUAL CONTENT
**/
import {profile} from "./setupConf";
import {getQueryString, setCookie, getCookie} from "./tools/toolbox";
import {envConfig} from "./Utils/Constants";


const v = 0.5;
const p = getQueryString("env")||profile;
const config = envConfig[p]

const d = {
  // endpoint: 'https://cibler.io/',
  // endpoint: "http://localhost:8080",
  // endpoint: 'https://qa.cibler.io',
  endpoint: config.endpoint,
  profile: p,
  version: v,
  role: {
    ROLE_BACKUSER: false,
    ROLE_USER: false,
    ROLE_ADMIN: false,
  },
  clientToken: '',
};


const initSiteContext = () => {
  try {
    window.switchEnv = (p)=>{window.siteContext.endpoint=envConfig[p].endpoint;window.saveContext();}
    window.siteContext = JSON.parse(localStorage.getItem("siteContext")) || d;
    if (window.siteContext.version < d.v || window.siteContext.profile !== d.profile) {
      window.siteContext = d;
    }
    const cookieClientLoginToken = getCookie('clientLoginToken');
    window.siteContext.clientToken = cookieClientLoginToken || getQueryString('tc') || window.siteContext.clientToken;
    if (window.siteContext.clientToken && !cookieClientLoginToken) {
      setCookie('clientLoginToken', window.siteContext.clientToken, 7);
    }

    window.siteContext.accessToken = getQueryString('ta') || window.siteContext.accessToken;

    if (getQueryString('ts')) {
      window.siteContext.adminToken = window.siteContext.accessToken;
      window.siteContext.accessToken = getQueryString('ts');
    }
  } catch (e) {
    window.siteContext = d;
  }
};

export {initSiteContext,config};
