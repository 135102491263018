import React, {Component} from 'react';
import '../../style/connexionClient.scss';
import {loginWithUserPhone, reverseLogin, sendRegisterInfo, validatePhoneCode} from '../../tools/apitools'
import {Context, ContextUtil} from '../../tools/context';
import {customer, siteContext, saveContext, setCookie, getQueryString, isStringJson} from '../../tools/toolbox';
import {withRouter, Link} from "react-router-dom";
import PhoneField from "../../components/formField/PhoneField";
import {popupTypes} from "../../Utils/Constants";
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";
import AppButton from '../../components/AppButton';
import {clientT, t} from "../../tools/i18n";
import {getFields, userProfileFieldBase} from "../../tools/formFieldsDatabase";

class ConnexionClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNo: '',
      validationCode: '',
      enableValidationWrapper: false,
      newUser: false,
      validationRequestId: '',
      showReversePopup: false,
      reverseCode: '',
      errorPopup: false,
      loginErrorLabel: '',
      userId: '',
      disableSMS: false,
    }
  }

  componentDidMount = () => {
    if (siteContext().clientToken && !getQueryString('data')) {
      setCookie('clientLoginToken', siteContext().clientToken, 60);
      this.redirect();
    }
    const code = getQueryString('t');
    const request = getQueryString('r');
    if (code && request) {
      this.submitValidate(code, request);
    }

    if ('OTPCredential' in window) {
      this.ac = new AbortController();
      navigator.credentials.get({
        otp: {transport: ['sms']},
        signal: this.ac.signal
      }).then((otp) => {
        this.setState(
          {validationCode: otp.code},
          () => this.submitValidate()
        );
      }).catch((err) => {
        console.error(err);
      });
    }
  }

  componentWillUnmount() {
    this.ac && this.ac.abort();
  }

  submitPhoneNumber = () => {
    const {phoneNo, validationCode, disableSMS} = this.state;
    if(disableSMS) return;

    const adaptater = {
      lastname: 'name',
      contactEmail: 'email',
      zipcode: 'zipcode',
      question_1: `${Context.partner.sponsor}_custom_1`,
      question_2: `${Context.partner.sponsor}_custom_2`,
    };
    const keys = [];
    const fields = getFields('clientProfile', userProfileFieldBase);
    Object.keys(fields).forEach(name => {
      const field = fields[name]
      field.forEach(f => {
        if (!f.optional) {
          f.id = adaptater[f.id] || f.id;
          keys.push(f.id);
        }
      })
    })

    const loginData = {
      id: phoneNo.number,
      phone: phoneNo.formatted,
      validationKey: validationCode
    };
    this.setState({disableSMS: true});
    setTimeout(() => this.setState({disableSMS: false}), 10000)
    loginWithUserPhone(loginData, keys, data => {
      this.setState({
        newUser: data?.newUser,
        validationRequestId: data?.validationRequestId,
        enableValidationWrapper: true,
        userId: data?.userId
      });
    }, (e) => {
      this.setState({loginError: true})
    })
  }

  submitValidate = (code, requestId, callback) => {
    this.ac && this.ac.abort();
    const {phoneNo, validationCode, validationRequestId} = this.state;
    validatePhoneCode({
        customerId: customer(),
        id: phoneNo ? phoneNo.number : '',
        phone: phoneNo ? phoneNo.formatted : '',
        validationKey: code || validationCode,
        validationRequestId: requestId || validationRequestId,
        reverseLogin: !!code,
      },
      async (result) => {
        callback && callback();
        ContextUtil.update('clientToken', result);
        siteContext().clientToken = result;
        siteContext().userID = this.state.userId;
        saveContext();
        setCookie('clientLoginToken', result, 60);
        await sendRegisterInfo();
        this.redirect();
      }, e => {
        if (callback) return;
        switch (e.message) {
          case 'InvalidValidationCode':
          case 'BadValidationRequest':
            this.setState({loginErrorLabel: clientT('login.errors.invalidCode')});
            break;
          default:
            break;
        }
        this.setState({errorPopup: true})
      }
    );
  }

  submitReverse = async () => {
    const {phoneNo, validationCode} = this.state;
    const body = {
      id: phoneNo.number,
      phone: phoneNo.formatted,
      validationKey: validationCode,
      reverseLogin: true,
    };

    const code = await reverseLogin(body);

    if (!code) return this.setState({errorPopup: true});

    this.setState({showReversePopup: true, reverseCode: code})

    // Detect the user's platform
    const userAgent = navigator.userAgent;
    let smsLink;

    // Android
    if (/android/i.test(userAgent)) {
      smsLink = `sms:+33644630348?body=${encodeURIComponent(code)}`;
    }
    // iOS
    else if (/iPad|iPhone|iPod/.test(userAgent)) {
      smsLink = `sms:+33644630348&body=${encodeURIComponent(code)}`;
    }
    // Others
    else {
      smsLink = `sms:+33644630348?body=${encodeURIComponent(code)}`;
    }

    window.location.href = smsLink;

    const inter = setInterval(() => {
      this.submitValidate(code, null, () => {
        clearInterval(inter);
      })
    }, 3000)
    setTimeout(() => {
      clearInterval(inter);
    }, 600000)
  }



  redirect = () => {
    const redirectUrl = getQueryString('redirect');
    if (redirectUrl && !this.state.newUser) {
      window.location = decodeURIComponent(getQueryString('redirect')).replace(/([^:])\/\//g, '$1/');
      return;
    }

    !this.state.newUser
      ? !!redirectUrl
          ?  this.props.history.replace(redirectUrl)
          : this.props.history.replace('/portefeuille')
      : this.props.history.replace('/informations-client')
  }

  handleInput = (validate) => (e) => {
    if (e.key !== 'Enter') return;
    if (this.state.disableSMS) return;

    if (validate) {
      this.submitValidate();
    } else {
      this.submitPhoneNumber();
    }
  }

  render() {
    const {phoneNo, validationCode, enableValidationWrapper, disableSMS} = this.state;
    const footer = Context.partner?.client?.connectionFooter ?
      <div className="connexionclient__footerWrapper"
           dangerouslySetInnerHTML={{__html: Context.partner.client.connectionFooter}}/>
      : '';
    const steps = clientT('clientConnexion.steps');
    //TODO Add back phone validation (check MQ phone number 0696 68 44 33)
    return (
      <div className="connexionclient__backgroundBlock">
        {Context.partner?.client?.connexionBackground &&
        <div className="connexionclient__background loginImageDesktop">
          <img src={Context.partner?.client?.connexionBackground}/>
        </div>
        }
        {Context.partner?.client?.connexionBackgroundMobile &&
        <div className="connexionclient__background loginImageMobile">
          <img src={Context.partner?.client?.connexionBackgroundMobile}/>
        </div>
        }
        <div className={`main connexionclient__mainWrapper`}>
          {/*{Context.partner?.client?.connexionBackground ? 'withBackground' : ''}`}>*/}
          <p className="connexionclient__mainHeading">{t('clientConnexion.title')}</p>
          <div
            className={`connexionclient__stepsContainer ${Context.partner?.platform === 'ticketcommercant' && 'ticketCo'}`}>
            {
              (steps.map ? steps : [steps]).filter(text => text.trim()).map((text, index) => {
                return <div key={index}>
                  <p>{index + 1}</p>
                  <p className="stepLabel">{text}</p>
                </div>
              })
            }
          </div>
          <div className="connexionclient__loginForm">
            <label htmlFor="" dangerouslySetInnerHTML={{
              __html: clientT(
                getQueryString('data') ? 'clientConnexion.instructionsLogin' : 'clientConnexion.instructions')
            }}/>
            <div className="formControl" style={{display: 'flex', alignItems: 'center', background: '#fff'}}>
              <PhoneField
                fieldOnly
                onKeyPress={this.handleInput()}
                handleChange={(e) => {
                  this.setState({phoneNo: e.target.value})
                }}
                id="phoneNo"
                value={phoneNo}
              />
            </div>
            <AppButton
              event="Recevoir un code par SMS"
              client id="submitPhoneNo"
              className="formControl mainButton"
              onClick={this.submitPhoneNumber}
              style={{opacity: `${disableSMS ? "0.5" : ''}`}}
              disabled={ disableSMS }
            >
              {t('clientConnexion.recieveCode')}
            </AppButton>
            <div style={{visibility: enableValidationWrapper ? 'visible' : 'hidden'}}>
              <input id="validationCode" className="formControl validationCode" type="text"
                     autoComplete="one-time-code" inputMode="numeric"
                     onKeyPress={this.handleInput(true)}
                     placeholder={t('clientConnexion.validationCode')} value={validationCode} onChange={(e) => {
                this.setState({validationCode: e.target.value})
              }}/>
              <AppButton event="Connexion" client id="submitValidationCode" className="formControl mainButton"
                         onClick={() => this.submitValidate()}
                         style={{opacity: `${!validationCode ? "0.5" : ''}`}}
                         disabled={!validationCode}>{t('common.login')}
              </AppButton>
              <AppButton
                className="reverseLoginBtn"
                event="Je n'ai pas reçu mon code"
                client
                onClick={this.submitReverse}>{t('clientConnexion.notRecieved')}</AppButton>
            </div>
            {this.state.showReversePopup && <TicketsInfoPopup
              type={popupTypes.reverseLogin}
              replaceKeys={[{k: 'code', v: this.state.reverseCode}]}
              close={() => {
                this.setState({showReversePopup: false})
              }}
            />}
          </div>
          {this.state.errorPopup && <TicketsInfoPopup
            title={this.state.loginErrorLabel}
            close={() => {
              this.setState({errorPopup: false})
            }}
          />}
          {footer}
        </div>
      </div>
    )
  }
}

export default withRouter(ConnexionClient);