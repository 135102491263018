import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import '../../style/register2.scss';
import '../../style/constant.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import Context from '../../tools/context';
import {
  siteContext,
  getQueryString
} from '../../tools/toolbox';
import {
  createUser,
  logToOauth,
  authCall,
  uploadImage,
} from '../../tools/apitools';
import Loader from '../../components/Loader';
import CommentCaMarche from '../../components/CommentCaMarche';
import {clientT} from '../../tools/i18n';
import Step from "./Step";
import Confirmation from "./Confirmation";

class RegisterSteps extends Component {
  constructor() {
    super();

    this.errorMessages = {
      errorAlert: 'Merci de corriger les erreurs signalées en rouge',
      emailErrorMessage: 'Compte existant',
      passwordError: 'Les deux mots de passe ne correspondent pas',
      invalidAddressMessage:
        'Attention adresse inexistante merci de vérifier adresse et code postal avant de confirmer',
      registrationNumberErrorMessage: 'Le SIRET doit contenir 14 chiffres',
      phoneError: 'Le numero de téléphone n\'est pas valide',
      emptyFieldError: "Merci de saisir l'ensemble des champs obligatoires",
    };

    this.mapParams = {
      width: 300,
      height: 300,
    };

    this.registered = getQueryString('registered');
    this.iframe = getQueryString('iframe');
    if (!this.registered || this.iframe) {
      siteContext().accessToken = null;
      siteContext().refreshToken = null;
    }
    if (this.iframe && Context?.partner?.sponsor === 'passprovence') {
      document.body.style.setProperty('--partnerFontFamily', "\"Zilla Slab\", serif");
    }
    this.state = {
      formData: {
        companyName: '', //Nom de l'établissement
        corporateName: '', //Raison sociale
        registrationNumber: '', //N° SIRET
        category: '',
        contactEmail: '',
        contactPhone: '',
        address: '',
        zipcode: '',
        city: '',
        description: '',
        password1: '',
        password2: '',
        eligibleTypeOfShop: null,
        optInMarketing: null,
        optInTermsAndConditions: null, // Acceptation CGU
        iban: null,
        name: '',
        shortcode: null,
        tag: '',
        uuid: '',
      },
      //Error messages
      errorMessage: '',
      loaderOn: false,
      loaderClassName: 'hide',
      step: 0,
    };
  }

  handleChange = (e) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [e.target.id]: e.target.value
      },
    }));
  };

  createDefaultOffer = (sellerData) => {
    const defaultOffer = Context.partner.defaultOffer;

    if (!defaultOffer)
      return;

    const giftData = {
      data: {},
      giftDTO: {
        campaignId: defaultOffer.campaignId || null,
        description: defaultOffer.description,
        discountType: defaultOffer.offerType || "GWP",
        giftData: defaultOffer.giftData || null,
        id: defaultOffer.id || null,
        name: defaultOffer.description?.toUpperCase(),
        offerType: defaultOffer.offerType || "GWP",
        threshold: defaultOffer.threshold || "",
        thresholdType: defaultOffer.thresholdType || "BASKET",
        value: defaultOffer.value || "",
      },
      sellerId: sellerData.id,
      sellerName: sellerData.name,
    }

    authCall(
      "api/seller/offers",
      {
        method: "POST",
        body: JSON.stringify(giftData),
      },
      (result) => undefined,
      (e) => console.error(e)
    );
  }

  handleCreateUser = () => {
    this.setState({
      loaderOn: true,
    });

    const {formData} = this.state;
    const bodyKey = [
      'loginEmail',
      'contactEmail',
      'iban',
      'registrationNumber',
      'category',
      'name',
      'shortcode',
      'ownerEmail',
      'latitude',
      'longitude',
    ];

    const excludeKey = ['password1', 'password2', 'data', 'shortcode', 'image', 'image2', 'image3'];

    const body = {
      address: `${formData.address};${formData.city};${formData.zipcode}`, //"street;city;zipcode",
      password: formData.password1,
      contactPhone: formData.contactPhone?.number,
      ownerPhone: formData.ownerPhone?.number,
      contactPhoneCode: formData.contactPhone?.code,
      ownerPhoneCode: formData.ownerPhone?.code,
      sellerData: {
        partner: Context.partner.sponsor,
        street: formData.address,
      },
    };

    if (getQueryString('event')) {
      body.sellerData.event = getQueryString('event');
    }

    body.sellerData[`${Context.partner.sponsor}_status`] =
      Context.partner.features.requireValidation ? 'created' : 'accepted';

    if (Context.partner.parentCustomer) {
      body.sellerData[`${Context.partner.parentCustomer.key}_status`] =
        Context.partner.features.requireValidation ? 'created' : 'accepted';
    }

    Object.keys(formData).forEach((k) => {
      if (bodyKey.includes(k)) {
        body[k] = formData[k];
        return;
      }

      if (!body[k] && !body.sellerData[k] && !excludeKey.includes(k)) {
        body.sellerData[k] = formData[k];
      }
    });

    this.setState({loaderOn: true});
    createUser(body)
      .then((data) => {
        if (data.id) {
          // login
          const login = formData.loginEmail;
          const password = formData.password1;

          logToOauth(
            {login, password},
            () => {
              const randomImageVersion = Math.floor(Math.random() * 1000000);

              if (formData.image) {
                uploadImage(formData.image, data.id);


                const dataBody = {};
                dataBody['store_image'] = `https://cdn.cibler.io/static/seller/${data.id}/store.jpg?v=${randomImageVersion}`;
                authCall(
                  `api/seller/data/${data.id}`,
                  {
                    method: 'PUT',
                    body: JSON.stringify(dataBody),
                  }, () => undefined, () => undefined);
              }
              if (formData.image2) {
                uploadImage(formData.image2, data.id, 2);

                const dataBody = {};
                dataBody['store_image_2'] = `https://cdn.cibler.io/static/seller/${data.id}/store_2.jpg?v=${randomImageVersion}`;
                authCall(
                  `api/seller/data/${data.id}`,
                  {
                    method: 'PUT',
                    body: JSON.stringify(dataBody),
                  }, () => undefined, () => undefined);
              }
              if (formData.image3) {
                uploadImage(formData.image3, data.id, 3);

                const dataBody = {};
                dataBody['store_image_3'] = `https://cdn.cibler.io/static/seller/${data.id}/store_3.jpg?v=${randomImageVersion}`;
                authCall(
                  `api/seller/data/${data.id}`,
                  {
                    method: 'PUT',
                    body: JSON.stringify(dataBody),
                  }, () => undefined, () => undefined);
              }
              if (formData.imagepartner) {
                uploadImage(formData.imagepartner, data.id, 1, true);
              }

              if (Context.partner.defaultOffer)
                this.createDefaultOffer(data);

              const homePage = Context.partner.dashboardNew?.actAsHomePage ? '/dashboard-new' : '/home';
              if (this.iframe) {
                window.open(`${homePage}?ta=` + siteContext().accessToken, "_blank");
              }

              if (Context.partner.landingPlatform === 'correze') {
                this.props.history.replace(homePage);
              }

              this.setState({
                step: 4,
                loaderOn: false,
              });

              return;
            },
            () => this.props.history.replace('/connexion')
          );
        } else {
          // alert("Compte existant");
          window.scrollTo(0, 0);
          this.setState({
            errorMessage: "Une erreur est survenue pendant l'inscription",
            loaderOn: false,
          });
        }
      })
      .catch(() => {
        window.scrollTo(0, 0);
        this.setState({
          errorMessage: "Une erreur est survenue pendant l'inscription",
          loaderOn: false,
        });
      });
  };

  handleChangePage = (previous) => {
    window.scrollTo(0, 0);
    this.setState(p => ({step: p.step + (previous ? -1 : 1)}));
  }

  render() {
    const background =
      Context.partner.register && Context.partner.register.customBackground
        ? {
          background: `url('/assets/register_background.jpg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
        : null;

    return (
      <div className="registerV2">
        <div className='RegisterPage_container' style={background}>
          {this.state.loaderOn ? <Loader/> : null}
          {Context.partner?.register &&
          !Context.partner.register.withoutEdito ? (
            <div className='register_flex'>
              <div
                className='register_edito'
                dangerouslySetInnerHTML={{
                  __html: Context.partner.register.editoHtml,
                }}
              />
            </div>
          ) : null}
          <div className='register_flex'>
            <div dangerouslySetInnerHTML={{__html: clientT('registerV2.title')}}/>
            <div className='Content_wrapper'>
              {/* FORM START */}
              <h1
                className={this.state.loaderOn === false ? 'hide' : 'loaderOn'}
              >
                <Loader/>
              </h1>
              <div className='signUpPage_wrapper'>
                {this.state.step < 4 ?
                  <>
                    {this.state.errorMessage ? (
                      <div className='register_error'>
                        {this.state.errorMessage}
                      </div>
                    ) : null}
                    <Step
                      state={this.state.formData}
                      handleChange={this.handleChange}
                      handleNext={() => this.handleChangePage()}
                      handlePrevious={() => this.handleChangePage(true)}
                      handleSubmit={this.handleCreateUser}
                      history={this.props.history}
                      step={this.state.step}
                    />
                  </>
                  : <Confirmation history={this.props.history}/>
                }
              </div>
            </div>
          </div>
        </div>
        {Context.partner?.register?.commentCaMarche && <CommentCaMarche/>}
      </div>
    );
  }
}

export default withRouter(RegisterSteps);
