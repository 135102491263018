import React, { Component } from 'react';
import {
  withRouter,
} from 'react-router-dom';
import { Context, ContextUtil } from '../../tools/context';
import { authCall } from '../../tools/apitools';
import '../../style/home.scss';
import HomeDefault from './HomeDefault';
import HomeMyProvence from './HomeMyProvence';
import HomeTCA from "./HomeTCA";
import HomeTicketPei from './HomeTicketPei';
import HomeAriege from './HomeAriege';
import HomeBordeauxMonCommerce from './HomeBordeauxMonCommerce';


class Home extends Component {
  constructor() {
    super();
  }

  render() {
    switch (Context.partner.landingPlatform) {
      case 'passprovence':
         return <HomeMyProvence {...this.props} {...this.state} />;
      case 'ticketpei':
        return <HomeTicketPei {...this.props} {...this.state} />;
      case 'bordeauxmoncommerce':
        return <HomeBordeauxMonCommerce {...this.props} {...this.state} />;
      case 'tca':
      case 'myprovence':
      case 'ariege':
      case 'siblu':
      case 'capnord':
      case 'correze':
      case 'iledefrance':
      case 'orleans':
      case 'armor':
      case 'ccvba':
        return <HomeTCA {...this.props} />;
        // return <HomeAriege {...this.props}/>
      default:
        return <HomeDefault {...this.props}/>;
    }
  }
}

export default withRouter(Home);
