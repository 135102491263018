import React, {Component} from "react";
import TCACard from "./GenericCard";
import ATCACard from "./TCACard";
import Context from "../../tools/context";
import "../../style/addProductSuccess.scss";
import AppButton from "../../components/AppButton";
import {clientT} from "../../tools/i18n";
import AppLink from "../../components/AppLink";

export class AddProductSuccess extends Component {
    renderPreview = () => {
        return (
            <div >
            <AppLink className="btn infoBtn"
                     target="_blank"
                href={`https://tourismebyca.fr/bon-plan/?id=${this.props.previousData.id}`}
            >Voir mon avantage</AppLink>
            </div>)
    }

    render() {
        const {screen} = this.props;

        return (
            <React.Fragment>
                {screen === 1 || screen === 2 || screen === 3 ? (
                    <div className="page productBox">
                        <p className="titleHeader screen1Heading">
                            {clientT('addProductSuccess.screenHeading')}
                        </p>
                        <p className="titleChild screen1SubHeading">
                            {clientT('addProductSuccess.subHeading')}
                        </p>
                        <div id="offersWrap" className="adCard">
                            {this.renderPreview()}
                        </div>
                        <div className="descriptionBox">
                            <p className="titleChild descriptionHeading">
                                {clientT('addProductSuccess.descriptionHeading')}
                            </p>
                            <p className="description">
                                {clientT('addProductSuccess.description')}

                            </p>
                        </div>
                        <div className="sticky-bottom infoBtnBox">
                            <AppButton
                                className="btn infoBtn"
                                event="Oui, ajouter d’autres informations"
                                onClick={() => {
                                    this.props.setSuccessData({
                                        screen: 3,
                                        transformDirection: `translateX(-200%)`,
                                    });
                                }}
                            >
                                {clientT('addProductSuccess.otherinfoButton')}
                            </AppButton>
                        </div>
                        <div className="sticky-bottom screen1NextBtnBox">
                            <AppButton
                                type="button"
                                className="btn btn-primary"
                                event="Fermer"
                                onClick={() =>
                                    this.props.history.push('mes-avantages')
                                }
                            >
                                {clientT('addProductSuccess.close')}
                            </AppButton>
                        </div>
                    </div>
                ) : null}
            </React.Fragment>
        );
    }
}
