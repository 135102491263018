import React, { Component } from 'react';
import '../../style/MonSolde.scss';
import {pdf} from '@react-pdf/renderer';
import ExportPdf from '../ExportPdf';
import { saveAs } from 'file-saver';
import {t} from '../../tools/i18n';
import Context from '../../tools/context';

export default class HistoryPopup extends Component {

    componentWillMount(){
        this.props.showPopup && document.body.classList.add("has-popup");
    }

    componentWillUnmount(){
        this.props.showPopup && document.body.classList.remove("has-popup");
    }

    closeHistoryPopup = () => {
        this.props.onClosePopup();
    };

    downloadPDF = async (amount, date) => {
      const platform = Context.partner?.partnerPlatform || t('common.platform', {fallback: ""});
      const address = Context.seller?.data?.find(d => d.key === 'street')?.value + ' ' + Context.seller?.data?.find(d => d.key === 'zipcode')?.value + ' ' + Context.seller?.data?.find(d => d.key === 'city')?.value || "";
      const blob = await pdf(<ExportPdf platform={platform} address={address} amount={amount} date={date} />).toBlob();

      saveAs(blob, 'Versement.pdf');
    }

    render() {
        const {data, isEarnings, popupHeading, showExportPdfBtn} = this.props;

        return (
        <div id="popupHistory" className="history popupHistory">
          <div className="popupHistoryHeader">
            <div><span onClick={this.closeHistoryPopup}>X</span></div>
            <p>{popupHeading}</p>
          </div>
          <div className="historyTableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Montant</th>
                  <th>Date</th>
                  <th>{isEarnings ? 'Nature' : 'Statut'}</th>
                  {isEarnings && <th>Code</th>}
                  {isEarnings && <th>Tel</th>}
                  
                </tr>
              </thead>
              <tbody style={{textAlign: "center"}} id="mon-history">
              {
                data && data.map(entry =>
                    <tr>
                      <td>{entry.amount}€</td>
                      <td>{entry.date}</td>
                      <td>{entry.extra}</td>
                      {isEarnings && <td>{entry.code}</td>}
                      {isEarnings && <td>{entry.phone}</td>}
                      {showExportPdfBtn && entry.status == 'VALIDATED' &&<td>
                        <img onClick={() => {this.downloadPDF(entry.amount,entry.date)}} style={{cursor: "pointer"}}  src="/assets/Export.png" width={20} height={20} />
                      </td>}
                    </tr>
                )
              }
              </tbody>
            </table>
          </div>
        </div>
        )
    }
}
