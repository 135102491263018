import React from "react";
import { withRouter } from "react-router-dom";
import AppLink from "./AppLink";

const LinkWrapper = (props) => {
  const localProps = { ...props };
  delete localProps.staticContext;

  if (localProps?.to?.startsWith("http")) {
    return (
      <AppLink href={localProps.to} {...localProps}>
        {localProps.children}
      </AppLink>
    );
  }

  return (
    <AppLink href={localProps.to} {...localProps}>
      {localProps.children}
    </AppLink>
  );
};

export default withRouter(LinkWrapper);
