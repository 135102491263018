import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Context} from '../tools/context'
import AppLink from "./AppLink";
import Logo from "./Logo";
import Menu from "./Menu";

class GenericHeader extends Component {
    constructor() {
      super();
      this.state = {
          open: false,
      }
    }

  renderShopPageLink = () => {
      if (!Context?.seller?.id || !Context.partner.shopPageUrl) return false;

      return <AppLink href={
        Context.partner.shopPageUrl.replace('{{id}}', Context.seller.id)
      } className="btn mode-magasin"
                      target="_blank"
      >
        Page établissement
      </AppLink>;
  }

  handleMenuState = (state) => {
    this.setState({open: state})
  }

  render() {
    return (
      <div
        className="genericHeader"
      >
        <div style={{flex: 1}}>
          {Context.seller && <Menu onChange={this.handleMenuState} open={this.state.open} />}
        </div>
        <div style={{flex: 1}}>
          <Logo big style={{margin: '0 auto'}}/>
        </div>
        <div style={{flex: 1, textAlign: 'right'}}>
          {this.renderShopPageLink()}
        </div>
      </div>
    );
  }
}

export default withRouter(GenericHeader)
