import React, { Component } from "react";
import moment from 'moment'
import ErrorPopup from "../components/Popup/ErrorPopup";
import Loader from "../components/Loader";
import "../style/MesClients.scss";
import {authCall} from "../tools/apitools";
import {customer} from "../tools/toolbox";
import AppButton from "../components/AppButton";
import {clientT} from "../tools/i18n";

export default class MesTransactions extends Component {
  state = {
    loader: false,
    isErrorPopup: false,
    content : null,
    search : null,
  };

  componentDidMount() {
      this.fetchBalance();
  }

  fetchBalance = () => {
    authCall(
      `api/sellers/balance/${customer()}`,
      {}, (result) => {
        const content = result && result.map ? result.map(r => ({
          ...r,
          phoneFormat: r.phone && r.phone.replace ? r.phone.replace(/^\+?33/, '0') : '',
        })) : [];

        this.setState({content});
      },
      async () => {
      }
    );
  };

  searchUsers = (event) => {
    let keyword = event.target.value;
    this.setState({search:keyword})
  }


  render() {
    const { loader, isErrorPopup, content } = this.state;
    const filteredCards = this.state.search
      ? content && content.filter(d => d.phoneFormat.includes(this.state.search.toLowerCase()))
      : content;

    const userCards = filteredCards && filteredCards.map((data,index) => {
        return <div
              className="card card-client"
              data-search={data.phone}
              key={index}
            >
              <div className="avatar-wrap" style={{display: "none"}}>
                <img
                  src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
                  className="avatar"
                />
              </div>
              <div className="content-wrap">
                <p>
                  <strong className="phone">
                    {(data.phoneFormat.match(/.{1,2}/g) || []).join(" ")}{" "}
                  </strong>
                </p>
                <p>
                  <small>
                    <span>
                      {data.campaignName}
                    </span>
                    <span style={{marginLeft:"1em"}}>
                      {data.giftCode}
                    </span>
                  </small>
                </p>
                <p>
                  <small>
                    <span>le {moment(data.date).format('DD/MM/YYYY')} à {moment(data.date).format('HH:mm')}</span>
                  </small>
                </p>
              </div>
            </div>
    });

    return (
    <React.Fragment>
      {
        loader ? <Loader /> :  <div className="dashboard">
        <main className="main" id="main">
          <div className="search-wrap">
            <div className="input-group icon-left">
              <input
                type="text"
                id="clientSearch"
                className="form-control"
                placeholder={clientT('myClients.searchPlaceholder')}
                onChange={(e)=>this.searchUsers(e)}
              />
              <span className="icon icon-glass"></span>
            </div>
            <AppButton
              className="btn btn-primary btn-light btn-inline"
              style={{ display: "none" }}
            >
              <span className="icon icon-export"></span>
            </AppButton>
          </div>
          <div id="offers">
            { userCards }
          </div>
          {isErrorPopup && <ErrorPopup error={isErrorPopup} />}
        </main>
        </div>
      }
     </React.Fragment>
    );
  }
}
