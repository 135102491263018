import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './style/custom.scss';
import AdminApp from "./AdminApp";
import MainApp from "./MainApp";
import {clientT} from "./tools/i18n";

function App() {
  window.showPopupType = (popupType) => {
    console.log(
      '|--------Show popup type---------|\n\n' +
      `Title: ${clientT(`popup.${popupType}.title`)}\n\n` +
      `Lines:\n - ${clientT(`popup.${popupType}.lines`).join('\n - ')}\n\n` +
      '|--------------------------------|'
    );
  }

  return (
    <Switch>
      <Route path={'/Account'} component={AdminApp} />
      <Route component={MainApp} />
    </Switch>
  )
}

export default App;
