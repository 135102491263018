import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {getQueryString} from "../tools/toolbox";
import Header from './Landing/Header';
import VendorsMap from './Landing/VendorsMap';
import {clientT} from '../tools/i18n';
import '../style/etablissements.scss';
import Edito from '../components/Edito';
import TicketsInfoPopup from '../components/Popup/TicketsPopup';

class Etablissements extends Component {
  constructor() {
    super();

    this.state = {
      isLaunchDate: false,
      date: '',
      sellerShortCode: 'AAAAAA',
      showPopup: true
    };
  }

  componentDidMount() {
    if (window.location.hash === '#map' && document.querySelector('.map')) {
      setTimeout(() => document.querySelector('.map').scrollIntoView({behavior: "smooth"}), 500);
    }
  }

  render() {
    const {showPopup} = this.state;

    return (
      <div className={`Etablissements ${this.props.platform}`}>
        <Header backButton={{link: "/landing", label: clientT('etablissements.back', {fallback: ""}) || "Back"}} />
        {!this.props.noPopup && showPopup && clientT('etablissements.popup', {fallback: ''}) &&
          <TicketsInfoPopup
            title={clientT('etablissements.popup.title', {fallback: ""})}
            lines={clientT('etablissements.popup.list', {fallback: ""})}
            bottomButtons={[
              {
                label: clientT('etablissements.popup.close'),
                action: () => this.setState({showPopup: false})
              }
            ]}
          >
            {clientT('etablissements.popup.help', {fallback: ""}) &&
              <h4 style={{textAlign: "center"}}>{clientT('etablissements.popup.help')}</h4>
            }
          </TicketsInfoPopup>
        }
        {clientT('etablissements.admissibleSellers') !== 'etablissements.admissibleSellers' &&
          <div className={"sellersType"}>
            {clientT('etablissements.admissibleSellers')}
          </div>
        }
        <VendorsMap {...this.props} displayShortcode refund sellersFilter={this.props.sellersFilter || ['referenced']} />
      </div>
    );
  }
}

export default withRouter(Etablissements);