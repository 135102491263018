import React from "react";
import "../style/loader.scss";
import LoaderSpinner from "react-loader-spinner";

const Loader = ({overrideBackgroundColor, overrideSpinnerColor}) => {
  const pathname = window.location.pathname;
  return (
    <div
      className={`LoaderWrapper ${pathname.includes("/magasin") ? "bg-magasin" : "bg-blue"}`}
      style={{ background: overrideBackgroundColor ?? ''}}
    >
      <div>
        <LoaderSpinner
          type="ThreeDots"
          color={overrideSpinnerColor ?? `${pathname.includes("/magasin") ? "white" : "#00BFFF"}`}
          height={100}
          width={100}
          className="Loader"
        />
      </div>
    </div>
  );
};

export default Loader;
