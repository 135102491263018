import React, {Component} from "react";
import {
  withRouter,
  Link,
} from 'react-router-dom';
import {resetPassword, setNewPassword} from "../tools/apitools";
import Logo from "../components/Logo";
import '../style/login.scss';
import {Context} from "../tools/context";
import {getQueryString} from "../tools/toolbox";
import AppLink from "../components/AppLink";
import AppButton from "../components/AppButton";

class ResetPassword extends Component {
  constructor() {
    super();

    this.resetKey = getQueryString('resetkey');

    this.state = {
      login: '',
      password1: '',
      password2: '',
      errorMessage: null,
      confirm: false,
    }
  }

  componentDidMount() {
    if (Context.partner.sponsor) {
      // document.title = 'Mot de passe oublié ?';
    }
  }

  handleMail = () => {
    resetPassword(this.state.login,
      () => {
        this.setState({confirm: true})
      },
      (wrongMail) =>
        wrongMail
          ? this.setState({errorMessage: `Ce compte n'existe pas`})
          : this.setState({errorMessage: 'Une erreur est survenue'}
          ));
  }

  handlePassword = () => {
    if (this.state.password1 !== this.state.password2) {
      this.setState({errorMessage: 'Les deux mots de passe sont différents'});
      return;
    }

    setNewPassword(this.state.password1, this.resetKey,
      () => {
        this.setState({confirm: true})
      },
      () => this.setState({errorMessage: 'Une erreur est survenue'})
    );
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({errorMessage: null});

    if (this.resetKey) {
      this.handlePassword();
      return;
    }

    this.handleMail();
    return false;
  }

  renderMail = () => {
    if (this.state.confirm)
      return (<div>
        Un email de changement de mot de passe a été envoyé à votre adresse {this.state.login}
      </div>);

    return (
      <div>
        <label htmlFor="username">Email<span
          className="required"
        >*</span></label>
        <input
          required
          type="text"
          name="username"
          id="username"
          autoComplete="username"
          value={this.state.login}
          onChange={(e) => {
            this.setState({login: e.target.value})
          }}
        />
      </div>
    )
  }

  renderPassword = () => {
    if (this.state.confirm)
      return (<div>
        Votre nouveau mot de passe a été enregistré
      </div>);

    return (
      <>
        <div>
          <label htmlFor="password1">Mot de passe<span
            className="required"
          >*</span></label>
          <input
            required
            type="password"
            name="password1"
            id="password1"
            autoComplete="password"
            pattern=".{8,}"
            value={this.state.password1}
            onChange={(e) => {
              this.setState({password1: e.target.value})
            }}
            onInvalid={(e) => {
              e.target.setCustomValidity('Votre mot de passe doit contenir au moins 8 caractères');
            }}
            onInput={(e) => {e.target.setCustomValidity('')}}
          />
        </div>
        <div>
          <label htmlFor="password2">Confirmez le mot de passe<span
            className="required"
          >*</span></label>
          <input
            required
            type="password"
            name="password2"
            id="password2"
            pattern=".{8,}"
            value={this.state.password2}
            onChange={(e) => {
              this.setState({password2: e.target.value})
            }}
            onInvalid={(e) => {
              e.target.setCustomValidity('Votre mot de passe doit contenir au moins 8 caractères');
            }}
            onInput={(e) => {e.target.setCustomValidity('')}}
          />
        </div>
      </>
    )
  }

  render() {
    return (
      <div className='login'>
        {Context.partner.login && Context.partner.login.customImage ?
          <div className="loginImageDesktop">
            <img src={`/assets/${Context.partner.sponsor}_login_desktop.png`} />
          </div>
          : null
        }
        <div className="formBody">
          <Logo/>
          {Context.partner.login && Context.partner.login.customImage ?
            <div className="loginImageMobile">
              <img src={`/assets/${Context.partner.sponsor}_login_mobile.png`} />
            </div>
            : null
          }
          <div className="loginTitle">
            {this.resetKey ?
              'Entrez votre nouveau mot de passe'
              :
              'Entrez l\'email du compte dont vous avez perdu le mot de passe.'
            }
          </div>
          {this.state.errorMessage ?
            <div className="error">{this.state.errorMessage}</div>
          : null}
          <form onSubmit={this.handleClick}>
            {this.resetKey ? this.renderPassword() : this.renderMail() }
            {!this.state.confirm ?
              <div style={{marginTop: '40px'}}>
                <AppButton
                  type="submit"
                  name="login"
                  value="Identification"
                  className="button button1"
                  event="Reset password"
                >
                  Envoyer
                </AppButton>
                <div>
                  <AppLink href="/connexion">Se connecter</AppLink>
                </div>
              </div>
              :
              <div style={{marginTop: '40px'}}>
                <div>
                  <AppLink
                    className="button button1"
                    href="/connexion"
                  >Se connecter</AppLink>
                </div>
                <AppButton
                  type="submit"
                  name="login"
                  value="Identification"
                  event="Reset password"
                  style={{border: 'none', background: 'none',
                    color: 'var(--link-blue)', fontFamily: 'var(--partnerFontFamily)',
                    marginTop: '10px', cursor: 'pointer'
                  }}
                >
                  Envoyer de nouveau
                </AppButton>
              </div>
            }
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);