import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../pages/Landing/Header";
import "../../style/clientLayout.scss";
import { getUserData } from "../../tools/apitools";
import { Context, ContextUtil } from "../../tools/context";
import {
  getFields,
  userProfileFieldBase,
} from "../../tools/formFieldsDatabase";
import { clientT, t } from "../../tools/i18n";
import {
  getTicketDenomination,
  saveContext,
  setCookie,
  siteContext,
  userLoggedIn,
} from "../../tools/toolbox";
import AppButton from "../AppButton";
import AppLink from "../AppLink";
import LanguageSelector from "../LanguageSelector";
import { Picto } from "../Picto";
import Version from "../Version";
import LanguageButton from "../LanguageButton";

const ClientLayout = ({
  publicZone,
  completedProfileOptional,
  hideHeader,
  noClientLayout,
  children,
}) => {
  const [show, toggleMenu] = useState(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState(-1);
  const history = useHistory();
  const label = getTicketDenomination(true);

  let menuItems = [
    {
      Icon: "MyPromos",
      href: "/promotions",
      key: "/promotions",
      hide: !Context.features.showPromoTracking,
    },
    {
      Icon: "MyTickets",
      href: "/portefeuille",
      key: "/portefeuille",
    },
    {
      Icon: "MyTicketsUsed",
      href: "/historique",
      key: "/historique",
    },
    {
      Icon: "TicketActivation",
      href: "/ticket-activation",
      key: "/ticket-activation",
      hide: !Context.features.showTicketActivation,
    },
    {
      Icon: "MyTickets",
      href: "/mes-code-promos",
      key: "/mes-code-promos",
    },
    {
      Icon: "Refund",
      href: "/refund/list",
      key: "/refund/list",
      hide: !Context.partner?.features.allowUserRefund,
    },
    {
      Icon: "Profile",
      href: "/profile-client",
      key: "/profile-client",
    },
    {
      Icon: "retour-accueil-icon",
      key: "home",
      href: Context.partner?.clientNavigation?.homeUrl || "#",
    },
  ];
  if (window.zE && Context.partner?.features?.zendesk) {
    window.zE("webWidget", "show");
    window.zE("webWidget", "close");
  }
  if (Context.partner.clientNavigation) {
    const addLink = Context.partner.clientNavigation.add || [];
    addLink.forEach((link) => {
      if (link.key) {
        const index = menuItems.findIndex((i) => i.key === link.key);
        if (index >= 0) {
          menuItems[index] = {
            ...menuItems[index],
            ...link,
          };

          return;
        }
      }

      menuItems.push(link);
    });

    const removeLink = Context.partner.clientNavigation.remove || [];
    removeLink.forEach((r) => {
      const index = menuItems.findIndex((p) => p.href === r);
      if (index >= 0) {
        menuItems.splice(index, 1);
      }
    });
  }

  useEffect(() => {
    if (completedProfileOptional) return;

    const adaptater = {
      lastname: "name",
      contactEmail: "email",
      zipcode: "zipcode",
      question_1: `${Context.partner.sponsor}_custom_1`,
      question_2: `${Context.partner.sponsor}_custom_2`,
    };
    const keys = [];

    const fields = getFields("clientProfile", userProfileFieldBase);
    Object.keys(fields).forEach((name) => {
      const field = fields[name];
      field.forEach((f) => {
        if (!f.optional) {
          f.id = adaptater[f.id] || f.id;
          keys.push(f.id);
        }
      });
    });

    getUserData(
      keys,
      (data) => {
        let completed = true;
        if (!data || Object.keys(data).length === 0) completed = false;
        else {
          Object.keys(data).forEach((key) => {
            if (!data[key]) {
              completed = false;
            }
          });
        }
        setIsProfileCompleted(completed ? 1 : 0);
      },
      (error) => {
        console.error(error);
        setIsProfileCompleted(0);
      }
    ).catch((e) => {
      console.error(e);
      setIsProfileCompleted(0);
    });
  }, []);

  if (!publicZone && !userLoggedIn()) {
    const path = history.location.pathname;
    if (
      (path?.includes("/transactions") &&
        !!Context.partner.course?.campaignId) ||
      path === "/informations-client-course" ||
      path === "/course"
    ) {
      history.push(
        `/connexion-qr?redirect=${encodeURIComponent(
          `${history.location.pathname}${history.location.search}`
        )}`
      );
      return null;
    }
    history.push(
      `/connexion-client?redirect=${encodeURIComponent(
        `${history.location.pathname}${history.location.search}`
      )}`
    );
  }

  if (
    !publicZone &&
    !completedProfileOptional &&
    isProfileCompleted === 0 &&
    userLoggedIn()
  ) {
    const redirect = history.location.pathname;
    history.push(
      `/informations-client?redirect=${encodeURIComponent(redirect)}`
    );
  }

  const src = Context.partner.customLogo
    ? `/assets/${Context.partner.sponsor}_logo.png`
    : "/assets/logo.png";

  const handleDisconnect = () => {
    ContextUtil.update("clientToken", null);
    siteContext().clientToken = null;
    saveContext();
    setCookie("clientLoginToken", "", -1);
    history.replace(Context.partner.clientNavigation.disconnectionUrl || "/");
  };

  if (publicZone || noClientLayout) {
    return (
      <div className={`client-layout ${Context.partner.platform}`}>
        {!hideHeader && <Header />}
        {children}
      </div>
    );
  }

  return (
    <div className="client-layout">
      {!hideHeader && (
        <header className="client-header">
          <div className="logo-wrapper">
            <AppLink
              client
              href={Context.partner?.clientNavigation?.homeUrl || "/"}
            >
              <img src={src} alt="" title="logo" />
            </AppLink>
          </div>
          <LanguageButton />

          {!publicZone && (
            <AppButton
              className="menuButton"
              onClick={() => toggleMenu(!show)}
              event="Client menu button"
              client
            >
              <svg
                className="menuIcon"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 8H28V10.6667H4V8ZM4 14.6667H28V17.3333H4V14.6667ZM4 21.3333H28V24H4V21.3333Z"
                  // fill={Context?.partner?.textColor5 || Context?.partner?.textColor1}
                />
              </svg>
            </AppButton>
          )}
        </header>
      )}
      {/*<div style={{display: "flex"}}>*/}
      {!publicZone && (
        <div
          className="client-menu"
          style={show ? { transform: "translateX(0)" } : {}}
        >
          <div
            className="client-header"
            style={{ position: "absolute", left: 0, top: 0 }}
          >
            <div className="logo-wrapper">
              <AppLink
                client
                href={Context.partner?.clientNavigation?.homeUrl || "/"}
              >
                <img src={src} alt="" title="logo" />
              </AppLink>
            </div>
          </div>
          <ul>
            <div className="externalLinks">
              {Context?.partner?.client?.externalLinks &&
                Context.partner.client.externalLinks.map((item) => (
                  <li
                    key={item.href}
                    className={item.selected ? "selected" : ""}
                  >
                    <a href={item.href || "#"}>
                      {clientT(`menu.${item.key}`, { fallback: item.label })}
                    </a>
                  </li>
                ))}
            </div>
            {menuItems
              .filter((m) => !m.hide)
              .map((item, index) => {
                const content = (
                  <>
                    {item.Icon && <Picto iconKey={item.Icon} />}
                    <span className="menulabel">
                      {clientT(`menu.${item.key || item.href}`, {
                        label: label,
                      })}
                    </span>
                  </>
                );
                const selected = document.location.pathname === item.href;

                return (
                  <li key={index} className={selected ? "selected" : ""}>
                    <AppLink client {...item}>
                      {content}
                    </AppLink>
                  </li>
                );
              })}
          </ul>
          <div className="bottom-menu">
            <ul>
              {Context.partner?.clientNavigation?.helpUrl && (
                <li>
                  <AppLink
                    anchor
                    client
                    href={
                      Context.partner.clientNavigation.helpUrl.url ||
                      Context.partner.clientNavigation.helpUrl
                    }
                    target="_blank"
                  >
                    <Picto iconKey="Help" />
                    <span className="menulabel">
                      {Context.partner.clientNavigation.helpUrl.label ||
                        t("menu.help")}
                    </span>
                  </AppLink>
                </li>
              )}
              <li>
                <a className={"pointer"} onClick={handleDisconnect}>
                  <Picto iconKey="Disconnect" />
                  <span className="menulabel">{t("menu.logout")}</span>
                </a>
              </li>
            </ul>
          </div>
          <LanguageSelector />
          <Version />
        </div>
      )}
      {publicZone ? (
        children
      ) : (
        <div className="screenAdapter" onClick={() => toggleMenu(false)}>
          {children}
        </div>
      )}
      {/*</div>*/}
    </div>
  );
};

export default ClientLayout;
