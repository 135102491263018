import React, { Component } from 'react';
import CustomDateRangePicker from "../CustomDateRangePicker";

export default class DateRangeField extends Component {
  localHandle = (value) => {
    console.log(value)
    console.log(JSON.stringify(value))
    this.props.handleChange({
      target: {
        id: this.props.id,
        value,
      }
    })
  }

  render() {
    const {
      id,
      label,
      optional,
      errorMsg,
      value,
      subLabel,
      labelOptional
    } = this.props;

    const localValue = value
      ? value
      : [
          new Date(new Date()),
          new Date(new Date().setDate(new Date().getDate() + 7))
      ]

    const presets = [
      {
        label: 'Cette année',
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31)
      }
    ];

    return (
     <p>
      {!labelOptional &&
        <label htmlFor={id}>
          {label}
          {!optional
            ? <span className="required">*</span>
            : null
          }
          <span className="subLabel">{subLabel}</span>
        </label>
       }
        <CustomDateRangePicker
          dateRange={localValue}
          presets={presets}
          placeholder={presets[presets.length - 1].label}
          onChange={this.localHandle}
        />
        {errorMsg
          ? <span className="error">{errorMsg}</span>
          : null
        }
      </p>
    )
  }
}