import React from 'react';
import { Context } from '../tools/context';
import '../style/registerPageHeader.scss';
import AppLink from './AppLink';

const RegisterPageHeader = () => {
  const ticketCoLogo_src =
    Context.partner.ticketcommercant &&
    `/assets/ticket_co_${Context.partner.sponsor}.png`;
  const partnerLogo_src = `/assets/${Context.partner.sponsor}_logo.png` || '#';
  return (
    <div className='headerWrapper'>
      <div className='headerLogo'>
        {ticketCoLogo_src && <img src={ticketCoLogo_src} />}
        {partnerLogo_src && <img src={partnerLogo_src} />}
      </div>
      <div className='headerHelper'>
        <div><AppLink href={Context.partner.howItWorksURL||'#commentçamarche'} target={Context.partner.howItWorksURL ? "_blank" : ""} anchor>Comment ça marche?</AppLink></div>
        <div><AppLink href={Context.partner.contactURL||'https://bienvenus.zendesk.com/hc/fr/requests/new'} target='_blank'>Contact</AppLink></div>
      </div>
    </div>
  );
};

export default RegisterPageHeader;
