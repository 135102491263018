import * as React from "react";
import {track} from "../tools/tracking";

function AppButton({type, event, client, ...props}) {
  const handleClick = (e) => {
    track('click button', {
      type, event
    })
    props.onClick && props.onClick(e);
  }
  
  return <button
    type={type || "button"}
    {...props}
    onClick={handleClick}
  >{props.children || ''}</button>
}

export default AppButton;
