import React, {useEffect, useState} from "react";
import {getUserData} from "../../../../tools/apitools";
import SwiperItem from "./SwiperItem";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {A11y, EffectCoverflow, Pagination} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './index.scss';

SwiperCore.use([EffectCoverflow, Pagination, A11y]);

export default function PaymentCards({ tickets, used }) {
  const [user, setUser] = useState({});

  useEffect(() => {
    const userKeys = ['firstname', 'name'];

    getUserData(
      userKeys,
      (res) => setUser(res),
      (e) => console.error(e)
    ).catch(e => console.error(e));
  }, []);

  return (
    <div className="PaymentCards">
      <Swiper
        onSwiper={(swiper) => (window.swiper = swiper)}
        effect="coverflow"
        spaceBetween={16}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: -16,
          depth: 125,
          modifier: 1,
          scale: 1,
          slideShadows: false,
        }}
        pagination={{ clickable: true }}
        centeredSlides
        grabCursor
      >
        {tickets?.map((ticket, i) => (
          <SwiperSlide key={i}>
            <SwiperItem ticket={ticket} user={user} used={used} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
