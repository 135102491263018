import React from 'react'
import './index.scss'
import {siteContext} from "../../../tools/toolbox";
import Context from "../../../tools/context";
import {authClient} from '../../../tools/apitools';
import {clientT} from '../../../tools/i18n';

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      score: -1,
      comment: "",
      reviewSent: false,
      hovered: -1
    }

    this.submit = this.submitFeedback;
  }

  stars() {
    const nbStars = 11;
    let stars = [];

    for (let i = 0; i < nbStars; i++) {
      stars.push(
        <label>
          <input
            type="radio"
            value={i}
            checked={this.state.score === i}
            onClick={() => this.setState({score: i})}
          />
          <div
            className={`star star${i} ${i <= this.state.hovered ? 'active' : ''}`}
            onMouseEnter={() => this.setState({hovered: i})}
            onMouseLeave={() => this.setState({hovered: this.state.score})}
          >
            {i}
          </div>
        </label>
      )
    }

    return <fieldset className="stars">{stars}</fieldset>
  }

  submitFeedback() {
    if (this.state.score === -1 && !this.state.comment) return;
    if (this.state.reviewSent) return;
    this.setState({reviewSent: true});
    const body = {
      gift: {id: 0},
      shopGrade: this.state.score,
      giftGrade: 0,
      review: this.state.comment,
      name: siteContext().userID,
      date: new Date(),
      sellerInfo: {id: this.props.sellerId}
    }

    authClient(`api/seller/offers/review/?customerId=${Context.partner.customerId}`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  render() {
    return (
      <div className={"Feedback"}>
        {this.state.reviewSent ?
          <div>{clientT('feedback.confirmation')}</div>
          :
          <>
            <div className={"rating"}>
              {clientT('feedback.question', {fallback: ""}) &&
              <div className={"question"}>{clientT('feedback.question')}</div>
              }
              {this.stars()}
              <div className="legend">
                <div>0 - 😞</div>
                <div>10 - 😃</div>
              </div>
            </div>
            <textarea
              required
              className={"comment"}
              name="comment"
              placeholder={clientT('feedback.comment')}
              onChange={(e) => this.setState({comment: e.target.value})}
              value={this.state.comment}
            />
            {!this.props.noButton &&
              <button
                name="submit"
                type="submit"
                id="submit"
                disabled={this.state.score === -1}
                className={"mainButton submit"}
                onClick={() => this.submitFeedback()}
              >{clientT('feedback.submit', {fallback: 'Valider'})}</button>
            }
          </>
        }
      </div>
    )
  }
}

export default Feedback
