import React, {Component} from 'react';
import '../../style/TicketsPage.scss';
import {authClient} from '../../tools/apitools';
import {getQueryString} from '../../tools/toolbox';
import MyTickets from './MyTickets';
import TicketConsumer from "./TicketConsumer";
import TicketExchange from "../../components/TicketExchange";
import ConfirmedTicket from "../../components/ConfirmedTicket"
import Context from '../../tools/context';
import moment from 'moment';
import {popupTypes} from '../../Utils/Constants';
import TicketsInfoPopup from "../../components/Popup/TicketsPopup";
import {clientT, t} from "../../tools/i18n";
import AppLink from "../../components/AppLink";
import {track} from "../../tools/tracking";
import TicketValidation from "./TicketValidation";
import {withRouter} from "react-router-dom";

class TicketsPage extends Component {
    constructor() {
        super();

        let couponQuery = getQueryString('coupon');
        this.coupons = [];
        if (couponQuery) {
            this.coupons = decodeURIComponent(couponQuery).split('|');
        }

        this.initialState = {
            tickets: [],
            selectedTickets: [],
            showTicketsConsumer: false,
            showTerminationPage: false,
            canConfirm: false,
            confirmPopup: false,
            notConfirmedPopup: false,
            confirmed: false,
            shortCode: '',
            sellerName: '',
            totalPrize: '',
            partnerName: '',
            infoPopup: false,
            typePopup: '',
            title: '',
            exchangeData: '',
            step: 0,
            partnerData: {},
            isLoading: true,
        };

        this.state = {...this.initialState};
    }

    componentDidMount() {
        const shortcode = getQueryString('shortcode');
        if (shortcode) {
            this.setState({partnerData: {partnerCode: shortcode.toUpperCase()}});
            this.handleInputShortCode(shortcode.toUpperCase());
        }

        this.getGifts();
    }

    onGetGiftResponse = (res) => {
        const buffer = res?.couponList
            ?.filter(ticket => {
                if (ticket.used)
                    return false;
                if (!ticket.giftType || ticket.giftType.toLowerCase() !== "standard")
                    return false;
                if (ticket.campaignType === 'PaymentCard')
                    return (ticket.sorewardsCardBalance === null || ticket.sorewardsCardBalance > 0);
                if (ticket.expiration && moment().isAfter(ticket.expiration))
                    return false;
                return true;
            })

        if ((!buffer || !buffer.length)) {
            if (!Context.partner?.popupTexts?.noPopup) {
                if (res.notEligible) {
                    this.setState({isLoading: false,typePopup: popupTypes.notEligible, gotoActivation: true})
                    return;
                }
                if (res.noCampaign) {
                    this.setState({isLoading: false,typePopup: popupTypes.noCampaign, gotoActivation: true})
                    return;
                }
                if (res.hasTicket) {
                    this.setState({isLoading: false,typePopup: popupTypes.allUsed, gotoActivation: true})
                    return;
                }

                this.setState({isLoading: false,typePopup: popupTypes.noTicket, gotoActivation: true})
                return;
            } else {
                this.setState({isLoading: false,gotoActivation: true})
            }
        }

        if (buffer.length !== 0) {
            this.setState({isLoading: false, tickets: buffer});

            if (this.coupons && this.coupons.length > 0) {
                const coups = [];
                this.coupons.forEach(c => {
                    const coup = buffer.find(b => b.prize?.toLowerCase() === c?.toLowerCase());
                    if (coup) coups.push(coup);
                })
                if (coups.length > 0) {
                    this.setState({
                        selectedTickets: coups,
                        step: this.state.partnerData.partnerCode ? 2 : 1,
                    })
                }
            } else if (buffer.length === 1 && Context.features.autoSelectTicket) {
                this.handleSelection(buffer[0]);
            }
        } else {
            this.setState({isLoading: false, tickets: []});
        }
    }

    onGetGiftError = (status, err) => {
        console.error('Either customerId is incorrect or partner is not correctly set', err);
        this.setState({isLoading: false, typePopup: 'custom', title: "Une erreur est survenue"});
    }

    getGifts = async () => {
        let sellerMode = getQueryString("seller");
        if (!sellerMode) {
            authClient(`api/bienvenus/user/ticketV2?customer=${Context.partner.customerId}`, {method: 'GET'},
                this.onGetGiftResponse, this.onGetGiftError);
        }
    }

    select = (ticket) => {
        let selectedTickets = this.state.selectedTickets;

        if (!selectedTickets.length) return this.state.tickets.filter(t => ticket.uuids?.includes(t.uuid));

        const hasSelected = selectedTickets.find(t => ticket.uuids.includes(t.uuid));

        if (hasSelected) {
            selectedTickets = selectedTickets.filter(t => !ticket.uuids.includes(t.uuid));
            return selectedTickets;
        }

        if (Context.features.disableMultiSelection) return [ticket];

        if (selectedTickets[0].campaignId !== ticket.campaignId) {
            this.setState({typePopup: popupTypes.severalCampaigns})
            return selectedTickets;
        }

        this.state.tickets.filter(t => ticket.uuids.includes(t.uuid)).forEach(t => {
            if (selectedTickets.find(st => st.uuid === t.uuid)) return;
            selectedTickets.push(t);
        });
        return selectedTickets;
    }

    handleSelection = (ticket) => {
        const selectedTickets = this.select(ticket);

        this.setState({selectedTickets})
    }

    trackTicket = () => {
        if (!Array.isArray(this.state.selectedTickets)) return;
        track('viewConfirmPopup', {
            shortcode: this.state.partnerData?.partnerCode,
            prize: this.state.selectedTickets.map(ticket => ticket.prize).join('|'),
            uuid: this.state.selectedTickets.map(ticket => ticket.uuid).join('|'),
        }, true);
    }

    nextStep = (previous) => {
        this.setState(p => ({step: p.step + (previous ? -1 : 1)}))
    }

    gotoExchange = () => {
        this.setState({step: 4, partnerData: {partnerCode: 'THEFORK'}})
    }

    handleConfirmed = (showTicketsConsumer) => {
        if (!showTicketsConsumer) {
            if (this.shortcode && getQueryString('noBurn') === 'true') {
                const refundPrizes = encodeURIComponent(this.state.selectedTickets.map(p => p.prize).join('|'));
                window.location.href = `/refund/ask/${this.shortcode}/${refundPrizes}?noBurn=true`;
                return;
            }
            return this.setState({step: 1});
        }

        if (this.state.canConfirm)
            if (Context.features.bypassBurnConfirmation) {
                this.handleBurn()
            } else {
                this.setState({confirmPopup: true});
                this.trackTicket();
            }
    }

    handleInputShortCode = (value) => {
        if (value.length >= 6) {
            authClient(`api/public/seller/data/${value}`, {method: 'GET'},
                res => this.setState({
                    partnerData: {
                        partnerName: res.name,
                        partnerError: '',
                        partnerId: res.id,
                        partnerCode: value,
                    }
                }),
                res => this.setState({partnerData: {partnerError: clientT('myTicketsV2.step2.error')}}))
        } else {
            this.setState({partnerData: {}});
        }
    }

    onBurnResponse = (res) => {
        if (this.state.step === 4) {
            this.setState({
                showTerminationPage: true,
                exchangeData: res?.exchangeData,
                sellerName: res?.sellerName,
                sellerId: res?.sellerId,
                totalPrize: res?.totalPrize
            });
        } else {
            this.setState({
                sellerId: res?.sellerId,
                sellerName: res.sellerName,
                totalPrize: res.totalPrize,
                step: 3,
            });
        }
    }

    onBurnError = (error, status) => {
        this.setState({typePopup: popupTypes[error.message] || popupTypes.unknownError, confirmPopup: false});
    }

    handleBurn = async () => {
        const {selectedTickets, partnerData, step} = this.state;
        try {
            authClient(
                `api/${step === 4 ? "prizes/exchange" : "bienvenus/coupons/burn"}/${Context.partner.customerId}/?shortcode=${partnerData.partnerCode}`,
                {
                    method: 'POST',
                    body: step === 4 ? selectedTickets[0]?.uuid : JSON.stringify(selectedTickets.map(t => t.uuid))
                },
                this.onBurnResponse,
                this.onBurnError,
            );
        } catch (err) {
            console.error(err);
        }
    }

    burnWithShortcode = (shortcode) => {
        this.setState({shortCode: shortcode})
        this.handleInputShortCode(shortcode);
        this.setState({step: 2});
    }

    reset = () => {
        this.setState({...this.initialState})
        this.getGifts();
    }

    render() {
        const {
            tickets, selectedTickets,
            sellerName, showTerminationPage,
            shortCode, sellerId, step, isLoading,
        } = this.state;

        const steps = clientT('myTicketsV2.timeline', {fallback: []})

        return (
            <div className="TicketsPage">
                <div className="clientTitle">
                    {clientT('myTicketsV2.title')}
                </div>
                {tickets?.length > 0 && step < steps.length && !tickets.some(t => t.campaignType === "PaymentCard") && (
                  <div className="stepper">
                    <div className="current">{steps[step]}</div>
                    <div className="steps">
                        {steps.map((s, i) => <div
                            key={`step_${i}`}
                            data-step={step}
                            data-i={i}
                            className={`step ${step >= i ? 'done' : 'todo'}`}
                        />)}
                    </div>
                  </div>
                )}
                {step === 0 && <MyTickets
                    tickets={tickets}
                    handleSelection={this.handleSelection}
                    resetSelectedTickets={() => this.setState({selectedTickets: []})}
                    selectedTickets={selectedTickets}
                    shortcode={this.state.partnerData.partnerCode}
                    nextStep={this.nextStep}
                    isLoading={isLoading}
                />}
                {step === 1 && <TicketConsumer
                    selectedTickets={selectedTickets}
                    partnerData={this.state.partnerData}
                    handleInputShortCode={this.handleInputShortCode}
                    gotoExchange={this.gotoExchange}
                    nextStep={this.nextStep}
                    burnWithShortcode={this.burnWithShortcode}
                />}
                {step === 2 && <TicketValidation
                    selectedTickets={selectedTickets}
                    partnerData={this.state.partnerData}
                    handleBurn={this.handleBurn}
                    nextStep={this.nextStep}
                />}
                {step === 3 && <ConfirmedTicket
                    selectedTickets={selectedTickets}
                    sellerShortCode={shortCode}
                    sellerName={sellerName}
                    sellerId={sellerId}
                    reset={this.reset}
                />}
                {step === 4 && <TicketExchange
                    showTerminationPage={showTerminationPage}
                    selectedTickets={selectedTickets}
                    handleBurn={this.handleBurn}
                    back={() => this.setState({partnerData: {}, step: 1})}
                />}
                {this.state.typePopup &&
                <TicketsInfoPopup
                    type={this.state.typePopup}
                    title={this.state.title}
                    close={() => this.setState({typePopup: '', gotoActivation: false})}
                    addendum={Context.features.showTicketActivation === false ? undefined : this.state.gotoActivation && <div className="refundBlock">
                        <AppLink href={`/ticket-activation`}
                                 dangerouslySetInnerHTML={{__html: clientT('ticketActivation.goto')}}
                        /></div>}
                />}
                {this.state.gotoActivation && !this.state.typePopup && <TicketsInfoPopup
                    type={'gotoActivation'}
                    close={() => this.setState({gotoActivation: false})}
                    bottomButtons={[
                        {label: t('common.yes'), action: () => this.props.history.push("/ticket-activation")},
                        {
                            label: t('common.no'),
                            action: () => this.setState({gotoActivation: false}),
                            class: 'secButton'
                        }
                    ]}
                />}
            </div>
        );
    }

}

export default withRouter(TicketsPage);

export {TicketsPage}
