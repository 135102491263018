import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css'; // theme
import {reactFormatter, ReactTabulator} from 'react-tabulator';
import {authCall, logToOauth, getAccount, getSeller} from '../tools/apitools';
import '../style/adminPage.scss';
import moment from 'moment';
import {siteContext, saveContext, customer} from '../tools/toolbox';
import Loader from '../components/Loader';
import AppButton from '../components/AppButton';
import {clientT} from "../tools/i18n";

class AdminTransactions extends Component {
    constructor() {
        super();
        this.tabRef = React.createRef();

        this.baseTable = [
            {title: clientT('adminTransactions.fields.campaignName'), field: 'campaignName'},
            {title: clientT('adminTransactions.fields.phoneFormat'), field: 'phoneFormat'},
            {title: clientT('adminTransactions.fields.firstName'), field: 'firstName'},
            {title: clientT('adminTransactions.fields.name'), field: 'name'},
            {title: clientT('adminTransactions.fields.dateFormat'),
                field: 'dateFormat',
                sorter: (a, b) => {
                    const dateA = moment(a, 'DD/MM/YYYY HH:mm').toDate();
                    const dateB = moment(b, 'DD/MM/YYYY HH:mm').toDate();
                    return dateA- dateB;
                },
            },
            {title: clientT('adminTransactions.fields.giftCode'), field: 'giftCode'},
            {title: clientT('adminTransactions.fields.prizeValue'), field: 'prizeValue',
                width: 100, sorter:"number"},
            {title: clientT('adminTransactions.fields.sellerName'), field: 'sellerName'},
        ];

        this.state = {
            content: [],
            errorMessage: null,
        };
    }


    componentDidMount() {
        // document.title = 'Page d\'administration';
        this.fetchBalance();
    }

    fetchBalance = () => {
        authCall(
          `api/customers/balance_v2/${customer()}?per_page=10000000`,
          {}, (result) => {
              const content = result?.balances?.map(r => ({
                  ...r,
                  phoneFormat: r.phone && r.phone.replace ? r.phone.replace(/^\+?33/, '0') : '',
                  dateFormat: r.date ? moment(r.date).format('DD/MM/YYYY HH:mm') : '',
              })) || [];

              this.setState({content});
          },
          async () => {
          }
        );
    };

    test = (input, filter) => {
        return (input + '' || '').toLowerCase().includes(filter.toLowerCase());
    };

    downloadCsv = () => {
        this.tabRef.current.table.download('csv', 'data.csv', {delimiter: ';'});
    };

    render() {
        const columns = [
            {
                formatter: 'rowSelection',
                titleFormatter: 'rowSelection',
                hozAlign: 'center',
                headerSort: false,
                download: false,
                maxWidth: 75,
            },
            ...this.baseTable,
        ];

        const options = {
            downloadDataFormatter: (data) => data,
            downloadReady: (fileContents, blob) => blob,
            downloadRowRange: 'selected',
            layout: 'fitColumns',
            resizableColumns:true,
            pagination: 'local',
            paginationSize: 20,
            autoResize: true,
        };

        const {filter} = this.state;
        const filtered = filter
            ? this.state.content.filter(
                (v) =>
                    this.test(v.campaignName, filter) ||
                    this.test(v.phoneFormat, filter) ||
                    this.test(v.dateFormat, filter) ||
                    this.test(v.giftCode, filter) ||
                    this.test(v.sellerName, filter) ||
                    this.test(v.firstName, filter) ||
                    this.test(v.name, filter)
            )
            : this.state.content;
        return (
            <div className='adminPage adminTransactions'>
                {this.state.loaderOn ? <Loader /> : null}
                <div className='title'>{clientT('adminTransactions.title')}</div>
                {this.state.errorMessage ? (
                    <div className='register_error'>{this.state.errorMessage}</div>
                ) : null}
                <div>
                    <input
                        className='adminSearch'
                        placeholder={clientT('adminTransactions.searchplaceholder')}
                        onChange={(e) => this.setState({filter: e.target.value})}
                    />
                </div>
                <div className='action_list'>
                    <AppButton event="Export CSV" onClick={this.downloadCsv}>{clientT('adminPage.exportCsv')}</AppButton>
                </div>
                <ReactTabulator
                    ref={this.tabRef}
                    data={[...filtered]}
                    columns={columns}
                    layout={'fitData'}
                    options={options}
                />
            </div>
        );
    }
}

export default withRouter(AdminTransactions);
