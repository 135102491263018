import {clientT} from "../../../../tools/i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function Button({ isActive, isLoading, onClick }) {
  return (
    <button
      className={`Button ${isActive ? "active" : ""}`}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading
        ? <FontAwesomeIcon icon={faSpinner} spin />
        : clientT(`myTicketsV2.PaymentCard.button.${isActive ? "active" : "inactive"}`)
      }
    </button>
  );
};
