import React from "react";
import '../../style/course/footer.scss';
import {Context} from "../../tools/context";
import {t} from "../../tools/i18n";

export default function CourseFooter() {
  const footerConfig = Context.partner.course?.footer;

  if (!footerConfig) return null;

  const Logo = footerConfig.logoUrl
    ? footerConfig.logoRedirectionLink
      ? <a href={footerConfig.logoRedirectionLink}>
          <img src={footerConfig.logoUrl} alt="logoImage" />
        </a>
      : <img src={footerConfig.logoUrl} alt="logoImage" />
    : null;

  return (
    <div className="CourseFooter">
      {Logo}
      {t('course.footer.content')
        ? <div
            className="CourseFooter__content"
            dangerouslySetInnerHTML={{ __html: t('course.footer.content') }}
          />
        : null
      }
    </div>
  );
};

