import React, {Component} from 'react';

export default class SelectField extends Component {
  componentDidMount() {
    const {
      id,
      handleChange,
      value,
      values,
    } = this.props;
    if (values.length === 1 && !value) {
      handleChange({target: {id, value: values[0].value}})
    }
  }

  render() {
    const {
      id,
      label,
      handleChange,
      optional,
      subLabel,
      defaultLabel,
      value,
      values,
      labelOptional
    } = this.props;
    let localval = value && values.some(v => v.value == value) ? value : null;
    const monoValue = values.length === 1;

    return (
      <p>
        {
          !labelOptional && <label htmlFor={id}>
                {label}{!optional ? <span
                className="required">*</span> : null} <span
                className="subLabel">{subLabel}</span>
              </label>
        }
        <select
          required={!optional}
          className='categoryList'
          id={id}
          onChange={handleChange}
          value={localval}
          disabled={monoValue}
        >
          {monoValue ?
            <option value={values[0].value}>
              {values[0].label}
            </option>
            : <>
              <option value="" selected disabled>
                {defaultLabel}
              </option>
              {values.sort((a, b) => a.value === b.value ? 0 : a.value > b.value ? 1 : -1) && values.map((v, i) => (
                <option key={`${i}_${v.value}`} value={v.value}>
                  {v.label}
                </option>
              ))}
            </>}
        </select>
      </p>
    );
  }
}
