import React from 'react';
import { Context } from '../../tools/context';
import '../../style/home.scss';
import DefaultPage from "../DefaultPage";
import LandingTicket from "./LandingTicket";

const LandingClient = (props) => {
  return <LandingTicket {...props} landing={"client"} platform={Context.partner.platform}/>
}

export default LandingClient;
