import React from "react";

export const SparkTop = (props) => (
  <svg width={props.width || "47"} height={props.height || "52"} viewBox="0 0 47 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.8539 19.3686C40.0967 17.8263 38.5438 16.1072 37.0025 14.3486C35.4813 12.5958 33.9601 10.843 32.6116 8.9473C31.5793 7.46576 33.4187 5.72544 34.877 6.79425C35.8434 7.52659 36.7263 8.3205 37.6035 9.13421C40.1774 11.623 42.5757 14.3397 44.335 17.4502C45.1716 19.0035 43.197 20.5327 41.8741 19.3745L41.8539 19.3686Z"
      fill={props.color || "white"}
    />
    <path
      d="M30.38 29.6618C24.5722 27.0257 18.9401 23.8611 13.4634 20.6129C11.6455 19.5037 9.8334 18.3748 8.06448 17.1726C7.3339 16.6807 7.15886 15.7064 7.65121 14.9909C8.09461 14.3686 8.92048 14.1582 9.59648 14.4624C13.4972 16.221 17.2567 18.2391 20.9789 20.3108C24.655 22.3905 28.4288 24.5846 31.9062 26.9714C33.4594 28.0894 32.1232 30.4272 30.3541 29.6758L30.38 29.6618Z"
      fill={props.color || "white"}
    />
    <path
      d="M12.49 40.985C15.7469 41.3537 18.9258 42.2151 22.027 43.2685C23.0568 43.6328 24.081 44.0169 25.0762 44.5C25.8817 44.8849 26.215 45.8408 25.8377 46.6113C25.5525 47.2153 24.8849 47.5577 24.2519 47.4807C22.0682 47.2527 19.945 46.7418 17.8392 46.1715C15.7448 45.5616 13.662 44.9121 11.6657 43.9657C10.0606 43.1762 10.7523 40.801 12.4958 40.9652L12.49 40.985Z"
      fill={props.color || "white"}
    />
  </svg>
);
