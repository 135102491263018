import React, { Component } from 'react';
import {t} from '../../tools/i18n'
import SelectField from './SelectField';
import FilteredSelectField from "./FilteredSelectField";
import ImportField from "./ImportField";
import CheckboxField from "./CheckboxField";
import TextareaField from "./TextareaField";
import DateField from "./DateField";
import DateRangeField from "./DateRangeField";
import PhoneField from "./PhoneField";
import ImportImageField from "./ImportImageField";
import {formatters} from "../../tools/formFieldsDatabase";

export default class FormField extends Component {
  render(){
    const {id, label,
      handleChange, optional,
      type, pattern, maxSize,
      errorMsg, value,
      customError, subLabel,
      placeholder, disabled, labelOptional,
      rows, i18n, register,
      formatter,
    } = this.props;
    const placeHolder =
      (labelOptional && `${i18n ? t(`form.${id}`) : label}${!optional ? '*' : ''}`)
      || placeholder;

    const localChange = (e) => {
      if (formatter && formatters[formatter]) e.target.value = formatters[formatter](e.target.value);
      handleChange(e);
    }

    switch (type) {
      case 'select':
        return <SelectField {...this.props} />
      case 'filteredSelect':
        return <FilteredSelectField {...this.props} />
      case 'import':
        return <ImportField {...this.props} />
      case 'importImage':
        return <ImportImageField {...this.props} />
      case 'checkbox':
        return <CheckboxField {...this.props} />
      case 'textArea':
        return <TextareaField {...this.props} />
      case 'date':
        return <DateField {...this.props} />
      case 'date-range':
        return <DateRangeField {...this.props} />
      case 'phone':
        return <PhoneField {...this.props} />
      default:
        return (
          <>
            <p>
             {
               !labelOptional &&  <label htmlFor={id}>
               {i18n ? t(`form.${id}`) : label}{!optional ? <span
               className="required">*</span> : null} <span
               className="subLabel">{subLabel}</span>
             </label>
             }
              <input
                required={!optional}
                type={type || 'text'}
                pattern={pattern}
                id={id}
                onChange={localChange}
                value={value}
                placeholder={placeHolder}
                disabled={disabled && !register}
                onInvalid={(e) => {
                  if (customError) e.target.setCustomValidity(i18n ? t(`form.customErrors.${id}`) : customError);
                }}
                onInput={(e) => {e.target.setCustomValidity('')}}
              />
              {errorMsg ?
                <span className="error">
                  {errorMsg}
                </span>
                : null}
            </p>
          </>
        )
    }
  }
}