import React from "react";
import QRCode from "react-qr-code";
import { renderToStaticMarkup } from "react-dom/server";
import { saveAs } from 'file-saver';
import { toPng } from 'html-to-image';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {Context} from "../tools/context";
import '../style/downloadQrButton.scss';

export default function DownloadQRButton({ value, filename }) {
  const downloadQRCode = () => {
    const qrCodeElement = (
      <QRCode
        value={value}
        size={4096}
        bgColor="transparent"
        fgColor="#000000"
        level="Q"
      />
    );
    const qrCodeMarkup = renderToStaticMarkup(qrCodeElement);

    const div = document.createElement('div');
    div.innerHTML = qrCodeMarkup;
    div.style.position = 'absolute';
    div.style.left = '-9999px';
    document.body.appendChild(div);

    toPng(div.firstChild, { quality: 1 })
      .then((dataUrl) => {
        saveAs(dataUrl, `${filename}.png`);
        document.body.removeChild(div);
      })
      .catch((error) => {
        console.error('Could not download QR code', error);
        document.body.removeChild(div);
      });
  };

  return (
    <button className="DownloadQRButton" onClick={downloadQRCode}>
      <FontAwesomeIcon icon={faDownload} color={Context.partner.textColor1} />
    </button>
  );
};
