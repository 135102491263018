import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import "../../style/register.scss";
import "../../style/constant.scss";
import "../../style/userProfile.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import Context from "../../tools/context";
import {getFields, userProfileFieldBase} from '../../tools/formFieldsDatabase'
import {getUserData, postUserData, uploadClientMethod, uploadImageMethod} from "../../tools/apitools";
import FormField from "../../components/formField/";
import AppButton from "../../components/AppButton";
import {clientT, t} from "../../tools/i18n";
import LoaderSpinner from "react-loader-spinner";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.adaptater = {
      contactEmail: 'email',
      zipcode: 'zipcode',
      question_1: `${Context.partner.sponsor}_custom_1`,
      question_2: `${Context.partner.sponsor}_custom_2`,
    }

    const formData = {};
    const validationRules = {};

    this.newKeys = ['name'];
    // get client profile fields
    this.fields = [];
    const fields = getFields('clientProfile', userProfileFieldBase);
    Object.keys(fields).forEach(name => {
      const field = fields[name]
      const ff = field.map(f => {
        f.id = this.adaptater[f.id] || f.id;
        this.newKeys.push(f.id);
        if(f.type === 'select'){
          f.values = f.values.map((c, i) => (c.value?
            {...c,key:`${c.value}_${(i+1)}` } :{
            key: `${f.id}_${(i+1)}`,
            label: c,
            value: i+1,
          }))
        }
        if (f.id === 'zipcode') {
          validationRules[f.id] = {
            customError: f.customError,
            pattern: f.pattern
          };
        }

        return f;
      });
      this.fields = this.fields.concat(ff);
    });
    if (Context.partner.clientProfile && Context.partner.clientProfile.optin) {
      this.newKeys = this.newKeys.concat(Context.partner.clientProfile.optin.map(f => f.id));
    }
    if (Context.partner.clientProfile && Context.partner.clientProfile.dropdownFields) {
      this.newKeys = this.newKeys.concat(Context.partner.clientProfile.dropdownFields.map(f => f.id));
    }

    this.optins = Context.partner.clientProfile && Context.partner.clientProfile.optin
      ? Context.partner.clientProfile.optin
      : [];

    this.optins.forEach(o => {
      if (o.optional) return;

      formData[o.id] = false;
    })

    this.state = {
      formData,
      validationRules,
      //Error messages
      message: '',
      errorMessage: '',
      // question 2 should be hidden if zipcode = '13XXX'
      show_question_2: true,
    };
  }

  componentDidMount() {
    getUserData(this.newKeys, (result) => {
      // decode response
      const data = {...this.state.formData};
      Object.entries(result).forEach(([k, v]) => {
        data[k] = v;
      })
      data.lastname = data.name;
      this.setState({formData: data});

      if('zipcode' in result && result['zipcode'].startsWith(13)) {
        this.setState({show_question_2: false})
      }

    }, (e) => {
      this.setState({errorMessage: t('common.error')})
    })
  }

  handleChange = (e) => {
    // store all changes
    const formData = {
      ...this.state.formData, 
      [e.target.id]: e.target.value
    };
    const { validationRules } = this.state;

    if (e.target.id === 'zipcode') {
      const zipPattern = new RegExp(validationRules.zipcode.pattern);
      if (!zipPattern.test(e.target.value)) {
        this.setState({
          [e.target.id + 'Error']: validationRules.zipcode.customError
        });
      } else {
        this.setState({
          [e.target.id + 'Error']: ''
        });
      }
    }
    if (e.target.id === 'zipcode') {
      if (e.target.value.startsWith(13)) {
        this.setState({show_question_2: false});
      } else {
        if (!this.state.show_question_2)
          this.setState({show_question_2: true})
      }
    }

    this.setState({formData});
  };


  /**
   * Get all the modified fields
   * build a body based on these values
   * then postData
   * @param {*} e: the event
   * @returns 
   */
  handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = this.state.formData;

    const now = new Date();
    now.setFullYear(now.getFullYear()-18);
    if ((now - new Date(formData.birthdate)) < 0) {
     this.setState({
       BirthdateError: 'Vous devez être majeur'
     })
      return;
    }

    this.setState({load: true});
    if (formData.userdocument && formData.userdocument.name) {
      await uploadClientMethod('api/users/files/', formData.userdocument, formData.userdocument.name);
    }

    let body = {}
    // encode response
    Object.entries(formData).forEach(([k, v]) => {
      if (k === 'userdocument') return;
      if (k === 'lastname') k = 'name';
      body[k]= v;
    });

    // if the body is empty, do nothing
    if(Object.keys(body).length === 0) {
        this.postUserDataOk();
        return;
    }

    postUserData(body, (e) => this.postUserDataOk(e), (e) => this.postUserDataFail(e))
  }
  

  postUserDataOk(e) {
    window.scrollTo(0,0);
    this.setState({
      message: t('userProfile.confirmation'),
      errorMessage: '',
      // clear all posted changes
      formData: {},
      load: false,
    })
    this.props.history.push("/my-tickets"); //TODO redirect to my tickets if profile is full
  }

  postUserDataFail(e) {
    window.scrollTo(0,0);
    this.setState({
      message: '',
      errorMessage: t('userProfile.error'),
      load: false,
    });
  }

  buildField(f) {
    // if zipcode == 13XXX, do not show 'question_2'
    if(f.id === this.adaptater['question_2'] && this.state.show_question_2 === false) {
      return null;
    }
    let value = this.state.formData[f.id];
    if (f.type === 'checkbox') {
      value = this.state.formData[f.id] === 'true'
        || this.state.formData[f.id] === true;
    }

    return (
      <FormField
        {...f}
        labelOptional
        key={f.id}
        optional={f.optional}
        disabled={f.userDisabled}
        value={value}
        errorMsg={this.state[f.id + 'Error']}
        handleChange={(e) => this.handleChange(e)}
        i18n
      />)
  }

  render() {
    return (
      <div>
          <div className="main informationsClient">
          <h1 className={`clientTitle`} dangerouslySetInnerHTML={{__html: clientT('userProfile.title')}}/>
          <div className={`clientSubTitle`}
             dangerouslySetInnerHTML={{__html: Context.partner?.client?.profileTitle || clientT('userProfile.subtitle')}}/>
          <form onSubmit={this.handleFormSubmit} className={"form"}>
                  {/* state.errorMessage */}
              {this.state.errorMessage ?
                <div className="profile_error">
                  {this.state.errorMessage}
                </div>
                : null}
              {/* state.message */}
              {this.state.message ?
                <div className="profile_message">
                  {this.state.message}
                </div>
                : null}
                {this.fields.map(f => this.buildField(f))}
                {Context.partner?.clientProfile?.dropdownFields && 
                <>
                { Context.partner.clientProfile.dropdownFields.map(f =>
                    <FormField
                      {...f}
                      labelOptional
                      customClass
                      key={f.id}
                      value={this.state.formData[f.id]}
                      errorMsg={f.errorMsgKey ? this.state[f.errorMsgKey] : null}
                      handleChange={(e) => this.handleChange(e)}
                      i18n
                    />)
                  }
                  </>
                }
                {this.optins.map(f =>
                    <FormField
                      {...f}
                      customClass
                      optional={f.optional !== false}
                      type={'checkbox'}
                      key={f.id}
                      value={this.state.formData[f.id] === 'true'
                      || this.state.formData[f.id] === true}
                      errorMsg={f.errorMsgKey ? this.state[f.errorMsgKey] : null}
                      handleChange={(e) => this.handleChange(e)}
                      i18n
                    />)
                }
                <AppButton
                  className={"submitForm"}
                  type="submit" event="Valider" client
                  disabled={this.state.load}
                >
                  {this.state.load
                  ? <LoaderSpinner
                      type="ThreeDots"
                      color={"white"}
                      height={9}
                      width={40}
                    /> :
                  t('common.validate')}
                </AppButton>
              </form>
            </div>
      </div>
    );
  }
}

export default withRouter(UserProfile);
